import CustomDatePicker from "../../../../../../../components/date-picker/v1/CustomDatePicker";
import { DateSelectorFieldWrapper } from "../style/DateOperator.styled";

export default function DateSelectorFieldView ({ startDate, endDate, onChangeStartDateValue, onChangeEndDateValue }) {
    return (
        <DateSelectorFieldWrapper>
            <div className='date-selector-box'>
                <div className='date-selector-box-title'>시작일</div>
                <CustomDatePicker
                    valueSize={14}
                    labelSize={12}
                    label={'시작일'}
                    selected={startDate}
                    onChange={value => onChangeStartDateValue(value)}
                ></CustomDatePicker>
            </div>
            <div className='date-selector-box'>
                <div className='date-selector-box-title'>종료일</div>
                <CustomDatePicker
                    valueSize={14}
                    labelSize={12}
                    label={'종료일'}
                    selected={endDate}
                    onChange={value => onChangeEndDateValue(value)}
                ></CustomDatePicker>
            </div>
        </DateSelectorFieldWrapper>
    )
}