import styled from 'styled-components';

const Container = styled.div`
    background-color: var(--defaultBackground);
    overflow: hidden;
    padding-bottom: 150px;
`;

export {
    Container,
}