import CustomBoxImage from "../../../../../../../components/image/box-image/CustomBoxImage";
import Ripple from "../../../../../../../components/ripple/v1/Ripple";
import { PageRouterSelectBox, ProductSearchButtonEl, SearchFieldBox, SearchFieldWrapper, SelectItem } from "../style/Operator.styled";

export default function SearchFieldView({
    selectedProduct,
    onActionOpenProductListModal,
    onActionRouteToPage
}) {
    return (
        <SearchFieldWrapper>
            <div>
                <ProductSearchButtonEl
                    type='button'
                    onClick={(e) => onActionOpenProductListModal(e)}
                >
                    {selectedProduct?.name ?? `조회 상품 선택`}
                    <Ripple color={'#e0e0e0'} duration={1000} />
                </ProductSearchButtonEl>
            </div>

            <SearchFieldBox>
                <CustomBoxImage
                    src={selectedProduct?.thumbnailUri}
                    size='200px'
                    className='image-box'
                />
            </SearchFieldBox>

            <PageRouterSelectBox>
                {selectedProduct &&
                    <SelectItem onClick={() => onActionRouteToPage('/sales-analysis/sub-category')}>
                        <span>서브 카테고리 : </span>
                        <span>{selectedProduct?.productSubCategory?.name}</span>
                        <span>[총 매출액 & 판매 건] 확인</span>
                    </SelectItem>
                }
            </PageRouterSelectBox>
        </SearchFieldWrapper>
    )
}