import withMainApiCsrfWrapper from "../utils/withMainApiCsrfWrapper";
import { axiosAuthInterceptor } from "./axiosInterceptors"
import qs from 'qs';

const API_ADDRESS = process.env.REACT_APP_MAIN_API_ADDRESS;

const productOptionDataConnect = () => {
    return {
        /**
         * 
         * @param {object} params 
         * @param {string} params.productId
         * @param {object} headers 
         * @param {string} headers.wsId
         * @returns 
         */
        searchListByProductId: async function (params, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.get(`${API_ADDRESS}/api/v1/product-options/products/${params.productId}`, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} params 
         * @param {string} params.packageYn
         * @param {string} params.productCategoryId
         * @param {string} params.productSubCategoryId
         * @param {string} params.productId
         * @param {string} params.stockManagementYn
         * @param {string} params.searchCondition
         * @param {string} params.searchQuery
         * @param {string} params.sort
         * @param {number} params.page
         * @param {number} params.size
         * @param {object} headers 
         * @param {string} headers.wsId
         * @returns 
         */
        searchPagePositionInventory: async function (params, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.get(`${API_ADDRESS}/api/v1/product-options/page/related:ProductCategoryAndProductSubCategoryAndProduct`, {
                    params: params,
                    headers: headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN',
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: 'brackets' })
                    }
                })
            )
        },
        /**
         * 
         * @param {object} params 
         * @param {string} params.packageYn
         * @param {string} params.productCategoryId
         * @param {string} params.productSubCategoryId
         * @param {string} params.productId
         * @param {string} params.stockManagementYn
         * @param {string[]} params.searchCondition
         * @param {string} params.searchQuery
         * @param {string} params.sort
         * @param {number} params.page
         * @param {number} params.size
         * @param {object} headers 
         * @param {string} headers.wsId
         * @returns 
         */
        searchPositionTotalSize: async function (params, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.get(`${API_ADDRESS}/api/v1/product-options/count`, {
                    params: params,
                    headers: headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN',
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: 'brackets' })
                    }
                })
            )
        }
    }
}

export {
    productOptionDataConnect
}