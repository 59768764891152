import _ from "lodash";
import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { GraphDataset, createGraphData, createGraphOption } from "../../../../../utils/chartUtils";
import { getRandomColors } from "../../../../../utils/colorUtils";
import { toPriceUnitFormat } from "../../../../../utils/numberFormatUtils";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";

const SALES_GRAPH_BG_COLOR = ['#689CB5', '#9CB5D3', '#CECEEB', '#F3CAF2', '#FFC4DE', '#FFC8B4', '#FFDB86', '#F9F871'];

export default function BestProductGraphComponent (props) {
    const [payAmountGraphData, setPayAmountGraphData] = useState(null);
    const [totalPayAmountGraphData, setTotalPayAmountGraphData] = useState(null);
    const [unitGraphData, setUnitGraphData] = useState(null);
    const [totalUnitGraphData, setTotalUnitGraphData] = useState(null);

    const [priceGraphOption, setPayAmountGraphOption] = useState(null);
    const [unitGraphOption, setUnitGraphOption] = useState(null);
    
    const [viewSize, setViewSize] = useState(null);

    useEffect(() => {
        if(!(props.bestPayAmountItem && props.bestUnitItem)) {
            return;
        }

        if(!(props.selectedCategories?.length > 0)) {
            handleActionResetGraphData();
            return;
        }
        
        handleActionCreatePayAmountGraphData();
        handleActionCreateUnitGraphData();
    }, [props.bestPayAmountItem, props.bestUnitItem, props.selectedCategories])

    useEffect(() => {
        if(!(payAmountGraphData && unitGraphData)) {
            return;
        }
        
        if(!viewSize) {
            return;
        }
        
        handleActionCreatePayAmountGraphOption();
        handleActionCreateUnitGraphOption();
    }, [payAmountGraphData, unitGraphData, viewSize])

    useEffect(() => {
        if(!props.optionListSize) {
            return;
        }

        let data = props.optionListSize;
        setViewSize(data);
    }, [props.optionListSize])

    const handleActionResetGraphData = () => {
        setPayAmountGraphData(null);
        setUnitGraphData(null);
        setPayAmountGraphOption(null);
        setUnitGraphOption(null);
    }

    const handleActionCreatePayAmountGraphData = () => {
        let randomColor = getRandomColors(props.selectedCategories.length - SALES_GRAPH_BG_COLOR.length);
        let graphColor = [...SALES_GRAPH_BG_COLOR, ...randomColor];

        let salesDatasets = [];
        let totalDatasets = [];
        props.selectedCategories.forEach((r, idx) => {
            let data = props.bestPayAmountItem.filter(r2 => r.id === r2.categoryId)[0];
            let graphLabels = data.performances.map((r2, idx) => (`${idx+1}.${r2.productName}`));
            
            let salesPayAmountValues = data.performances.map(r2 => r2.salesPayAmount);
            let salesPayAmountDataset = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '판매 매출액',
                data: salesPayAmountValues,
                backgroundColor: graphColor[idx],
                borderColor: graphColor[idx],
                borderWidth: 0,
                order: 0
            }

            let orderPayAmountValues = data.performances.map(r2 => r2.orderPayAmount);
            let orderPayAmountDataset = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '주문 매출액',
                data: orderPayAmountValues,
                backgroundColor: graphColor[idx] + '55',
                borderColor: graphColor[idx] + '55',
                borderWidth: 0,
                order: 1
            }
            
            salesDatasets.push({
                labels: graphLabels,
                datasets: [salesPayAmountDataset]
            });

            totalDatasets.push({
                labels: graphLabels,
                datasets: [orderPayAmountDataset, salesPayAmountDataset]
            });
        })

        setPayAmountGraphData(salesDatasets);
        setTotalPayAmountGraphData(totalDatasets);
    }

    const handleActionCreateUnitGraphData = () => {
        let randomColor = getRandomColors(props.selectedCategories.length - SALES_GRAPH_BG_COLOR.length);
        let graphColor = [...SALES_GRAPH_BG_COLOR, ...randomColor];

        let salesDatasets = [];
        let totalDatasets = [];
        props.selectedCategories.forEach((r, idx) => {
            let data = props.bestUnitItem.filter(r2 => r.id === r2.categoryId)[0];
            let graphLabels = data.performances.map((r2, idx) => (`${idx+1}.${r2.productName}`));
            
            let salesUnitValues = data.performances.map(r2 => r2.salesUnit);
            let salesUnitDataset = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '판매 수량',
                data: salesUnitValues,
                backgroundColor: graphColor[idx],
                borderColor: graphColor[idx],
                borderWidth: 0,
                order: 0
            }

            let orderUnitValues = data.performances.map(r2 => r2.orderUnit);
            let orderUnitDataset = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '주문 수량',
                data: orderUnitValues,
                backgroundColor: graphColor[idx] + '55',
                borderColor: graphColor[idx] + '55',
                borderWidth: 0,
                order: 1
            }
            
            salesDatasets.push({
                labels: graphLabels,
                datasets: [salesUnitDataset]
            });

            totalDatasets.push({
                labels: graphLabels,
                datasets: [orderUnitDataset, salesUnitDataset]
            });
        })

        setUnitGraphData(salesDatasets);
        setTotalUnitGraphData(totalDatasets);
    }

    const handleActionCreatePayAmountGraphOption = () => {
        let priceOption = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: true,
            },
            indexAxis: 'y',
            scales: {
                x: {
                    ticks: {
                        callback: function (value, index, ticks) {
                            return toPriceUnitFormat(value);
                        }
                    }
                },
                y: {
                    // 글자 수 7글자로 제한
                    afterTickToLabelConversion: function (scaleInstance) {
                        let ticks = scaleInstance.ticks;

                        let updatedTicks = ticks.map(r => {
                            let updatedLabel = r.label
                            if(updatedLabel.length > 7) {
                                return {
                                    ...r,
                                    label : updatedLabel.substring(0, 7) + "..."
                                }
                            }else {
                                return r;
                            }
                        })
                        
                        scaleInstance.ticks = updatedTicks;
                    },
                    // label width 고정
                    afterFit: function(scaleInstance) {
                        scaleInstance.width = 100; 
                    },
                    grid: {
                        display: false
                    },
                    stacked: true,
                    max: parseInt(viewSize) - 1
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            var sum = _.sum(tooltipItem.dataset.data);
                            var value = tooltipItem?.parsed?.x || 0;
                            var rate = Math.round(((value / sum) * 100) || 0)
                            return value + "원 (" + rate + "%)";
                        }
                    }
                }
            }
        }

        setPayAmountGraphOption(priceOption);
    }

    const handleActionCreateUnitGraphOption = () => {
        let unitOption = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'y',
                intersect: false,
            },
            indexAxis: 'y',
            scales: {
                y: {
                    // 글자 수 7글자로 제한
                    afterTickToLabelConversion: function (scaleInstance) {
                        let ticks = scaleInstance.ticks;

                        let updatedTicks = ticks.map(r => {
                            let updatedLabel = r.label
                            if(updatedLabel.length > 7) {
                                return {
                                    ...r,
                                    label : updatedLabel.substring(0, 7) + "..."
                                }
                            }else {
                                return r;
                            }
                        })
                        
                        scaleInstance.ticks = updatedTicks;
                    },
                    // label width 고정
                    afterFit: function(scaleInstance) {
                        scaleInstance.width = 100;
                    },
                    grid: {
                        display: false
                    },
                    stacked: true,
                    max: parseInt(viewSize) - 1,
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            var sum = _.sum(tooltipItem.dataset.data);
                            var value = tooltipItem?.parsed?.x || 0;
                            var rate = Math.round(((value / sum) * 100) || 0)
                            return value + "개 (" + rate + "%)";
                        }
                    }
                }
            }
        }

        setUnitGraphOption(unitOption);
    }

    return (
        <>
            {payAmountGraphData?.map((r, idx) => {
                let categoryName = props.selectedCategories[idx]?.name ?? '';
                return (
                    <CustomChartGroupV2 key={'best-product-graph-idx' + idx}>
                        <CustomChartGroupV2.BoardGroup>
                            <CustomChartGroupV2.BoardGroup.Title>{`[${categoryName}] 판매 BEST`}</CustomChartGroupV2.BoardGroup.Title>
                            <CustomChartGroupV2.BoardGroup.Info>해당 카테고리의 상품별 판매 매출액 & 판매수량과 카테고리 내의 판매 비율을 표시합니다.</CustomChartGroupV2.BoardGroup.Info>
                        </CustomChartGroupV2.BoardGroup>

                        <CustomChartGroupV2.BodyGroup className='flex-item'>
                            <CustomChartGroupV2.BodyGroup.ChartBody height={`${50 * viewSize}px`}>
                                {totalPayAmountGraphData && priceGraphOption &&
                                    <Chart
                                        data={createGraphData(props.checkedSwitch ? totalPayAmountGraphData[idx] : payAmountGraphData[idx])}
                                        options={createGraphOption(priceGraphOption)}
                                    />
                                }
                            </CustomChartGroupV2.BodyGroup.ChartBody>

                            <CustomChartGroupV2.BodyGroup.ChartBody height={`${50 * viewSize}px`}>
                                {totalUnitGraphData && unitGraphOption &&
                                    <Chart
                                        data={createGraphData(props.checkedSwitch ? totalUnitGraphData[idx] : unitGraphData[idx])}
                                        options={createGraphOption(unitGraphOption)}
                                    />
                                }
                            </CustomChartGroupV2.BodyGroup.ChartBody>
                        </CustomChartGroupV2.BodyGroup>
                    </CustomChartGroupV2>
                )
            })}
        </>
    )
}