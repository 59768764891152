import { Container } from "./style/TextInfo.style";
import TextInfoFieldView from "./view/TextInfoField.view";

export default function TextInfoComponent() { 
    return (
        <>
            <Container>
                <TextInfoFieldView />
            </Container>
        </>
    )
}