import FieldCircleLoading from "../../../../../../components/loading/field-loading/v1/FieldCircleLoading"
import { dateToYYYYMMDD, getDayName } from "../../../../../../utils/dateFormatUtils"
import { toPriceUnitFormat } from "../../../../../../utils/numberFormatUtils"
import { FixedTh, TableEl, TableFieldWrapper, TableTr } from "../style/SummaryTable.styled"

export default function TableFieldView ({ isTotalAnalysisLoading, totalSumData, tableData }) {
    return (
        <TableFieldWrapper>
            <div style={{ position: 'relative' }}>
                {isTotalAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }
                <TableEl width='100%'>
                    <thead>
                        <TableTr>
                            <FixedTh scope='col' width={70}>#</FixedTh>
                            <FixedTh scope='col' width={150}>날짜</FixedTh>
                            <FixedTh scope='col' width={70}>요일</FixedTh>
                            <FixedTh scope='col' width={170}>총 주문매출</FixedTh>
                            <FixedTh scope='col' width={170}>총 판매매출</FixedTh>
                            <FixedTh scope='col' width={170}>미판매 예상 매출액</FixedTh>
                            <FixedTh scope='col' width={180}>총 판매 / 주문 건</FixedTh>
                            <FixedTh scope='col' width={180}>총 판매 / 주문 수량</FixedTh>
                            <FixedTh scope='col' width={150}>미판매 수량</FixedTh>
                        </TableTr>
                    </thead>
                    <tbody>
                        {totalSumData &&
                            <TableTr className='first-tr'>
                                <td>전체</td>
                                <td>전체</td>
                                <td>전체</td>
                                <td className='highlight-td'>총 {toPriceUnitFormat(totalSumData.orderPayAmount)}</td>
                                <td className='highlight-td' style={{ color: 'var(--mainColor)' }}>총 {toPriceUnitFormat(totalSumData.salesPayAmount)}</td>
                                <td className='highlight-td' style={{ color: '#ff3060' }}>총 {toPriceUnitFormat(totalSumData.unsalesPayAmount)}</td>
                                <td>총 {totalSumData.salesRegistration} / {totalSumData.orderRegistration} 건</td>
                                <td>총 {totalSumData.salesUnit} / {totalSumData.orderUnit} 개</td>
                                <td>총 {totalSumData.unsalesUnit} 개</td>
                            </TableTr>
                        }
                        {tableData?.map((r, idx) => {
                            return (
                                <TableTr key={'table-tr-idx' + idx}>
                                    <td>{idx+1}</td>
                                    <td>{dateToYYYYMMDD(r.datetime)}</td>
                                    <td>{getDayName(r.datetime)}</td>
                                    <td className='highlight-td'>{toPriceUnitFormat(r.orderPayAmount)}</td>
                                    <td className='highlight-td' style={{ color: 'var(--mainColor)' }}>{toPriceUnitFormat(r.salesPayAmount)}</td>
                                    <td className='highlight-td' style={{ color: '#ff3060' }}>{toPriceUnitFormat(r.unsalesPayAmount)}</td>
                                    <td>{r.salesRegistration} / {r.orderRegistration} 건</td>
                                    <td>{r.salesUnit} / {r.orderUnit} 개</td>
                                    <td>{r.unsalesUnit} 개</td>
                                </TableTr>
                            )
                        })}
                    </tbody>
                </TableEl>
            </div>
        </TableFieldWrapper>
    )
}