import { useEffect, useState } from "react";
import NavBarComponent from "../../navbar/NavBar.component";
import ConditionFieldComponent from "./condition-field/ConditionField.component";
import DailyAnalysisComponent from "./daily-analysis/DailyAnalysis.component";
import { Container } from "./index.styled";
import MonthlyAnalysisComponent from "./monthly-analysis/MonthlyAnalysis.component";
import useRouterHookV2 from "../../../../hooks/router/useRouterHookV2";

export default function SalesAnalysis_DashboardIndexComponent() {
    const [periodType, setPeriodType] = useState('registration');

    const router = useRouterHookV2();

    useEffect(() => {
        if(!router.query.periodType) {
            return;
        }

        setPeriodType(router.query.periodType);
    }, [router.location])

    const handleChangePeriodType = () => {
        let changeValue = (periodType === 'registration') ? 'channelOrderDate' : 'registration';
        setPeriodType(changeValue);

        router.query.periodType = changeValue;
        router.replace();
    }
    
    return (
        <Container>
            <NavBarComponent
                sidebarName={'판매 성과 요약'}
                headerName={'대시보드'}
                sidebarColor={'#ffffff'}
            >
                <>
                    <ConditionFieldComponent
                        periodType={periodType}
                        onChangePeriodType={handleChangePeriodType}
                    />

                    <DailyAnalysisComponent />
                    <MonthlyAnalysisComponent />
                </>
            </NavBarComponent>
        </Container>
    )
}