import _ from "lodash";
import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import CustomDotIcon from "../../../../../components/icon/dot-icon/v1/CustomDotIcon";
import { GraphDataset, createGraphData, createGraphOption, setAnalysisResultText } from "../../../../../utils/chartUtils";
import { getRandomColors } from "../../../../../utils/colorUtils";
import { dateToYYYYMMDDAndDayName, getDateFormatByGraphDateLabel, getEndDate, getMonthAndSearchDateRange, getStartDate, getWeekNumberAndSearchDateRange } from "../../../../../utils/dateFormatUtils";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";
import FieldCircleLoading from "../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const SALES_GRAPH_BG_COLOR = ['#B9B4EB', '#F0B0E8', '#FF9999', '#B0E89B', '#FFFF85', '#ADDCFF', '#8EF5DD', '#88CAD3', '#FFCF99', '#BDD2FF'];

export default function RegistrationAndUnitGraphComponent (props) {
    const [salesRegistrationGraphData, setSalesRegistrationGraphData] = useState(null);
    const [totalRegistrationGraphData, setTotalRegistrationGraphData] = useState(null);
    
    const [salesUnitGraphData, setSalesUnitGraphData] = useState(null);
    const [totalUnitGraphData, setTotalUnitGraphData] = useState(null);
    
    const [registrationSummaryData, setRegistrationSummaryData] = useState(null);
    const [unitSummaryData, setUnitSummaryData] = useState(null);
    
    const [graphOption, setGraphOption] = useState(null);
    const [graphLabels, setGraphLabels] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        if(!(router.state?.salesChannels)) {
            handleActionResetGraphData();
            return;
        }

        if(!props.searchDimension) {
            return;
        }

        if(!props.analysis) {
            return;
        }

        handleActionCreateRegistrationGraphData();
        handleActionCreateUnitGraphData();
    }, [router.state, props.analysis, props.searchDimension])

    useEffect(() => {
        if (!(totalRegistrationGraphData && totalUnitGraphData)) {
            return;
        }

        handleActionCreateGraphOption();
    }, [totalRegistrationGraphData, totalUnitGraphData])

    const handleActionResetGraphData = () => {
        setSalesRegistrationGraphData(null);
        setTotalRegistrationGraphData(null);
        setSalesUnitGraphData(null);
        setTotalUnitGraphData(null);
        setRegistrationSummaryData(null);
        setUnitSummaryData(null);
    }

    const handleActionCreateRegistrationGraphData = () => {
        let graphColor = SALES_GRAPH_BG_COLOR;

        let salesRegistrationData = [];
        let orderRegistrationData = [];

        let salesDatasets = [];
        let orderDatasets = [];
        let salesAvgDatasets = [];
        let graphLabels = new Set([]);
        let channels = [...router.state.salesChannels];

        // 날짜 최소값, 최대값 설정
        let minimumDate = props.analysis[0].datetime;
        let maximumDate = props.analysis.slice(-1)[0].datetime;

        channels.forEach(channel => {
            let salesRegistration = [];
            let orderRegistration = [];
            let analysisMap = new Map();

            props.analysis.forEach(r => {
                let datetime = dateToYYYYMMDDAndDayName(r.datetime);
                if (props.searchDimension === 'week') {
                    datetime = getWeekNumberAndSearchDateRange(r.datetime, minimumDate, maximumDate);
                } else if (props.searchDimension === 'month') {
                    datetime = getMonthAndSearchDateRange(r.datetime, minimumDate, maximumDate);
                }
    
                let performance = r.performances?.filter(r2 => r2.salesChannel === channel)[0];
                let data = analysisMap.get(datetime);
                analysisMap.set(datetime, {
                    salesRegistration: (data?.salesRegistration || 0) + (performance?.salesRegistration || 0),
                    orderRegistration: (data?.orderRegistration || 0) + (performance?.orderRegistration || 0)
                });
            })
            
            analysisMap.forEach((value, key) => {
                graphLabels.add(key);
                salesRegistration.push(value.salesRegistration);
                orderRegistration.push(value.orderRegistration);
            })

            salesRegistrationData.push(salesRegistration);
            orderRegistrationData.push(orderRegistration);
        })

        let randomColor = getRandomColors(channels.length - SALES_GRAPH_BG_COLOR.length)
        graphColor = [...graphColor, ...randomColor];

        // 그래프 데이터 세팅
        if (channels.size === 0) {
            let barGraphOfOrder = {
                ...new GraphDataset().toJSON(),
                label: '주문 건',
                data: [],
                type: 'line',
                fill: false,
                borderColor: graphColor[0],
                backgroundColor: graphColor[0],
                borderWidth: 0,
                order: 1
            }
            let barGraphOfSales = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '판매 건',
                data: [],
                borderColor: graphColor[0],
                backgroundColor: graphColor[0],
                borderWidth: 0,
                order: 0
            }

            orderDatasets.push(barGraphOfOrder);
            salesDatasets.push(barGraphOfSales);
        } else {
            channels.forEach((r, idx) => {
                let channelName = r ?? '미지정';

                let barGraphOfOrder = {
                    ...new GraphDataset().toJSON(),
                    type: 'bar',
                    label: '(주문) ' + channelName,
                    stack: 'group' + idx,
                    data: orderRegistrationData[idx],
                    borderColor: graphColor[idx] + '55',
                    backgroundColor: graphColor[idx] + '55',
                    borderWidth: 0,
                    order: 1
                }
                let barGraphOfSales = {
                    ...new GraphDataset().toJSON(),
                    type: 'bar',
                    label: channelName,
                    stack: 'group' + idx,
                    data: salesRegistrationData[idx],
                    borderColor: graphColor[idx],
                    backgroundColor: graphColor[idx],
                    borderWidth: 0,
                    order: 0
                }
                salesDatasets.push(barGraphOfSales);
                orderDatasets.push(barGraphOfOrder);

                // 판매매출액 7일간 평균 데이터 생성
                // 조회된 기간의 시작날짜부터 7일간 null로 채운다
                let salesRegistrationAvgData = Array(6).fill(null, 0, 6);
                for (let i = 6; i <= salesRegistrationData[idx].length; i++) {
                    let avg = parseInt(salesRegistrationData[idx].slice(i - 6, i + 1).reduce((a, b) => a + b) / 7);
                    salesRegistrationAvgData.push(avg);
                }

                // 판매매출액 7일간 평균 그래프 데이터 생성
                let lineGraphOfSalesAvg = {
                    ...new GraphDataset().toJSON(),
                    type: 'line',
                    label: channelName + ' 7일간 평균',
                    data: salesRegistrationAvgData,
                    fill: false,
                    borderColor: graphColor[idx],
                    backgroundColor: graphColor[idx],
                    order: -2,
                    borderDash: [3, 3]
                }

                salesAvgDatasets.push(lineGraphOfSalesAvg);
            })
        }

        setSalesRegistrationGraphData({
            labels: [...graphLabels],
            datasets: [...salesDatasets, ...salesAvgDatasets]
        })
        setTotalRegistrationGraphData({
            labels: [...graphLabels],
            datasets: [...salesDatasets, ...orderDatasets, ...salesAvgDatasets]
        });
        setGraphLabels([...graphLabels]);

        // 요약 데이터 생성
        let salesData = setAnalysisResultText(salesDatasets)
        let totalData = setAnalysisResultText([...salesDatasets, ...orderDatasets]);

        // 매출액 내림차순으로 정렬
        salesData = _.orderBy(salesData, 'value', 'desc');
        totalData = _.orderBy(totalData, 'value', 'desc');

        setRegistrationSummaryData({
            sales: salesData,
            total: totalData
        });
    }

    const handleActionCreateUnitGraphData = () => {
        let salesUnitData = [];
        let orderUnitData = [];

        let salesDatasets = [];
        let orderDatasets = [];
        let salesAvgDatasets = [];
        let graphLabels = new Set([]);
        let channels = [...router.state.salesChannels];

        // 날짜 최소값, 최대값 설정
        let minimumDate = props.analysis[0].datetime;
        let maximumDate = props.analysis.slice(-1)[0].datetime;

        channels.forEach(channel => {
            let salesUnit = [];
            let orderUnit = [];
            let analysisMap = new Map();

            props.analysis.forEach(r => {
                let datetime = dateToYYYYMMDDAndDayName(r.datetime);
                if (props.searchDimension === 'week') {
                    datetime = getWeekNumberAndSearchDateRange(r.datetime, minimumDate, maximumDate);
                } else if (props.searchDimension === 'month') {
                    datetime = getMonthAndSearchDateRange(r.datetime, minimumDate, maximumDate);
                }
    
                let performance = r.performances?.filter(r2 => r2.salesChannel === channel)[0];
                let data = analysisMap.get(datetime);
                analysisMap.set(datetime, {
                    salesUnit: (data?.salesUnit || 0) + (performance?.salesUnit || 0),
                    orderUnit: (data?.orderUnit || 0) + (performance?.orderUnit || 0)
                });
            })
            
            analysisMap.forEach((value, key) => {
                graphLabels.add(key);
                salesUnit.push(value.salesUnit);
                orderUnit.push(value.orderUnit);
            })

            salesUnitData.push(salesUnit);
            orderUnitData.push(orderUnit);
        })

        let graphColor = SALES_GRAPH_BG_COLOR;
        for (let i = SALES_GRAPH_BG_COLOR.length; i < channels.length; i++) {
            let randomColor = `#${Math.round(Math.random() * 0xFFFFFF).toString(16)}`;
            graphColor.push(randomColor);
        }

        // 그래프 데이터 세팅
        if (channels.size === 0) {
            let barGraphOfOrder = {
                ...new GraphDataset().toJSON(),
                label: '주문 수량',
                data: [],
                type: 'line',
                fill: false,
                borderColor: graphColor[0],
                backgroundColor: graphColor[0],
                borderWidth: 0,
                order: 1
            }
            let barGraphOfSales = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '판매 수량',
                data: [],
                borderColor: graphColor[0],
                backgroundColor: graphColor[0],
                borderWidth: 0,
                order: 0
            }

            orderDatasets.push(barGraphOfOrder);
            salesDatasets.push(barGraphOfSales);
        } else {
            channels.forEach((r, idx) => {
                let channelName = r ?? '미지정';

                let barGraphOfOrder = {
                    ...new GraphDataset().toJSON(),
                    type: 'bar',
                    label: '(주문) ' + channelName,
                    stack: 'group' + idx,
                    data: orderUnitData[idx],
                    borderColor: graphColor[idx] + '55',
                    backgroundColor: graphColor[idx] + '55',
                    borderWidth: 0,
                    order: 1
                }
                let barGraphOfSales = {
                    ...new GraphDataset().toJSON(),
                    type: 'bar',
                    label: channelName,
                    stack: 'group' + idx,
                    data: salesUnitData[idx],
                    borderColor: graphColor[idx],
                    backgroundColor: graphColor[idx],
                    borderWidth: 0,
                    order: 0
                }
                orderDatasets.push(barGraphOfOrder);
                salesDatasets.push(barGraphOfSales);

                // 판매매출액 7일간 평균 데이터 생성
                // 조회된 기간의 시작날짜부터 7일간 null로 채운다
                let salesUnitAvgData = Array(6).fill(null, 0, 6);
                for (let i = 6; i <= salesUnitData[idx].length; i++) {
                    let avg = parseInt(salesUnitData[idx].slice(i - 6, i + 1).reduce((a, b) => a + b) / 7);
                    salesUnitAvgData.push(avg);
                }

                // 판매매출액 7일간 평균 그래프 데이터 생성
                let lineGraphOfSalesAvg = {
                    ...new GraphDataset().toJSON(),
                    type: 'line',
                    label: channelName + ' 7일간 평균',
                    data: salesUnitAvgData,
                    fill: false,
                    borderColor: graphColor[idx],
                    backgroundColor: graphColor[idx],
                    order: -2,
                    borderDash: [3, 3]
                }

                salesAvgDatasets.push(lineGraphOfSalesAvg);
            })
        }

        setSalesUnitGraphData({
            labels: [...graphLabels],
            datasets: [...salesDatasets, ...salesAvgDatasets]
        })
        setTotalUnitGraphData({
            labels: [...graphLabels],
            datasets: [...salesDatasets, ...orderDatasets, ...salesAvgDatasets]
        });

        // 매출 그래프 요약 데이터 생성
        let salesData = setAnalysisResultText(salesDatasets)
        let totalData = setAnalysisResultText([...salesDatasets, ...orderDatasets]);

        // 매출액 내림차순으로 정렬
        salesData = _.orderBy(salesData, 'value', 'desc');
        totalData = _.orderBy(totalData, 'value', 'desc');

        setUnitSummaryData({
            sales: salesData,
            total: totalData
        });
    }

    const handleActionCreateGraphOption = () => {
        let option = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    }
                },
                y: {
                    ticks: {
                        callback: function (value, index, ticks) {
                            return value;
                        }
                    },
                    stacked: false
                }
            },
            onClick: function (e, item) {
                handleActionSetGraphClickOption(item);
            },
            onHover: (e, item) => {
                const target = e.native ? e.native.target : e.target;
                target.style.cursor = item[0] ? 'pointer' : 'default';
            }
        }

        setGraphOption(option);
    }

    const handleActionSetGraphClickOption = (item) => {
        if (item.length === 0) return;

        var itemIdx = item[0].index;
        var label = graphLabels[itemIdx];
        var date = getDateFormatByGraphDateLabel(label, props.searchDimension);

        let startDate = getStartDate(date.startDate);
        let endDate = getEndDate(date.endDate);

        let data = {
            state: {
                ...router.state,
                startDate,
                endDate
            }
        }
        router.replace(data);
        props.onActionOpenDetailGraphSelectorModal();
    }

    const handleActionOpenWholePeroidDetailGraphSelectorModal = () => {
        let startDate = getStartDate(props.analysis[0].datetime);
        let endDate = getEndDate(props.analysis.slice(-1)[0].datetime);

        let data = {
            state: {
                ...router.state,
                startDate,
                endDate
            }
        }
        router.replace(data);
        props.onActionOpenDetailGraphSelectorModal();
    }

    return (
        <CustomChartGroupV2>
            <CustomChartGroupV2.BoardGroup className='flex-item'>
                <div>
                    <CustomChartGroupV2.BoardGroup.Title>총 판매건 & 수량</CustomChartGroupV2.BoardGroup.Title>
                    <CustomChartGroupV2.BoardGroup.Info>조회된 기간의 총 판매건과 판매수량이 표시되며, 일별 조회 시 7일간 평균 차트를 확인할 수 있습니다.</CustomChartGroupV2.BoardGroup.Info>
                </div>
                <CustomChartGroupV2.BoardGroup.SearchModalButton onActionModalOpen={handleActionOpenWholePeroidDetailGraphSelectorModal} />
            </CustomChartGroupV2.BoardGroup>

            <div style={{ position: 'relative' }}>
                {props.isSalesChannelAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }
                <CustomChartGroupV2.BodyGroup className='flex-item'>
                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {totalRegistrationGraphData && graphOption &&
                            <Chart
                                data={createGraphData(props.checkedSwitch ? totalRegistrationGraphData : salesRegistrationGraphData)}
                                options={createGraphOption(graphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>

                    <CustomChartGroupV2.BodyGroup.SummaryGroup>
                        <CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryTitle>[스토어 총 판매건]</CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryTitle>
                        <CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryContent>
                            <SummaryContentTextField
                                data={props.checkedSwitch ? registrationSummaryData?.total : registrationSummaryData?.sales}
                            />
                        </CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryContent>
                    </CustomChartGroupV2.BodyGroup.SummaryGroup>
                </CustomChartGroupV2.BodyGroup>

                <CustomChartGroupV2.BodyGroup className='flex-item'>
                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {totalUnitGraphData && graphOption &&
                            <Chart
                                data={createGraphData(props.checkedSwitch ? totalUnitGraphData : salesUnitGraphData)}
                                options={createGraphOption(graphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>

                    <CustomChartGroupV2.BodyGroup.SummaryGroup>
                        <CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryTitle>[스토어 총 판매수량]</CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryTitle>
                        <CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryContent>
                            <SummaryContentTextField
                                data={props.checkedSwitch ? unitSummaryData?.total : unitSummaryData?.sales}
                            />
                        </CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryContent>
                    </CustomChartGroupV2.BodyGroup.SummaryGroup>
                </CustomChartGroupV2.BodyGroup>
            </div>
        </CustomChartGroupV2>
    )
}

function SummaryContentTextField({ data }) {
    return (
        <ul>
            {data?.map((r, idx) => {
                return (
                    <li key={'graph-summary' + idx}>
                        <div style={{
                            width: '120px',
                            overflow:'hidden',
                            textOverflow:'ellipsis',
                            whiteSpace:'nowrap'
                         }}>
                            <CustomDotIcon color={r.color} />
                            <span> {r.label} </span>
                        </div>
                        <div style={{ fontWeight: 700 }}>
                            {(r.value || 0).toLocaleString()} 개
                        </div>
                    </li>
                )
            })}
            {!(data && data.length > 0) &&
                <li>
                    <span>데이터가 존재하지 않습니다.</span>
                </li>
            }
        </ul>
    )
}
