import styled from 'styled-components';
import CustomBlockButton from "../../../../components/buttons/block-button/v1/CustomBlockButton";
import { useSelector } from "react-redux";
import CustomImage from '../../../../components/image/v1/CustomImage';
import { Link } from 'react-router-dom';

const Container = styled.div`
    padding: 20px 30px;
    position: absolute;
    top: 54px;
    left: 0;
    background: #fff;
    width: 100%;
    z-index: 999;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    overflow-y: scroll;
    max-height: 300px;
    box-shadow: 0px 5px 5px 0 rgb(72 75 108 / 10%);
    transition: all .3s;

    &::-webkit-scrollbar {
        width: 7px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #00000025;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: white;
    }
`;

const CloseButtonBox = styled.div`
    @media screen and (max-width: 576px) {
        height: 40px;
    }
`;

const CloseButton = styled(CustomBlockButton)`
    width: 40px;
    height: 40px;
    border: none;
    float: right;
    transition: 0.2s;
    border-radius: 50%;

    :hover {
        background-color: var(--defaultGrayColor);
    }
`;

const MenuWrapper = styled.div`
    display: flex;
    margin: 15px 0;
    flex-wrap:wrap;

    @media screen and (max-width: 992px) {
        justify-content: flex-start;
    }
`;

const MenuBox = styled.div`
    margin-right: 20px;
    width: 250px;

    &:hover .menu-title {
        border-bottom: 3px solid var(--defaultBlueColor);
    }

    @media screen and (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 30px;
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .menu-title{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        border-bottom: 3px solid var(--defaultGrayColor);
        padding-bottom: 10px;
        text-align: center;
        color: #606060;
        transition: .2s;
    }

    .menu-item{
        font-size: 18px;
        margin-bottom: 5px;
        text-align: center;
        height: 40px;
        border:1px solid #00000000;
        color: #606060;
        font-weight: 500;
        transition: .2s;
        border-radius: 20px;

        &:hover{
            color: var(--defaultBlueColor);
            font-weight: 700;
        }
    }
`;

const SALES_ANALYSIS_CLIENT_ADDRESS = process.env.REACT_APP_SALES_ANALYSIS_CLIENT_ADDRESS;
const MAIN_CLIENT_ORIGIN = process.env.REACT_APP_MAIN_CLIENT_ADDRESS;

const getMenuHref = (href, workspaceId) => {
    if (href === '/sales-analysis/dashboard') {
        return `${SALES_ANALYSIS_CLIENT_ADDRESS}${href}?workspaceId=${workspaceId}`
    }
    if (href !== '/sales-analysis/dashboard') {
        return `${MAIN_CLIENT_ORIGIN}${href}`
    }
    return href;
}

export default function MenuListComponent({
    onCloseMenuList
}) {
    const workspaceRedux = useSelector(state => state.workspaceRedux);
    const workspaceId = workspaceRedux?.workspaceInfo?.id;

    return (
        <Container>
            <CloseButtonBox>
                <CloseButton
                    type='button'
                    onClick={() => onCloseMenuList()}
                >
                    <CustomImage src='/images/icon/close_default_959eae.svg' />
                </CloseButton>
            </CloseButtonBox>
            <MenuWrapper>
                {MENU_LIST?.map(menu => {
                    return (
                        <MenuBox key={menu?.title}>
                            <div className='menu-title'>
                                {menu?.title}
                            </div>
                            <div>
                                {menu.subMenuItems?.map(subMenu => {
                                    return (
                                        <Link
                                            key={subMenu?.title}
                                            to={getMenuHref(subMenu?.href, workspaceId)}
                                            onClick={() => onCloseMenuList()}
                                        >
                                            <CustomBlockButton
                                                className='menu-item'
                                            >
                                                {subMenu?.title}
                                            </CustomBlockButton>
                                        </Link>
                                    );
                                })}
                            </div>
                        </MenuBox>
                    );
                })}
            </MenuWrapper>
        </Container>
    );
}

const MENU_LIST = [
    {
        title: '유용한 기능',
        subMenuItems: [
            {
                title: '마진율 계산기',
                href: '/margin/dashboard'
            },
            {
                title: '엑셀 변환기',
                href: '/excel-translator'
            }
        ]
    },
    {
        title: '창고 관리 서비스',
        subMenuItems: [
            {
                title: '통합 상품 관리',
                href: '/product/dashboard'
            },
            {
                title: '통합 재고 관리',
                href: '/inventory'
            }
        ]
    },
    {
        title: '발주 관리 서비스',
        subMenuItems: [
            {
                title: '통합 발주 관리',
                href: '/erp/collection/dashboard'
            },
            {
                title: '판매 분석',
                href: '/sales-analysis/dashboard'
            }
        ]
    }
]