import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { productOptionDataConnect } from "../../../../../../data_connect/productOptionDataConnect";
import defaultErrorHandler from "../../../../../../handler/dataConnectErrorHandler";

export default function useProductOptionHook ({ selectedProduct }) {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(null);

    useEffect(() => {
        async function fetchInit() {
            let params = {
                productId: selectedProduct.id
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchProductOptionByProductId(params, headers);
        }

        if(!selectedProduct) {
            setOptions(null);
            return;
        }

        fetchInit();
    }, [selectedProduct])

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }
    
    const reqSearchProductOptionByProductId = async (params, headers) => {
        toggleIsLoading(true);
        await productOptionDataConnect().searchListByProductId(params, headers)
            .then(res => {
                if (res.status === 200 && res.data.message === 'success') {
                    setOptions(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
        toggleIsLoading(false);
    }

    return {
        isLoading,
        options
    }
}