import styled, { css } from "styled-components";

const Container = styled.div`
    .half-type {
        width: 50%;
        height: 320px;
        margin: 0 auto;

        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }
`;

const BoardControlFieldWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    
    @media screen and (max-width: 992px) {
        width: 100%;
        justify-content: flex-end;
    }
`;

const ButtonEl = styled.button`
    padding: 10px 13px;
    background-color: var(--defaultButtonColor);
    border: 1px solid var(--defaultBorderColor);
    border-radius: 5px;

    &:hover {
        background-color: var(--defaultButtonHoverColor);
    }
    
    ${props => props.checked && 
        css`
            background-color: #555 !important;
            border: 1px solid #555 !important;
            color: white !important;
        `
    }
`;

const TableFieldWrapper = styled.div`
    min-width: 1000px;
    text-align: center;
    font-size: 14px;
    overflow: auto;
`;

const TableEl = styled.table`
    position:relative;
    text-align: center;
    table-layout: fixed;
    border: none;
    word-break: break-all;
    border-collapse: collapse;
    caption-side: bottom;

    thead {
        width: 100%;
    }

    tbody tr {
        border: 1px solid #eee;
        color: #555;
        transition: 1ms;
    }
`;

const FixedTh = styled.th`
    background-color: #fff;
    height: 40px;
    position: sticky;
    top: 0;
    z-index:10;
    box-shadow: 0 -1.5px 0 0 var(--mainColor) inset;
`;

const LeftTr = styled.tr`
    font-weight: 600;
    background-color: ${props => props.backgroundColor};
    border-bottom: 1px solid rgb(224 224 224);
`;

const ItemTr = styled.tr`
    height: 38px;

    &:hover{
        background: #2c73d210;
    }
`;

export {
    Container,
    BoardControlFieldWrapper,
    ButtonEl,
    TableFieldWrapper,
    TableEl,
    ItemTr,
    FixedTh,
    LeftTr
}