import styled from "styled-components"
import BoardBox from "./BoardBox";
import BodyBox from "./BodyBox";
import ChartBody from "./ChartBody";
import SearchModalButton from "./SearchModalButton";
import Summary from "./Summary";
import Title from "./Title";

const ChartGroupContainer = styled.div`

`;

function ChartGroupMain({
    children
}) {
    return (
        <ChartGroupContainer>
            {children}
        </ChartGroupContainer>
    )
}

export const CustomChartGroup = Object.assign(ChartGroupMain, {
    BoardBox: BoardBox,
    Title: Title,
    SearchModalButton: SearchModalButton,
    BodyBox: BodyBox,
    ChartBody: ChartBody,
    Summary: Summary
})