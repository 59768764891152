import { Dialog } from "@mui/material";
import styled from "styled-components";
import useDisabledBtn from "../../../../hooks/button/useDisableBtn";
import CustomBlockButton from "../../../buttons/block-button/v1/CustomBlockButton";
import CustomBoxImage from "../../../image/box-image/CustomBoxImage";
import PropTypes from "prop-types";

const CustomDialog = styled(Dialog)`
    .MuiPaper-root::-webkit-scrollbar{
        background: #e1e1e130;
        height:7px;
        width: 5px;
    }
    .MuiPaper-root::-webkit-scrollbar-track{
        border-radius: 10px;
    }
    .MuiPaper-root::-webkit-scrollbar-thumb{
        background-color: #00000020;
        border-radius: 10px;
    }
    .MuiBackdrop-root{
        background-color:${props => props.backgroundcolor ? props.backgroundcolor : 'inherit'};
    }
    .MuiPaper-rounded{
        border-radius: 15px;
    }
`;

const HeaderBox = styled.div`
    padding: 10px 20px;
`;

const CloseBox = styled.div`
    display: flex;
    justify-content: flex-end;

    .button-el {
        width: 45px;
        height: 45px;
        position: relative;
        overflow: hidden;
        padding: 0;

        border: none;
        background-color: inherit;
        border-radius: 50%;
        cursor: pointer;

        :hover {
            transition: 0.15s;
            background-color: #efefef;
        }
    }
`;

const TitleBox = styled.div`
    font-size: 1.3rem;
    font-weight: 600;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
`;

const ContentContainer = styled.div`
    background: var(--defaultBackground);
`;

const MessageBox = styled.div`
    padding: 0 20px;

    .content-box{
        margin-top: 20px;

        .message{
            text-align: center;
            font-weight: 500;
            font-size: 15px;
            color:#404040;
            word-break: keep-all;
        }
    }
`;

const FooterButtonGroup = styled.div`
    margin-top: 20px;
    display: flex;

    .button-el{
        margin:0;
        padding:0;
        height: 48px;
        border:none;
        color:#fff;
        font-size: 18px;
        font-weight: 500;
    }
`;

/**
 * 
 * @param {Object} props
 * @param {boolean} props.open
 * @param {boolean} props.fullWidth
 * @param {string} props.maxWidth [xs, sm, md, lg, xl]
 * @param {function} props.onClose
 * @param {function} props.onConfirm
 * @param {element} props.title
 * @param {element} props.message
 * @param {object} props.confirmBtnStyle
 * @returns 
 */
const CustomConfirmModal = ({
    open,
    fullWidth,
    maxWidth,
    onClose,
    onConfirm,
    title,
    message,
    confirmBtnStyle,
    children,
    backgroundColor
}) => {
    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        submit: {
            confirm: (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                onConfirm();
            }
        }
    }

    return (
        <>
            <CustomDialog
                open={open || false}
                fullWidth={fullWidth ?? true}
                maxWidth={maxWidth || 'xs'}
                onClose={() => onClose() || {}}
                backgroundcolor={backgroundColor || '#00000080'}
            >
                <ContentContainer>
                    <HeaderBox>
                        <CloseBox>
                            <button
                                type='button'
                                className='button-el'
                                onClick={() => onClose() || {}}
                            >
                                <CustomBoxImage
                                    src='/images/icon/close_default_959eae.svg'
                                    size={45}
                                />
                            </button>
                        </CloseBox>
                        <TitleBox>{title || '확인메세지'}</TitleBox>
                    </HeaderBox>
                    <div>
                        {message &&
                            <MessageBox>
                                <div className='content-box'>
                                    <div className='message'>
                                        {message}
                                    </div>
                                </div>
                            </MessageBox>
                        }
                        <div>
                            {children || ''}
                        </div>
                        <FooterButtonGroup>
                            <CustomBlockButton
                                type='button'
                                className='button-el'
                                style={{
                                    background: '#959eae',
                                    flex: 1
                                }}
                                onClick={() => onClose() || {}}
                            >
                                취소
                            </CustomBlockButton>
                            <CustomBlockButton
                                type='button'
                                className='button-el'
                                onClick={(e) => __handle.submit.confirm(e)}
                                style={{
                                    background: 'var(--mainColor)',
                                    width: '60%',
                                    ...confirmBtnStyle
                                }}
                                disabled={disabledBtn}
                            >
                                확인
                            </CustomBlockButton>
                        </FooterButtonGroup>
                    </div>
                </ContentContainer>
            </CustomDialog>
        </>
    );
}

CustomConfirmModal.propTypes = {
    open: PropTypes.bool.isRequired,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
    confirmBtnStyle: PropTypes.string,
    children: PropTypes.object,
    backgroundColor: PropTypes.string
}

export default CustomConfirmModal;