import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import CustomDotIcon from "../../../../../components/icon/dot-icon/v1/CustomDotIcon";
import { GraphDataset, createGraphData, createGraphOption, setAnalysisResultText } from "../../../../../utils/chartUtils";
import { dateToYYYYMMDDAndDayName, getDateFormatByGraphDateLabel, getEndDate, getMonthAndSearchDateRange, getStartDate, getWeekNumberAndSearchDateRange } from "../../../../../utils/dateFormatUtils";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";
import FieldCircleLoading from "../../../../../components/loading/field-loading/v1/FieldCircleLoading";

// 그래프 기본 2가지 색상 : [판매 수량, 건수]
const DEFAULT_GRAPH_BG_COLOR = ['#4975A9', '#80A9E1'];
const DEFAULT_AVG_GRAPH_BG_COLOR = ['#224B7C', '#397CD8'];

export default function RegistrationAndUnitGraphComponent (props) {
    const [salesGraphData, setSalesGraphData] = useState(null);
    const [totalGraphData, setTotalGraphData] = useState(null);
    
    const [salesSummaryData, setSalesSummaryData] = useState(null);
    const [totalSummaryData, setTotalSummaryData] = useState(null);
    
    const [graphOption, setGraphOption] = useState(null);
    const [graphLabels, setGraphLabels] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        if(!props.searchDimension) {
            return;
        }

        if(!props.analysis) {
            return;
        }

        handleActionCreateGraphData();
    }, [props.analysis, props.searchDimension])

    useEffect(() => {
        if(!(salesGraphData && totalGraphData)) {
            return;
        }

        handleActionCreateGraphOption();
    }, [salesGraphData, totalGraphData])

    const handleActionCreateGraphData = () => {
        let salesRegistrationData = [];
        let salesUnitData = [];
        let orderRegistrationData = [];
        let orderUnitData = [];
        let graphLabels = [];
        let analysisMap = new Map();

        // 날짜 최소값, 최대값 설정
        let minimumDate = props.analysis[0].datetime;
        let maximumDate = props.analysis.slice(-1)[0].datetime;

        props.analysis.forEach(r => {
            let datetime = dateToYYYYMMDDAndDayName(r.datetime);
            if (props.searchDimension === 'week') {
                datetime = getWeekNumberAndSearchDateRange(r.datetime, minimumDate, maximumDate);
            } else if (props.searchDimension === 'month') {
                datetime = getMonthAndSearchDateRange(r.datetime, minimumDate, maximumDate);
            }

            let data = analysisMap.get(datetime);
            analysisMap.set(datetime, {
                salesRegistration: (data?.salesRegistration || 0) + r.salesRegistration,
                orderRegistration: (data?.orderRegistration || 0) + r.orderRegistration,
                salesUnit: (data?.salesUnit || 0) + r.salesUnit,
                orderUnit: (data?.orderUnit || 0) + r.orderUnit
            });
        })

        analysisMap.forEach((value, key) => {
            graphLabels.push(key);
            salesRegistrationData.push(value.salesRegistration);
            orderRegistrationData.push(value.orderRegistration);
            salesUnitData.push(value.salesUnit);
            orderUnitData.push(value.orderUnit);
        })

        let barGraphOfOrderReg = {
            ...new GraphDataset().toJSON(),
            type: 'bar',
            label: '주문건',
            data: orderRegistrationData,
            fill: false,
            borderColor: DEFAULT_GRAPH_BG_COLOR[1] + '55',
            backgroundColor: DEFAULT_GRAPH_BG_COLOR[1] + '55',
            order: 1,
            borderWidth: 0,
            stack: 'reg_group',
        }

        let barGraphOfOrderUnit = {
            ...new GraphDataset().toJSON(),
            type: 'bar',
            label: '주문수량',
            data: orderUnitData,
            fill: false,
            borderColor: DEFAULT_GRAPH_BG_COLOR[0] + '55',
            backgroundColor: DEFAULT_GRAPH_BG_COLOR[0] + '55',
            order: 1,
            borderWidth: 0,
            stack: 'unit_group',
        }
        
        let barGraphOfSalesReg = {
            ...new GraphDataset().toJSON(),
            type: 'bar',
            label: '판매건',
            data: salesRegistrationData,
            fill: true,
            borderColor: DEFAULT_GRAPH_BG_COLOR[1],
            backgroundColor: DEFAULT_GRAPH_BG_COLOR[1],
            order: 0,
            borderWidth: 0,
            stack: 'reg_group',
        }

        let barGraphOfSalesUnit = {
            ...new GraphDataset().toJSON(),
            type: 'bar',
            label: '판매수량',
            data: salesUnitData,
            fill: true,
            borderColor: DEFAULT_GRAPH_BG_COLOR[0],
            backgroundColor: DEFAULT_GRAPH_BG_COLOR[0],
            borderWidth: 0,
            order: 0,
            stack: 'unit_group'
        }

        // 판매건, 수량 7일간 평균 데이터 생성
        // 조회된 기간의 시작날짜부터 +6일은 null로 채운다
        let salesRegistrationAvgData = Array(6).fill(null, 0, 6);
        let salesUnitAvgData = Array(6).fill(null, 0, 6);
        for (let i = 6; i < salesRegistrationData.length; i++) {
            let regAvg = parseInt(salesRegistrationData.slice(i - 6, i + 1).reduce((a, b) => a + b) / 7);
            let unitAvg = parseInt(salesUnitData.slice(i - 6, i + 1).reduce((a, b) => a + b) / 7);
            salesRegistrationAvgData.push(regAvg);
            salesUnitAvgData.push(unitAvg);
        }

        // 판매매출액 7일간 평균 그래프 데이터 생성
        let lineGraphOfRegAvg = {
            ...new GraphDataset().toJSON(),
            type: 'line',
            label: '판매 건 7일간 평균',
            data: salesRegistrationAvgData,
            fill: false,
            borderColor: DEFAULT_AVG_GRAPH_BG_COLOR[1],
            backgroundColor: DEFAULT_AVG_GRAPH_BG_COLOR[1],
            order: -2,
            borderDash: [3, 3]
        }

        // 판매매출액 7일간 평균 그래프 데이터 생성
        let lineGraphOfUnitAvg = {
            ...new GraphDataset().toJSON(),
            type: 'line',
            label: '판매 수량 7일간 평균',
            data: salesUnitAvgData,
            fill: false,
            borderColor: DEFAULT_AVG_GRAPH_BG_COLOR[0],
            backgroundColor: DEFAULT_AVG_GRAPH_BG_COLOR[0],
            order: -2,
            borderDash: [3, 3]
        }

        let createdSalesGraphDatasets = [barGraphOfSalesReg, barGraphOfSalesUnit, lineGraphOfRegAvg, lineGraphOfUnitAvg];
        let createdOrderGraphDatasets = [barGraphOfOrderReg, barGraphOfOrderUnit, lineGraphOfRegAvg, lineGraphOfUnitAvg];
        
        setSalesGraphData({
            labels: [...graphLabels],
            datasets: createdSalesGraphDatasets
        });
        setTotalGraphData({
            labels: [...graphLabels],
            datasets: [...createdSalesGraphDatasets, ...createdOrderGraphDatasets]
        });
        setGraphLabels([...graphLabels]);

        // 매출 그래프 요약 데이터 생성
        handleActionCreateSalesSummary([barGraphOfSalesReg, barGraphOfSalesUnit]);
        handleActionCreateTotalSummary([barGraphOfSalesReg, barGraphOfSalesUnit, barGraphOfOrderReg, barGraphOfOrderUnit]);
    }

    const handleActionCreateSalesSummary = (data) => {
        let salesSummary = setAnalysisResultText(data);
        setSalesSummaryData(salesSummary);
    }

    const handleActionCreateTotalSummary = (data) => {
        let totalSummary = setAnalysisResultText(data);
        setTotalSummaryData(totalSummary);
    }

    const handleActionCreateGraphOption = () => {
        let option = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    }
                },
                y: {
                    stacked: false
                }
            },
            onClick: function (e, item) {
                handleActionSetGraphClickOption(item);
            },
            onHover: (e, item) => {
                const target = e.native ? e.native.target : e.target;
                target.style.cursor = item[0] ? 'pointer' : 'default';
            }
        }

        setGraphOption(option);
    }

    const handleActionSetGraphClickOption = (item) => {
        if(item.length === 0) return;

        var itemIdx = item[0].index;
        var label = graphLabels[itemIdx];
        var date = getDateFormatByGraphDateLabel(label, props.searchDimension);

        let startDate = getStartDate(date.startDate);
        let endDate = getEndDate(date.endDate);

        let data = {
            state: {
                ...router.state,
                startDate,
                endDate
            }
        }
        router.replace(data);
        props.onActionOpenDetailGraphSelectorModal();
    }
    
    const handleActionOpenWholePeroidDetailGraphSelectorModal = () => {
        let startDate = getStartDate(props.analysis[0].datetime);
        let endDate = getEndDate(props.analysis.slice(-1)[0].datetime);

        let data = {
            state: {
                ...router.state,
                startDate,
                endDate
            }
        }
        router.replace(data);
        props.onActionOpenDetailGraphSelectorModal();
    }

    return (
        <CustomChartGroupV2>
            <CustomChartGroupV2.BoardGroup className='flex-item'>
                <div>
                    <CustomChartGroupV2.BoardGroup.Title>총 판매건 & 수량</CustomChartGroupV2.BoardGroup.Title>
                    <CustomChartGroupV2.BoardGroup.Info>조회된 기간의 총 판매건과 판매수량이 표시되며, 일별 조회 시 7일간 평균 차트를 확인할 수 있습니다.</CustomChartGroupV2.BoardGroup.Info>
                </div>
                <CustomChartGroupV2.BoardGroup.SearchModalButton onActionModalOpen={handleActionOpenWholePeroidDetailGraphSelectorModal} />
            </CustomChartGroupV2.BoardGroup>

            <div style={{ position: 'relative' }}>
                {props.isTotalAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }
                <CustomChartGroupV2.BodyGroup className='flex-item'>
                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {totalGraphData && graphOption &&
                            <Chart
                                data={createGraphData(props.checkedSwitch ? totalGraphData : salesGraphData)}
                                options={createGraphOption(graphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>

                    <CustomChartGroupV2.BodyGroup.SummaryGroup>
                        <CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryTitle>[총 판매건 & 수량]</CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryTitle>
                        <CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryContent>
                            <SummaryContentTextField
                                data={props.checkedSwitch ? totalSummaryData : salesSummaryData}
                            />
                        </CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryContent>
                    </CustomChartGroupV2.BodyGroup.SummaryGroup>
                </CustomChartGroupV2.BodyGroup>
            </div>
        </CustomChartGroupV2>
    )
}

function SummaryContentTextField({ data }) {
    return (
        <ul>
            {data?.map((r, idx) => {
                return (
                    <li key={'graph-summary' + idx}>
                        <div style={{
                            width: '120px',
                            overflow:'hidden',
                            textOverflow:'ellipsis',
                            whiteSpace:'nowrap'
                         }}>
                            <CustomDotIcon color={r.color} />
                            <span> {r.label} </span>
                        </div>
                        <div style={{ fontWeight: 700 }}>
                            {(r.value || 0).toLocaleString()} 개
                        </div>
                    </li>
                )
            })}
            {!(data && data.length > 0) &&
                <li>
                    <span>데이터가 존재하지 않습니다.</span>
                </li>
            }
        </ul>
    )
}
