import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { salesAnalysisDataConnect } from "../../../../../../data_connect/salesAnalysisDataConnect";
import { getStartDate, getTimeDiffWithUTC, setPlusDate } from "../../../../../../utils/dateFormatUtils";
import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import defaultErrorHandler from "../../../../../../handler/dataConnectErrorHandler";

const TODAY = new Date();
const YESTERDAY = setPlusDate(TODAY, 0, 0, -1);

export default function useDashboardHook() {
    const workspaceRedux = useSelector(state => state.workspaceRedux);
    
    const [dashboard, setDashboard] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const router = useRouterHookV2();

    useEffect(() => {
        // 대시보드 - 오늘, 어제, -7일, -8일
        async function fetchInit() {
            let prev7DaysOfToday = setPlusDate(TODAY, 0, 0, -7);
            let prev7DaysOfYesterDay = setPlusDate(YESTERDAY, 0, 0, -7);
            
            let searchDate = [TODAY, YESTERDAY, prev7DaysOfToday, prev7DaysOfYesterDay].map(r => getStartDate(r));
            let utcHourDifference = getTimeDiffWithUTC();
            let searchPeriodType = router.query.periodType ?? 'registration';

            let body = {
                searchDate,
                utcHourDifference,
                periodType: searchPeriodType
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchDashboard(body, headers);
        }

        fetchInit();
    }, [router.location])
    
    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchDashboard = async (body, headers) => {
        toggleIsLoading(true);
        await salesAnalysisDataConnect().searchDashboard(body, headers)
            .then(res => {
                if (res.status === 200) {
                    setDashboard(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
        toggleIsLoading(false);
    }

    return {
        isLoading,
        dashboard,
        reqSearchDashboard
    }
}