import styled from "styled-components";
import ChartBody from "./ChartBody";
import { SummaryGroup } from "./summary/SummaryGroup";

const Container = styled.div`
    width: 100%;
    padding: 20px;
    overflow: hidden;

    &.flex-item {
        display: flex;
        align-items: center;
        gap: 15px;

        @media screen and (max-width: 992px) {
            display: block;
        }
    }
`;

function BodyGroupMain({
    children, className, ...props
}) {
    return (
        <Container className={className} {...props}>
            {children}
        </Container>
    )
}


export const BodyGroup = Object.assign(BodyGroupMain, {
    ChartBody: ChartBody,
    SummaryGroup: SummaryGroup
})
