import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { GraphDataset, createGraphData, createGraphOption } from "../../../../../utils/chartUtils";
import { getRandomColors } from "../../../../../utils/colorUtils";
import { getDayName, getWeekName, getWeekNumber } from "../../../../../utils/dateFormatUtils";
import { toPriceUnitFormat } from "../../../../../utils/numberFormatUtils";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";
import FieldCircleLoading from "../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const WEEKLY_AVG_GRAPH_BG_COLOR = '#71BACD';
const WEEKLY_GRAPH_BG_COLOR = ['#B2ACE6', '#E5BBEB', '#86AEE5', '#FFAFCC', '#92CFE3', '#D2C8F8', '#ECA9EB', '#5ED8BB', '#E4CAAC', '#389091'];

export default function PayAmountDayOfWeekGraphComponent (props) {
    const [graphData, setGraphData] = useState(null);
    
    const [graphOption, setGraphOption] = useState(null);

    useEffect(() => {
        if(!props.analysis) {
            return;
        }

        handleActionCreateGraphData();
    }, [props.analysis])

    useEffect(() => {
        if(!graphData) {
            return;
        }

        handleActionCreateGraphOption();
    }, [graphData])

    const handleActionCreateGraphData = () => {
        let graphColor = WEEKLY_GRAPH_BG_COLOR;

        let graphDatasets = [];
        let payAmountWeeklyData = [];
        let payAmountAvgData = [];
        let payAmountData = [];
        let graphLabels = getWeekName();
        let weekValue = {};

        // 주차별 판매 매출액 그래프
        let week = new Set([]);
        props.analysis.forEach(r => week.add(getWeekNumber(r.datetime)));
        graphLabels.forEach(r => {
            // 주차별 초기화
            weekValue = {
                ...weekValue,
                [r]: 0
            };

            // 평균 그래프 초기화
            payAmountData.push({
                dayName: r,
                salesPayAmount: 0,
                frequency: 0
            });
        })

        payAmountWeeklyData = [...week].map(r => {
            return {
                weekNum: r,
                value: weekValue
            }
        });

        props.analysis.forEach(r => {
            let compareWeek = getWeekNumber(r.datetime);
            let compareDay = getDayName(r.datetime);

            payAmountWeeklyData = payAmountWeeklyData.map(r2 => {
                if (r2.weekNum === compareWeek) {
                    return {
                        ...r2,
                        value: {
                            ...r2.value,
                            [compareDay]: parseInt(r2.value[compareDay]) + parseInt(r.salesPayAmount)
                        }
                    }
                } else {
                    return r2;
                }
            })

            payAmountData = payAmountData.map(data => {
                if(data.dayName === compareDay) {
                    return {
                        ...data,
                        salesPayAmount: data.salesPayAmount + r.salesPayAmount,
                        frequency: data.frequency + 1
                    }
                } else {
                    return data;
                }
            })
        })

        payAmountData.forEach(r => {
            let salesAvg = parseInt((r.salesPayAmount) / r.frequency);
            payAmountAvgData.push(salesAvg);
        })

        let randomColor = getRandomColors(payAmountWeeklyData.length - WEEKLY_GRAPH_BG_COLOR.length)
        graphColor = [...graphColor, ...randomColor];

        let lineGraphOfSales = {
            ...new GraphDataset().toJSON(),
            type: 'line',
            label: '평균 매출액',
            data: payAmountAvgData,
            borderColor: WEEKLY_AVG_GRAPH_BG_COLOR,
            backgroundColor: WEEKLY_AVG_GRAPH_BG_COLOR,
            borderWidth: 3,
            order: -2,
            borderDash: [3, 3]
        }

        graphDatasets.push(lineGraphOfSales);

        payAmountWeeklyData.forEach((r, idx) => {
            let datasets = new GraphDataset().toJSON();
            datasets = {
                ...datasets,
                type: 'bar',
                label: r.weekNum + '주차',
                data: r.value,
                fill: true,
                borderColor: graphColor[idx],
                backgroundColor: graphColor[idx],
                borderWidth: 0
            }
            graphDatasets.push(datasets);
        })

        // // 매출 그래프 데이터 생성
        setGraphData({
            labels: graphLabels,
            datasets: graphDatasets
        })
    }

    const handleActionCreateGraphOption = () => {
        let option = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                y: {
                    ticks: {
                        callback: function (value, index, ticks) {
                            return toPriceUnitFormat(value);
                        }
                    }
                }
            }
        }
        setGraphOption(option);
    }

    return (
        <CustomChartGroupV2>
            <CustomChartGroupV2.BoardGroup>
                <CustomChartGroupV2.BoardGroup.Title>요일별 매출액</CustomChartGroupV2.BoardGroup.Title>
                <CustomChartGroupV2.BoardGroup.Info>조회된 기간의 요일별 평균 판매액과 주차별 판매액이 표시됩니다.</CustomChartGroupV2.BoardGroup.Info>
            </CustomChartGroupV2.BoardGroup>

            <div style={{ position: 'relative' }}>
                {props.isTotalAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }
                <CustomChartGroupV2.BodyGroup className='flex-item'>
                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {graphData && graphOption &&
                            <Chart
                                data={createGraphData(graphData)}
                                options={createGraphOption(graphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>
                </CustomChartGroupV2.BodyGroup>
            </div>
        </CustomChartGroupV2>
    )
}