import styled from "styled-components"
import SummaryTitle from "./SummaryTitle";
import SummaryContent from "./SummaryContent";

const Container = styled.div`
    min-width: 250px;
    height: 400px;
    background-color: var(--defaultGrayColor);
    border: 1px solid var(--defaultGrayColor);
    border-radius: 10px;
`;

function SummaryGroupMain({
    children
}) {
    return (
        <Container>
            {children}
        </Container>
    )
}

export const SummaryGroup = Object.assign(SummaryGroupMain, {
    SummaryTitle: SummaryTitle,
    SummaryContent: SummaryContent
})
