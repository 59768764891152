import { useState } from "react";
import { Container } from "./style/ConditionSelector.styled";
import SelectorFieldView from "./view/SelectorField.view";
import TextFieldView from "./view/TextField.view";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { customToast, defaultOptions } from "../../../../../components/toast/custom-react-toastify/v1";

const MAXIMUM_SEARCH_SIZE = 10;

export default function ConditionSelectorComponent(props) {
    const [inputValue, setInputValue] = useState('');

    const router = useRouterHookV2();

    const handleChangeInputValue = (e) => {
        let value = e.target.value;
        setInputValue(value);
    }

    const returnIsCheckedOne = (channel) => router.state?.salesChannels?.includes(channel);

    const handleActionCheckOne = (e, channel) => {
        e.stopPropagation();
        let selectedChannels = new Set(router.state?.salesChannels);

        if(selectedChannels.has(channel)) {
            selectedChannels.delete(channel);
        } else {
            selectedChannels.add(channel);
        }

        if(selectedChannels.size > MAXIMUM_SEARCH_SIZE) {
            let memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`;
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }
        
        router.replace({
            state: {
                ...router.state,
                salesChannels: [...selectedChannels]
            }
        });
    }

    const handleActionCheckAll = (e) => {
        e.stopPropagation();

        if(props.salesChannel?.length > MAXIMUM_SEARCH_SIZE) {
            let memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`;
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        router.replace({
            state: {
                ...router.state,
                salesChannels: [...props.salesChannel]
            }
        });
    }

    const handleActionCheckCancelAll = (e) => {
        e.stopPropagation();
        
        router.replace({
            state: {
                ...router.state,
                salesChannels: []
            }
        });
    }

    return (
        <Container>
            <TextFieldView />

            <SelectorFieldView
                isSalesChannelAnalysisLoading={props.isSalesChannelAnalysisLoading}
                salesChannel={props.salesChannel}
                inputValue={inputValue}

                returnIsCheckedOne={returnIsCheckedOne}
                onActionCheckOne={handleActionCheckOne}
                onActionCheckAll={handleActionCheckAll}
                onActionCheckCancelAll={handleActionCheckCancelAll}
                onChangeInputValue={handleChangeInputValue}
            />
        </Container>
    )
}