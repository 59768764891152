
import { Link, useLocation } from "react-router-dom";
import { ButtonEl, Container, ItemTitle, LinkItem } from "./styles/NavBarList.styled";

export default function NavBarListComponent() {
    const location = useLocation();

    return (
        <>
            <Container>
                {NAV_BAR_LIST?.map((r, index) => {
                    return (
                        <LinkItem key={index}>
                            <ItemTitle>{r.title}</ItemTitle>
                            {r.page?.map(navPage => {
                                return (
                                    <Link
                                        key={navPage.pathname}
                                        to={navPage.pathname + (location.search || '')}
                                        replace
                                    >
                                        <ButtonEl
                                            type='button'
                                            actived={location.pathname === navPage.pathname}
                                        >
                                            {navPage.name}
                                        </ButtonEl>
                                    </Link>
                                )
                            })}
                        </LinkItem>
                    );
                })}
            </Container>
        </>
    );
}

const NAV_BAR_LIST = [
    {
        title: '판매성과 요약',
        page: [
            {
                name: '대시보드',
                pathname: '/sales-analysis/dashboard'
            }
        ]
    },
    {
        title: '전체 판매성과',
        page: [
            {
                name: '총 매출액 & 판매 건',
                pathname: '/sales-analysis/total'
            },
        ]
    },
    {
        title: '판매스토어 성과',
        page: [
            {
                name: '총 매출액 & 판매 건',
                pathname: '/sales-analysis/sales-channel'
            },
            {
                name: '다중 상품 성과',
                pathname: '/sales-analysis/sales-channel/multi-product'
            }
        ]
    },
    {
        title: '카테고리 성과',
        page: [
            {
                name: '총 매출액 & 판매 건',
                pathname: '/sales-analysis/category'
            },
            {
                name: 'BEST 카테고리',
                pathname: '/sales-analysis/category/best'
            }
        ]
    },
    {
        title: '서브 카테고리 성과',
        page: [
            {
                name: '총 매출액 & 판매 건',
                pathname: '/sales-analysis/sub-category'
            },
            {
                name: 'BEST 카테고리',
                pathname: '/sales-analysis/sub-category/best'
            }
        ]
    },
    {
        title: '상품 성과',
        page: [
            {
                name: '총 매출액 & 판매 건',
                pathname: '/sales-analysis/product'
            },
            {
                name: '상품 순위',
                pathname: '/sales-analysis/product/best-product'
            },
            {
                name: '단일 상품 성과',
                pathname: '/sales-analysis/product/single-product'
            }
        ]
    },
]