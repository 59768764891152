import { customToast, defaultOptions } from "../../../../../components/toast/custom-react-toastify/v1";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { Container } from "./style/ConditionSelector.styled";
import SelectorFieldView from "./view/SelectorField.view";
import TextFieldView from "./view/TextField.view";

const MAXIMUM_SEARCH_SIZE = 10;

export default function ConditionSelectorComponent(props) {
    const router = useRouterHookV2();

    const returnIsCheckedOne = subCategory => router.state?.productSubCategoryIds?.includes(subCategory.id);

    const handleActionCheckOne = (e, subCategory) => {
        e.stopPropagation();
        let selectedSubCategoryIds = new Set(router.state?.productSubCategoryIds);
    
        if(selectedSubCategoryIds.has(subCategory.id)) {
            selectedSubCategoryIds.delete(subCategory.id);
        } else {
            selectedSubCategoryIds.add(subCategory.id);
        }

        if(selectedSubCategoryIds.size > MAXIMUM_SEARCH_SIZE) {
            let memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`;
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        router.replace({
            state: {
                ...router.state,
                productSubCategoryIds: [...selectedSubCategoryIds]
            }
        })
    }

    const handleActionCheckAll = (e) => {
        e.stopPropagation();
        if(props.subCategories?.length > MAXIMUM_SEARCH_SIZE) {
            let memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`;
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }
        
        let ids = props.subCategories.map(r => r.id);
        router.replace({
            state: {
                ...router.state,
                productSubCategoryIds: ids
            }
        })
    }

    const handleActionCheckCancelAll = (e) => {
        e.stopPropagation();
        
        router.replace({
            state: {
                ...router.state,
                productSubCategoryIds: []
            }
        })
    }

    return (
        <Container>
            <TextFieldView />

            <SelectorFieldView
                isProductSubCategoryLoading={props.isProductSubCategoryLoading}
                subCategories={props.subCategories}

                returnIsCheckedOne={returnIsCheckedOne}
                onActionCheckOne={handleActionCheckOne}
                onActionCheckAll={handleActionCheckAll}
                onActionCheckCancelAll={handleActionCheckCancelAll}
            />
        </Container>
    )
}