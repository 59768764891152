import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import { dateToYYYYMMDD, setPlusDate } from "../../../../../../utils/dateFormatUtils";
import { Container } from "./style/Operator.styled";
import SearchFieldView from "./view/SearchField.view";

export default function OperatorComponent(props) {
    
    const router = useRouterHookV2();

    const handleActionRouteToPage = (pathname) => {
        let searchStartDate = setPlusDate(new Date(), 0, 0, -13);
        let searchEndDate = new Date();
        let categoryId = props.selectedProduct?.productSubCategory?.productCategoryId;
        let subCategoryId = props.selectedProduct?.productSubCategory?.id;
        
        if (router.query.startDate && router.query.endDate) {
            searchStartDate = new Date(router.query.startDate);
            searchEndDate = new Date(router.query.endDate);
        }

        let routerState = {
            startDate: searchStartDate,
            endDate: searchEndDate,
            productCategoryIds: [categoryId],
            productSubCategoryIds: [subCategoryId]
        }

        let data = {
            pathname,
            state: routerState
        }

        router.query.startDate = dateToYYYYMMDD(searchStartDate);
        router.query.endDate = dateToYYYYMMDD(searchEndDate);
        router.push(data);
    }

    return (
        <Container>
            <SearchFieldView
                selectedProduct={props.selectedProduct}

                onActionOpenProductListModal={props.onActionOpenProductListModal}
                onActionRouteToPage={handleActionRouteToPage}
            />
        </Container>
    )
}