import { useEffect, useState } from "react";
import { BackdropHookComponent } from "../../../../../hooks/backdrop/useBackdropHook";
import { Container } from "./index.styled";
import OperatorComponent from "./operator/Operator.component";
import TextInfoComponent from "./text-info/TextInfo.component";
import ConditionSelectorComponent from "./condition-selector/ConditionSelector.component";
import useProductOptionAnalysisHook from "./hooks/useProductOptionAnalysisHook";
import ProductListModalComponent from "./modal/product-list/ProductListModal.component";
import CustomCommonModal from "../../../../../components/modal/common/v1/CustomCommonModal";
import useProductOptionHook from "./hooks/useProductOptionHook";
import PayAmountGraphComponent from "./pay-amount-graph/PayAmountGraph.component";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import useSalesChannelAnalysisHook from "./hooks/useSalesChannelAnalysisHook";
import ChannelPayAmountGraphComponent from "./channel-pay-amount-graph/ChannelPayAmountGraph.component";
import useProductOptionAnalysisByOptionHook from "./hooks/useProductOptionAnalysisByOptionHook";
import OptionItemComponent from "./option-item/OptionItem.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import useProductHook from "./hooks/useProductHook";

export default function SalesAnalysis_ProductSingleProductAnalysisContainerComponent (props) {
    const [searchDimension, setSearchDimension] = useState('date');
    const [checkedSwitch, setCheckedSwitch] = useState(false);
    
    const [productListModalOpen, setProductListModalOpen] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const router = useRouterHookV2();

    const {
        product: selectedProduct
    } = useProductHook();

    const {
        isLoading: isProductOptionSearchLoading,
        options
    } = useProductOptionHook({ selectedProduct });
    
    const {
        isLoading: isProductOptionAnalysisByDateSearchLoading,
        analysis: analysisByDate,
        reqSearchProductOptionAnalysis
    } = useProductOptionAnalysisHook({ selectedProduct });

    const {
        isLoading: isSalesChannelAnalysisLoading,
        analysis: analysisByChannel,
        reqSearchSalesChannelAnalysis
    } = useSalesChannelAnalysisHook({ selectedProduct });

    const {
        isLoading: isProductOptionAnalysisSearchLoading,
        analysis: analysisByOption,
        reqSearchProductOptionAnalysis: reqSearchProductOptionAnalysisByOption
    } = useProductOptionAnalysisByOptionHook({ selectedProduct });

    useEffect(() => {
        if(!options) {
            return;
        }

        handleActionCheckAll();
    }, [options])

    useEffect(() => {
        if(!router.state) {
            setSelectedOptions([]);
        }
    }, [router.state])

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }
    
    const handleChangeDimension = (value) => {
        setSearchDimension(value);
    }

    const handleChangeSelectedProduct = (id) => {
        router.replace({
            state: {
                ...router.state,
                productIds: [id]
            }
        })
        handleActionCloseProductListModal();
    }

    const returnIsCheckedOne = (id) => {
        return selectedOptions.some(r => r.id === id)
    }

    const handleActionCheckOne = (e, id) => {
        e.stopPropagation();
        let data = [...selectedOptions];

        if(data.some(r => r.id === id)) {
            data = data.filter(r => r.id !== id);
        } else {
            let selectedOption = options.filter(r => r.id === id)[0];
            data.push(selectedOption);
        }
        
        setSelectedOptions(data);
    }

    const handleActionCheckAll = () => {
        setSelectedOptions([...options]);
    }

    const handleActionCheckCancelAll = (e) => {
        e.stopPropagation();
        
        setSelectedOptions([]);
    }

    const handleActionOpenProductListModal = () => {
        setProductListModalOpen(true);
    }

    const handleActionCloseProductListModal = () => {
        setProductListModalOpen(false);
    }
    
    const handleSubmitSearchPerformance = async (body, headers) => {
        await reqSearchProductOptionAnalysis(body, headers);
        await reqSearchSalesChannelAnalysis(body, headers);
        await reqSearchProductOptionAnalysisByOption(body, headers);
    }

    return (
        <>
            <Container>
                <TextInfoComponent />

                <OperatorComponent
                    selectedProduct={selectedProduct}

                    onActionOpenProductListModal={handleActionOpenProductListModal}
                />

                <ConditionSelectorComponent
                    isProductOptionSearchLoading={isProductOptionSearchLoading}
                    options={options}
                    returnIsCheckedOne={returnIsCheckedOne}
                    onActionCheckOne={handleActionCheckOne}
                    onActionCheckAll={handleActionCheckAll}
                    onActionCheckCancelAll={handleActionCheckCancelAll}
                />

                <GraphOperatorComponent
                    searchDimension={searchDimension}
                    checkedSwitch={checkedSwitch}

                    onChangeSwitch={handleChangeSwitch}
                    onChangeSearchDimension={handleChangeDimension}
                />

                <PayAmountGraphComponent
                    isProductOptionAnalysisByDateSearchLoading={isProductOptionAnalysisByDateSearchLoading}
                    analysis={analysisByDate}
                    searchDimension={searchDimension}
                    checkedSwitch={checkedSwitch}
                    selectedOptions={selectedOptions}
                />

                <ChannelPayAmountGraphComponent
                    isSalesChannelAnalysisLoading={isSalesChannelAnalysisLoading}
                    analysis={analysisByChannel}
                    searchDimension={searchDimension}
                    checkedSwitch={checkedSwitch}
                    selectedOptions={selectedOptions}
                />

                <OptionItemComponent
                    isProductOptionAnalysisSearchLoading={isProductOptionAnalysisSearchLoading}
                    analysis={analysisByOption}
                    selectedOptions={selectedOptions}
                />

                <DateOperatorComponent
                    selectedProduct={selectedProduct}
                    onSubmitSearchPerformance={handleSubmitSearchPerformance}
                />
            </Container>

            {productListModalOpen &&
                <CustomCommonModal
                    open={productListModalOpen}
                    onClose={handleActionCloseProductListModal}
                    element={
                        <ProductListModalComponent
                            onConfirm={handleChangeSelectedProduct}
                            onClose={handleActionCloseProductListModal}
                        />
                    }
                />
            }
        </>
    )
}