import withMainApiCsrfWrapper from "../utils/withMainApiCsrfWrapper";
import { axiosAuthInterceptor } from "./axiosInterceptors"

const API_ADDRESS = process.env.REACT_APP_MAIN_API_ADDRESS;

const productSubCategoryDataConnect = () => {
    return {
        /**
         * 
         * @param {object} params 
         * @param {string} params.productCategoryId
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchList: async function (params, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.get(`${API_ADDRESS}/api/v1/product-sub-categories/product-categories/${params.productCategoryId}`, {
                    headers: headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
    }
}

export {
    productSubCategoryDataConnect
}