import styled from "styled-components";

const Container = styled.div`
    margin-top: 30px;
`;

const SearchFieldWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    font-weight: 700;
`;

const BoxTitle = styled.div`
    min-width: 100px;
    padding: 0 10px;
`;

const ItemWrapper = styled.div`
    width: 100%;
    padding: 0 5px;
    border-radius: 5px;
    box-shadow: var(--defaultBoxShadow);
    background-color: white;
    overflow: auto;
    min-height: 25px;
    max-height: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-items: flex-start;
`;

const ItemBox = styled.div`
    padding: 2px 5px;
    color: var(--mainColor);
`;

export {
    Container,
    SearchFieldWrapper,
    BoxTitle,
    ItemWrapper,
    ItemBox
}