import _ from "lodash";
import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import CustomDotIcon from "../../../../../../components/icon/dot-icon/v1/CustomDotIcon";
import { GraphDataset, createGraphData, createGraphOption, setAnalysisResultText } from "../../../../../../utils/chartUtils";
import { getRandomColors } from "../../../../../../utils/colorUtils";
import { CustomChartGroupV2 } from "../../../../../../components/chart/v2/CustomChartGroup";
import { toPriceUnitFormat } from "../../../../../../utils/numberFormatUtils";
import FieldCircleLoading from "../../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const SALES_GRAPH_BG_COLOR = ['#FFDE88', '#CCCA80', '#EEE8A9', '#5C9C80', '#FFC988', '#EEE8A9', '#70dbc2', '#D5CABD', '#389091'];

export default function ChannelPayAmountGraphComponent(props) {
    const [salesGraphData, setSalesGraphData] = useState(null);
    const [totalGraphData, setTotalGraphData] = useState(null);
    
    const [salesSummaryData, setSalesSummaryData] = useState(null);
    const [totalSummaryData, setTotalSummaryData] = useState(null);
    
    const [graphOption, setGraphOption] = useState(null);

    useEffect(() => {
        if(!(props.selectedOptions?.length > 0)) {
            handleActionResetGraphData();
            return;
        }

        if(!props.searchDimension) {
            return;
        }

        if(!props.analysis) {
            return;
        }

        handleActionCreateGraphData();
    }, [props.selectedOptions, props.analysis, props.searchDimension])

    useEffect(() => {
        if(!(salesGraphData && totalGraphData)) {
            return;
        }

        handleActionCreateGraphOption();
    }, [salesGraphData, totalGraphData])

    const handleActionResetGraphData = () =>{
        setSalesGraphData(null);
        setTotalGraphData(null);
        setSalesSummaryData(null);
        setTotalSummaryData(null);    
    }

    const handleActionCreateGraphData = () => {
        let graphColor = SALES_GRAPH_BG_COLOR;

        let salesPayAmountData = [];
        let orderPayAmountData = [];
        let graphLabels = [];

        let searchChannelPerformance = [...new Set(props.analysis.map(r => r.salesChannel))].map(channel => {
            return {
                salesChannel: channel,
                salesPayAmount: 0,
                orderPayAmount: 0
            }
        });
        
        let selectedOptionIds = props.selectedOptions?.map(r => r.id) ?? [];
        let searchOptionPerformance = props.analysis.filter(r => selectedOptionIds.includes(r.optionId));

        searchOptionPerformance.forEach(r => {
            searchChannelPerformance = searchChannelPerformance.map(channelData => {
                if (r.salesChannel === channelData.salesChannel) {
                    return {
                        ...channelData,
                        salesPayAmount: channelData.salesPayAmount + r.salesPayAmount,
                        orderPayAmount: channelData.orderPayAmount + r.orderPayAmount
                    }
                } else {
                    return channelData;
                }
            })
        })

        // salesPayAmount 내림차순 정렬
        searchChannelPerformance = _.orderBy(searchChannelPerformance, 'salesPayAmount', 'desc');

        searchChannelPerformance.forEach(r => {
            graphLabels.push(r.salesChannel);
            salesPayAmountData.push(r.salesPayAmount);
            orderPayAmountData.push(r.orderPayAmount);
        })

        let randomColor = getRandomColors(searchChannelPerformance.length - SALES_GRAPH_BG_COLOR.length)
        graphColor = [...graphColor, ...randomColor];

        let orderDatasets = [];
        let salesDatasets = [];

        // 그래프 데이터 세팅
        if (searchChannelPerformance.size === 0) {
            let barGraphOfOrder = {
                ...new GraphDataset().toJSON(),
                label: '주문 매출액',
                data: [],
                type: 'bar',
                fill: false,
                borderColor: graphColor[0],
                backgroundColor: graphColor[0],
                order: -1,
                pointRadius: 2
            }
            let barGraphOfSales = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '판매 매출액',
                data: [],
                borderColor: graphColor[0],
                backgroundColor: graphColor[0],
                borderWidth: 0,
                order: 0
            }
            orderDatasets.push(barGraphOfOrder);
            salesDatasets.push(barGraphOfSales);
        } else {
            graphLabels.forEach((r, idx) => {
                let channelName = r ?? '미지정';

                let barGraphOfOrder = {
                    ...new GraphDataset().toJSON(),
                    type: 'bar',
                    label: '(주문) ' + channelName,
                    data: [orderPayAmountData[idx]],
                    borderColor: graphColor[idx] + '55',
                    backgroundColor: graphColor[idx] + '55',
                    stack: 'group' + idx,
                    borderWidth: 0,
                    order: 1,
                }

                let barGraphOfSales = {
                    ...new GraphDataset().toJSON(),
                    type: 'bar',
                    label: channelName,
                    data: [salesPayAmountData[idx]],
                    borderColor: graphColor[idx],
                    backgroundColor: graphColor[idx],
                    stack: 'group' + idx,
                    borderWidth: 0,
                    order: 0
                }

                orderDatasets.push(barGraphOfOrder);
                salesDatasets.push(barGraphOfSales);
            })
        }

        setSalesGraphData({
            labels: [''],
            datasets: [...salesDatasets]
        });
        setTotalGraphData({
            labels: [''],
            datasets: [...salesDatasets, ...orderDatasets]
        });

        // 매출 그래프 요약 데이터 생성
        handleActionCreateSalesSummary([...salesDatasets]);
        handleActionCreateTotalSummary([...salesDatasets, ...orderDatasets])
    }

    const handleActionCreateSalesSummary = (data) => {
        let salesData = setAnalysisResultText(data);

        // 매출액 내림차순으로 정렬
        salesData = _.orderBy(salesData, 'value', 'desc');
        setSalesSummaryData(salesData);
    }

    const handleActionCreateTotalSummary = (data) => {
        let totalData = setAnalysisResultText(data);

        totalData = _.orderBy(totalData, 'value', 'desc');
        setTotalSummaryData(totalData);
    }

    const handleActionCreateGraphOption = () => {
        let option = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'x',
                intersect: false,
            },
            plugins: {
                legend: {
                    position: 'bottom'
                }
            },
            scales: {
                y: {
                    stacked: false
                },
                x: {
                    grid: {
                        display: false
                    },
                    stacled: true
                }
            }
        }

        setGraphOption(option);
    }
    
    return (
        <CustomChartGroupV2>
            <CustomChartGroupV2.BoardGroup>
                <CustomChartGroupV2.BoardGroup.Title>스토어별 총 매출액</CustomChartGroupV2.BoardGroup.Title>
                <CustomChartGroupV2.BoardGroup.Info>선택된 상품의 스토어별 판매액이 표시됩니다.</CustomChartGroupV2.BoardGroup.Info>
            </CustomChartGroupV2.BoardGroup>

            <div style={{ position: 'relative' }}>
                {props.isSalesChannelAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }
                <CustomChartGroupV2.BodyGroup className='flex-item'>
                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {totalGraphData && graphOption &&
                            <Chart
                                data={createGraphData(props.checkedSwitch ? totalGraphData : salesGraphData)}
                                options={createGraphOption(graphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>

                    <CustomChartGroupV2.BodyGroup.SummaryGroup>
                        <CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryTitle>[스토어 총 매출액]</CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryTitle>
                        <CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryContent>
                            <SummaryContentTextField
                                data={props.checkedSwitch ? totalSummaryData : salesSummaryData}
                            />
                        </CustomChartGroupV2.BodyGroup.SummaryGroup.SummaryContent>
                    </CustomChartGroupV2.BodyGroup.SummaryGroup>
                </CustomChartGroupV2.BodyGroup>
            </div>
        </CustomChartGroupV2>
    )
}

function SummaryContentTextField({ data }) {
    return (
        <ul>
            {data?.map((r, idx) => {
                return (
                    <li key={'graph-summary' + idx}>
                        <div style={{
                            width: '120px',
                            overflow:'hidden',
                            textOverflow:'ellipsis',
                            whiteSpace:'nowrap'
                         }}>
                            <CustomDotIcon color={r.color} />
                            <span> {r.label} </span>
                        </div>
                        <div style={{ fontWeight: 700 }}>
                            {toPriceUnitFormat(r.value)}
                        </div>
                    </li>
                )
            })}
            {!(data && data.length > 0) &&
                <li>
                    <span>데이터가 존재하지 않습니다.</span>
                </li>
            }
        </ul>
    )
}
