import styled from "styled-components";
import CustomImage from "../v1/CustomImage";

const Figure = styled.div`
    width: ${props => props.size};
    height: ${props => props.size};
    margin: 0 auto;
`;

export default function CustomBoxImage({
    src,
    className,
    size='40px',
    ...props
}) {
    return (
        <Figure style={{ width: size, height: size }}>
            <CustomImage src={src} className={className} props={props} />
        </Figure>
    );
}