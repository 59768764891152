import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { CustomChartGroup } from "../../../../../components/chart/v1/CustomChartGroup";
import { GraphDataset, createGraphData, createGraphOption } from "../../../../../utils/chartUtils";
import { toPriceUnitFormat } from "../../../../../utils/numberFormatUtils";
import { Container } from "./style/OptionItem.styled";
import ItemBoardFieldView from "./view/BoardControlButtonField.view";
import TableFieldView from "./view/TableField.view";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";
import BoardControlButtonFieldView from "./view/BoardControlButtonField.view";
import FieldCircleLoading from "../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const DEFAULT_GRAPH_BG_COLOR = ['#67D9BD', '#CAF583', '#80A9E1', '#3CC3F1', '#00DAE6', '#45ECC2', '#A2F794', '#5FA6C7', '#6EB9C5', '#4975A9'];

export default function OptionItemComponent (props) {
    const [viewType, setViewType] = useState('table');

    const [tableData, setTableData] = useState(null);

    const [salesPayAmountGraphData, setSalesPayAmountGraphData] = useState(null);
    const [totalPayAmountGraphData, setTotalPayAmountGraphData] = useState(null);

    const [salesUnitGraphData, setSalesUnitGraphData] = useState(null);
    const [totalUnitGraphData, setTotalUnitGraphData] = useState(null);

    const [payAmountGraphOption, setPayAmountGraphOption] = useState(null);
    const [unitGraphOption, setUnitGraphOption] = useState(null);

    useEffect(() => {
        if (!props.optionAnalysis) {
            return;
        }

        handleActionInitTableData();
        handleActionCreatePayAmountGraphData();
        handleActionCreateUnitGraphData();
    }, [props.optionAnalysis])

    useEffect(() => {
        if (!(totalPayAmountGraphData && totalUnitGraphData)) {
            return;
        }

        handleActionCreatePayAmountGraphOption();
        handleActionCreateUnitGraphOption();
    }, [totalPayAmountGraphData, totalUnitGraphData])

    const handleActionInitTableData = () => {
        let tableData = [...new Set(props.optionAnalysis.map(r => JSON.stringify({
            productId: r.productId,
            productName: r.productName,
            performance: []
        })))].map(r => JSON.parse(r));

        props.optionAnalysis.forEach(r => {
            tableData = tableData.map(r2 => {
                if (r.productId === r2.productId) {
                    let data = r2.performance;
                    data.push(r);

                    return {
                        ...r2,
                        performance: data
                    }
                } else {
                    return r2;
                }
            });
        })

        setTableData(tableData);
    }

    const handleChangeViewType = (e) => {
        let value = e.target.value;
        setViewType(value);
    }

    const handleActionCreatePayAmountGraphData = () => {
        let graphLabels = [];
        let salesPayAmount = [];
        let orderPayAmount = [];
        let searchedProducts = props.productAnalysis.filter(r => r.productId).map(r => {
            return {
                id: r.productId,
                name: r.productName
            }
        });
        let salesPayAmountDatasets = [];
        let orderPayAmountDatasets = [];
        let createdSalesGraphs = [];
        let createdTotalGraphs = [];

        // 검색된 상품별 옵션 데이터 추출
        searchedProducts.forEach(product => {
            let label = [];
            let salesData = [];
            let orderData = [];

            props.optionAnalysis.forEach(r => {
                if(r.productId === product.id) {
                    label.push(r.optionName);
                    salesData.push(r.salesPayAmount);
                    orderData.push(r.orderPayAmount);
                }
            })

            graphLabels.push(label);
            salesPayAmount.push(salesData);
            orderPayAmount.push(orderData);
        })

        searchedProducts.forEach((product, idx) => {
            let salesDataset = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: product.name,
                data: salesPayAmount[idx],
                backgroundColor: DEFAULT_GRAPH_BG_COLOR[idx],
                borderColor: DEFAULT_GRAPH_BG_COLOR[idx],
                borderWidth: 0,
                order: 0
            }

            let orderDataset = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '(주문) ' + product.name,
                data: orderPayAmount[idx],
                fill: false,
                backgroundColor: DEFAULT_GRAPH_BG_COLOR[idx] + '55',
                borderColor: DEFAULT_GRAPH_BG_COLOR[idx] + '55',
                borderWidth: 0,
                order: 1
            }

            salesPayAmountDatasets.push(salesDataset);
            orderPayAmountDatasets.push(orderDataset);
        })

        searchedProducts.forEach((r, idx) => {
            let createdSalesGraph = {
                labels: graphLabels[idx],
                datasets: [salesPayAmountDatasets[idx]]
            }

            let createdTotalGraph = {
                labels: graphLabels[idx],
                datasets: [salesPayAmountDatasets[idx], orderPayAmountDatasets[idx]]
            }

            createdSalesGraphs.push(createdSalesGraph);
            createdTotalGraphs.push(createdTotalGraph);
        })

        setSalesPayAmountGraphData(createdSalesGraphs);
        setTotalPayAmountGraphData(createdTotalGraphs);
    }

    const handleActionCreateUnitGraphData = () => {
        let graphLabels = [];
        let salesUnit = [];
        let orderUnit = [];
        let searchedProducts = props.productAnalysis.filter(r => r.productId).map(r => {
            return {
                id: r.productId,
                name: r.productName
            }
        });
        let salesUnitDatasets = [];
        let orderUnitDatasets = [];
        let createdSalesGraphs = [];
        let createdTotalGraphs = [];

        // 검색된 상품별 옵션 데이터 추출
        searchedProducts.forEach(product => {
            let label = [];
            let salesData = [];
            let orderData = [];

            props.optionAnalysis.forEach(r => {
                if(r.productId === product.id) {
                    label.push(r.optionName);
                    salesData.push(r.salesUnit);
                    orderData.push(r.orderUnit);
                }
            })

            graphLabels.push(label);
            salesUnit.push(salesData);
            orderUnit.push(orderData);
        })

        searchedProducts.forEach((product, idx) => {
            let salesDataset = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: product.name,
                data: salesUnit[idx],
                backgroundColor: DEFAULT_GRAPH_BG_COLOR[idx],
                borderColor: DEFAULT_GRAPH_BG_COLOR[idx],
                borderWidth: 0,
                order: 0
            }

            let orderDataset = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '(주문) ' + product.name,
                data: orderUnit[idx],
                fill: false,
                backgroundColor: DEFAULT_GRAPH_BG_COLOR[idx] + '55',
                borderColor: DEFAULT_GRAPH_BG_COLOR[idx] + '55',
                borderWidth: 0,
                order: 1
            }

            salesUnitDatasets.push(salesDataset);
            orderUnitDatasets.push(orderDataset);
        })

        searchedProducts.forEach((r, idx) => {
            let createdSalesGraph = {
                labels: graphLabels[idx],
                datasets: [salesUnitDatasets[idx]]
            }

            let createdTotalGraph = {
                labels: graphLabels[idx],
                datasets: [salesUnitDatasets[idx], orderUnitDatasets[idx]]
            }

            createdSalesGraphs.push(createdSalesGraph);
            createdTotalGraphs.push(createdTotalGraph);
        })

        setSalesUnitGraphData(createdSalesGraphs);
        setTotalUnitGraphData(createdTotalGraphs);
    }

    const handleActionCreatePayAmountGraphOption = () => {
        let priceOption = {
            responsive: true,
            maxBarThickness: 20,
            maintainAspectRatio: false,
            interaction: {
                mode: 'y',
                intersect: false,
            },
            indexAxis: 'y',
            scales: {
                x: {
                    ticks: {
                        callback: function (value, index, ticks) {
                            return toPriceUnitFormat(value);
                        }
                    }
                },
                y: {
                    // 글자 수 7글자로 제한
                    afterTickToLabelConversion: function (scaleInstance) {
                        let ticks = scaleInstance.ticks;

                        let updatedTicks = ticks.map(r => {
                            let updatedLabel = r.label;
                            if (updatedLabel.length > 7) {
                                return {
                                    ...r,
                                    label: updatedLabel.substring(0, 7) + "..."
                                }
                            } else {
                                return r;
                            }
                        })

                        scaleInstance.ticks = updatedTicks;
                    },
                    // label width 고정
                    afterFit: function(scaleInstance) {
                        scaleInstance.width = 100;
                    },
                    stacked: true,
                    grid: {
                        display: false
                    }
                }
            }
        }
        setPayAmountGraphOption(priceOption);
    }

    const handleActionCreateUnitGraphOption = () => {
        let unitOption = {
            responsive: true,
            maxBarThickness: 20,
            maintainAspectRatio: false,
            interaction: {
                mode: 'y',
                intersect: false,
            },
            indexAxis: 'y',
            scales: {
                y: {
                    // 글자 수 7글자로 제한
                    afterTickToLabelConversion: function (scaleInstance) {
                        let ticks = scaleInstance.ticks;

                        let updatedTicks = ticks.map(r => {
                            let updatedLabel = r.label;
                            if (updatedLabel.length > 7) {
                                return {
                                    ...r,
                                    label: updatedLabel.substring(0, 7) + "..."
                                }
                            } else {
                                return r;
                            }
                        })

                        scaleInstance.ticks = updatedTicks;
                    },
                    // label width 고정
                    afterFit: function(scaleInstance) {
                        scaleInstance.width = 100; 
                    },
                    stacked: true,
                    grid: {
                        display: false
                    }
                }
            }
        }

        setUnitGraphOption(unitOption);
    }

    return (
        <CustomChartGroupV2>
            <CustomChartGroupV2.BoardGroup className='flex-item'>
                <div>
                    <CustomChartGroupV2.BoardGroup.Title>옵션 성과</CustomChartGroupV2.BoardGroup.Title>
                    <CustomChartGroupV2.BoardGroup.Info>상품 매출 / 수량 순위에서 조회된 상품의 모든 옵션 판매 데이터를 표시합니다.</CustomChartGroupV2.BoardGroup.Info>
                </div>
                <BoardControlButtonFieldView
                    viewType={viewType}
                    onChangeViewType={handleChangeViewType}
                />
            </CustomChartGroupV2.BoardGroup>

            <div style={{ position: 'relative' }}>
                {props.isOptionAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }

                <div style={{ position: 'relative', minHeight: '500px' }}>

                    {viewType === 'table' && tableData &&
                        <CustomChartGroupV2.BodyGroup style={{ padding: '0px', overflow: 'auto' }}>
                            <TableFieldView
                                tableData={tableData}
                                pageOrderByColumn={props.pageOrderByColumn}
                            />
                        </CustomChartGroupV2.BodyGroup>
                    }

                    {viewType === 'graph' &&
                        salesPayAmountGraphData?.map((r, idx) => {
                            return (
                                <CustomChartGroupV2.BodyGroup key={'option-analysis-graph' + idx} className='flex-item'>
                                    <CustomChartGroupV2.BodyGroup.ChartBody>
                                        {totalPayAmountGraphData && payAmountGraphOption &&
                                            <Chart
                                                data={createGraphData(props.checkedSwitch ? totalPayAmountGraphData[idx] : salesPayAmountGraphData[idx])}
                                                options={createGraphOption(payAmountGraphOption)}
                                            />
                                        }
                                    </CustomChartGroupV2.BodyGroup.ChartBody>

                                    <CustomChartGroupV2.BodyGroup.ChartBody>
                                        {totalUnitGraphData && unitGraphOption &&
                                            <Chart
                                                data={createGraphData(props.checkedSwitch ? totalUnitGraphData[idx] : salesUnitGraphData[idx])}
                                                options={createGraphOption(unitGraphOption)}
                                            />
                                        }
                                    </CustomChartGroupV2.BodyGroup.ChartBody>
                                </CustomChartGroupV2.BodyGroup>
                            )
                        })
                    }
                </div>
            </div>
        </CustomChartGroupV2>
    )
}