import { BoxTitle, ItemBox, ItemWrapper, SearchFieldWrapper } from "../style/ConditionSearch.styled";

export default function SearchFieldView ({ selectedSubCategories }) {
    return (
        <SearchFieldWrapper>
            <BoxTitle>선택 카테고리 : </BoxTitle>
            <ItemWrapper>
                {selectedSubCategories?.map((r, idx) => {
                    return (
                        <ItemBox
                            key={'slaes-channel-idx' + idx}
                            className='item-box'
                        >
                            <span>{r.name}</span>
                        </ItemBox>
                    )
                })}
            </ItemWrapper>
        </SearchFieldWrapper>
    )
}