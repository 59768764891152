import styled from "styled-components";

const Container = styled.div`
`;

const ItemBox = styled.div`
    height: 350px;
    overflow-y: scroll;
    cursor: pointer;
    background: #fff;
    /* padding: 0 10px; */
    border-radius: 10px;
    border: 1px solid #f0f0f0;

    &::-webkit-scrollbar{
        background: #e1e1e130;
        height:7px;
        width: 5px;
    }

    &::-webkit-scrollbar-track{
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb{
        background-color: #00000020;
        border-radius: 10px;
    }
`;

const ItemEl = styled.div`
    font-size: 14px;
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 20px;
    transition: 0.15s;

    &:hover {
        background-color: var(--defaultGrayColor);
    }

    &:active {
        background-color: var(--defaultGrayColor30);
    }

    &:last-child{
        border-bottom: none;
    }

    .category-info{
        color: var(--mainColor);
        font-weight: 600;
        color:#404040;
    }
`;

export {
    Container,
    ItemBox,
    ItemEl
}