import styled from 'styled-components';
import CustomBlockButton from '../../block-button/v1/CustomBlockButton';

const ButtonEl = styled(CustomBlockButton)`
    width: 40px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    background-color: ${props => props.checked ? 'var(--mainColor) !important' : 'var(--defaultButtonColor)'};
    border: 1px solid var(--mainColor);
    color: ${props => props.checked ? '#fff': '#000'};


    &:hover {
        background-color: var(--defaultButtonHoverColor);
    }
`;

export default function DateButton({
    callback,
    checked,
    ...props
}) {
    const handleClickButton = () => {
        callback('date');
    }

    return (
        <ButtonEl
            type='button'
            checked={checked}
            onClick={() => handleClickButton()}
            value='date'
            {...props}
        >
            일
        </ButtonEl>
    );
}