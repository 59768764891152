import CustomCommonModal from "../../../../../../components/modal/common/v1/CustomCommonModal";
import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import { dateToYYYYMMDD } from "../../../../../../utils/dateFormatUtils";
import { Container } from "./DetailGraphSelector.styled";
import ButtonFieldView from "./view/ButtonField.view";

export default function DetailGraphSelectorModalComponent(props) {
    const router = useRouterHookV2();

    const handleActionRouteToPage = (pathname) => {
        let routerState = {
            startDate: router.state.startDate,
            endDate: router.state.endDate,
            productIds: router.state.productIds
        }
        
        let data = {
            pathname,
            state: routerState
        }

        router.query.startDate = dateToYYYYMMDD(router.state.startDate);
        router.query.endDate = dateToYYYYMMDD(router.state.endDate);
        router.push(data);
    }

    return (
        props.modalOpen && 
        <CustomCommonModal
            open={props.modalOpen}
            title={'그래프 선택'}
            element={
                <Container>
                    <ButtonFieldView
                        onActionRouteToPage={handleActionRouteToPage}
                    />
                </Container>
            }
            maxWidth={'xs'}

            onClose={() => props.onActionCloseModal()}
        />
    )
}