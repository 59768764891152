import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { workspaceDataConnect } from "../../data_connect/workspaceDataConnect";
import useRouterHookV2 from "../../hooks/router/useRouterHookV2";

export default function WorkspacePermissionComponent(props) {
    const reduxDispatch = useDispatch();
    const userRedux = useSelector(state => state.userRedux);
    
    const router = useRouterHookV2();
    
    let workspaceId = router.query?.workspaceId;

    useEffect(() => {
        async function fetchInit() {
            await __handle.req.dispatchWorkspaceRedux(workspaceId);
        }
        if(!userRedux?.userInfo?.id){
            return;
        }

        fetchInit();
    }, [userRedux?.userInfo?.id, workspaceId]);

    const __handle = {
        req: {
            dispatchWorkspaceRedux: async (workspaceId) => {
                await workspaceDataConnect().getWorkspace(workspaceId)
                    .then(res => {
                        if (res.status === 200) {
                            reduxDispatch({
                                type: 'WORKSPACE_REDUX_SET_DATA',
                                payload: {
                                    isLoading: false,
                                    workspaceInfo: res.data.data
                                }
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err, err.response);
                        reduxDispatch({
                            type: 'WORKSPACE_REDUX_CLEAR_WORKSPACE_INFO'
                        })
                    })
            }
        }
    }
    return null;
}