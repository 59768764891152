import { ButtonEl, BoardControlFieldWrapper } from "../style/OptionItem.styled";

export default function BoardControlButtonFieldView ({ viewType, onChangeViewType }) {
    return (
        <BoardControlFieldWrapper>
            <div>
                <ButtonEl
                    onClick={(e) => { onChangeViewType(e) }}
                    checked={viewType === 'table'}
                    value='table'
                >
                    표
                </ButtonEl>
            </div>
            <div>
                <ButtonEl
                    onClick={(e) => { onChangeViewType(e) }}
                    checked={viewType === 'graph'}
                    value='graph'
                >
                    그래프
                </ButtonEl>
            </div>
        </BoardControlFieldWrapper>
    )
}