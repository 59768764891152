import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    overflow: auto;
`;

export default function BodyBox({
    children
}) {
    return (
        <Container>
            {children}
        </Container>
    )
}

BodyBox.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};
