import CustomDotIcon from "../../../../../../components/icon/dot-icon/v1/CustomDotIcon";
import { HeaderInfoFieldWrapper, HeaderBox, MobileHeaderBox, HeaderItem } from "../style/DailyAnalysis.styled";

export default function HeaderInfoFieldView () {
    return (
        <HeaderInfoFieldWrapper>
            <HeaderBox>
                <HeaderItem>어제</HeaderItem>
                <HeaderItem>오늘</HeaderItem>
            </HeaderBox>

            <MobileHeaderBox>
                <div>
                    <CustomDotIcon
                        color='#ffae4c'
                        ariaLable='어제'
                        style={{ marginRight: '5px' }}
                    />
                    <span>어제</span>
                </div>
                <div>
                    <CustomDotIcon
                        color='var(--mainColor)'
                        ariaLable='오늘'
                        style={{ marginRight: '5px' }}
                    />
                    <span>오늘</span>
                </div>
            </MobileHeaderBox>
        </HeaderInfoFieldWrapper>
    )
}