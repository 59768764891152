import 'chart.js/auto';
import styled from "styled-components";
import PropTypes from 'prop-types';

const Container = styled.div`
    flex: 1;
    min-width: ${props => props.width};
    max-width: ${props => props.width};
    height: ${props => props.height};
`;

export default function ChartBody({
    children,
    width,
    height='450px',
    ...props
}) {
    return (
        <Container width={width} height={height} {...props}>
            {children}
        </Container>
    )
}

ChartBody.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
