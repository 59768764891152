import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { GraphDataset, createGraphData, createGraphOption } from "../../../../../utils/chartUtils";
import { getRandomColors } from "../../../../../utils/colorUtils";
import { getDayName, getWeekName } from "../../../../../utils/dateFormatUtils";
import { toPriceUnitFormat } from "../../../../../utils/numberFormatUtils";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";
import FieldCircleLoading from "../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const SALES_GRAPH_BG_COLOR = ['#6EB9C5', '#61CBC6', '#6CDCBA', '#5FA6C7', '#8EEAA3', '#698FC1', '#BFF388', '#F9F871'];

export default function PayAmountDayOfWeekGraphComponent (props) {
    const [avgGraphData, setAvgGraphData] = useState(null);
    const [graphOption, setGraphOption] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        if(!router.state?.productSubCategoryIds) {
            handleActionResetGraphData();
            return;
        }

        if(!(props.subCategories?.length > 0)) {
            return;
        }

        if(!props.analysis) {
            return;
        }

        handleActionCreateAvgGraphData();
    }, [router.state, props.subCategories, props.analysis])

    useEffect(() => {
        if(!avgGraphData) {
            return;
        }

        handleActionCreateGraphOption();
    }, [avgGraphData])

    const handleActionResetGraphData = () => {
        setAvgGraphData(null);
    }

    const handleActionCreateAvgGraphData = () => {
        let salesPayAmountData = [];
        let salesDatasets = [];
        let graphLabels = getWeekName();
        let subCategoryIds = [...router.state.productSubCategoryIds];
        let selectedSubCategories = props.subCategories.filter(r => subCategoryIds.includes(r.id));

        let categoryPayAmount = selectedSubCategories.map(r => {
            let data = graphLabels.map(r2 => {
                return {
                    dayName: r2,
                    salesPayAmount: 0,
                    frequency: 0
                }
            })

            return {
                subCategoryId: r.id,
                subCategoryName: r.name,
                payAmountData: data
            }
        });

        /**
         * categoryPayAmount = [
         *  {
         *      category: A,
         *      payAmountData: [
         *          {dayName: '일', salesPayAmount: -, frequency: -},
         *          {dayName: '월', salesPayAmount: -. freqiemcy: -},
         *          ...
         *      ]
         *  }, {...}, {...}]
         */
        categoryPayAmount = categoryPayAmount.map(r => {
            let categoryPayAmountData = [...r.payAmountData];

            props.analysis.forEach(data => {
                let day = getDayName(data.datetime);

                data.performances.forEach(r2 => {
                    if (r2.productSubCategoryId === r.subCategoryId) {
                        categoryPayAmountData = categoryPayAmountData.map(r3 => {
                            if (r3.dayName === day) {
                                return {
                                    ...r3,
                                    salesPayAmount: r3.salesPayAmount + r2.salesPayAmount,
                                    frequency: r3.frequency + 1
                                }
                            } else {
                                return r3;
                            }
                        });
                    };
                });
            })

            return {
                ...r,
                payAmountData: categoryPayAmountData
            }
        });

        categoryPayAmount.forEach(r => {
            let categorySalesPayAmountData = [];
            r.payAmountData.forEach(r2 => {
                let salesAvg = parseInt((r2.salesPayAmount) / r2.frequency);
                categorySalesPayAmountData.push(salesAvg);
            })
            salesPayAmountData.push(categorySalesPayAmountData);
        })

        let randomColor = getRandomColors(selectedSubCategories.length - SALES_GRAPH_BG_COLOR.length)
        let graphColor = [...SALES_GRAPH_BG_COLOR, ...randomColor];

        if (selectedSubCategories.size === 0) {
            let barGraphOfSales = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '판매 매출액',
                data: [],
                borderColor: graphColor[0],
                backgroundColor: graphColor[0],
                borderWidth: 0,
                order: 0
            }
            salesDatasets.push(barGraphOfSales);
        } else {
            categoryPayAmount.forEach((r, idx) => {
                let barGraphOfSales = {
                    ...new GraphDataset().toJSON(),
                    type: 'bar',
                    label: r.subCategoryName,
                    data: salesPayAmountData[idx],
                    borderColor: graphColor[idx],
                    backgroundColor: graphColor[idx],
                    borderWidth: 0,
                    order: 0
                }
                salesDatasets.push(barGraphOfSales);
            })
        }

        // 매출 그래프 데이터 생성
        setAvgGraphData({
            labels: graphLabels,
            datasets: salesDatasets
        });
    }

    const handleActionCreateGraphOption = () => {
        let option = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                y: {
                    ticks: {
                        callback: function (value, index, ticks) {
                            return toPriceUnitFormat(value);
                        }
                    }
                },
                x: {
                    grid: {
                        display: false
                    }
                }
            }
        }

        setGraphOption(option);
    }

    return (
        <CustomChartGroupV2>
            <CustomChartGroupV2.BoardGroup>
                <CustomChartGroupV2.BoardGroup.Title>요일별 매출액</CustomChartGroupV2.BoardGroup.Title>
                <CustomChartGroupV2.BoardGroup.Info>조회된 기간의 요일별 평균 판매액이 표시됩니다.</CustomChartGroupV2.BoardGroup.Info>
            </CustomChartGroupV2.BoardGroup>

            <div style={{ position: 'relative' }}>
                {props.isSubCategoryAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }
                <CustomChartGroupV2.BodyGroup>
                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {avgGraphData && graphOption &&
                            <Chart
                                data={createGraphData(avgGraphData)}
                                options={createGraphOption(graphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>
                </CustomChartGroupV2.BodyGroup>
            </div>
        </CustomChartGroupV2>
    )
}