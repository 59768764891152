import { useState } from "react";
import CustomCommonModal from "../../../../../components/modal/common/v1/CustomCommonModal";
import ProductCategorySelectorModalComponent from "./modal/product-category-selector/ProductCategorySelectorModal.component";
import { Container } from "./style/Operator.styled";
import SearchFieldView from "./view/SearchField.view";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";

export default function OperatorComponent (props) {
    const [productCategoriesSelectorModalOpen, setProductCategoriesSelectorModalOpen] = useState(false);
    const router = useRouterHookV2();

    const handleActionOpenProductCategorySelectorModal = () => {
        setProductCategoriesSelectorModalOpen(true);
    }

    const handleActionCloseProductCategorySelectorModal = () => {
        setProductCategoriesSelectorModalOpen(false);
    }

    const handleActionSelectProductCategory = (id) => {
        props.onActionUpdateSelectedProductCategory(id)
        handleActionCloseProductCategorySelectorModal();
    }

    return (
        <>
            <Container>
                <SearchFieldView
                    selectedProductCategory={props.selectedProductCategory}
                    onActionOpenProductCategorySelectorModal={handleActionOpenProductCategorySelectorModal}
                />
            </Container>

            {props.productCategories && 
                <CustomCommonModal
                    open={productCategoriesSelectorModalOpen}
                    onClose={handleActionCloseProductCategorySelectorModal}
                    element={
                        <ProductCategorySelectorModalComponent
                            productCategories={props.productCategories}

                            onConfirm={handleActionSelectProductCategory}
                            onClose={handleActionCloseProductCategorySelectorModal}
                        />
                    }
                />
            }
        </>
    )
}