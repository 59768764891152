import styled from "styled-components";

const Container = styled.div`

`;

const TextInfoFieldWrapper = styled.div`
    padding: 0 10px;
`;

const TextBox = styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: #202e5d;

    display: flex;
    align-items: center;

    @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
        font-size: 14px;
    }
`;

const DateTextBox = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
`;

export {
    Container,
    TextInfoFieldWrapper,
    TextBox,
    DateTextBox
}