import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { salesAnalysisDataConnect } from "../../../../../../data_connect/salesAnalysisDataConnect";
import { getEndDate, getEndDateOfMonth, getStartDate, getStartDateOfMonth, getTimeDiffWithUTC, setPlusDate } from "../../../../../../utils/dateFormatUtils";
import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import defaultErrorHandler from "../../../../../../handler/dataConnectErrorHandler";

const TODAY = new Date();

export default function useLastMonthAnalysisHook () {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [lastMonthAnalysis, setLastMonthAnalysis] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const router = useRouterHookV2();

    useEffect(() => {
        async function fetchInit() {
            let lastMonth = setPlusDate(TODAY, 0, -1, 0);
            let startDate = getStartDate(getStartDateOfMonth(lastMonth));
            let endDate = getEndDate(getEndDateOfMonth(lastMonth));
            let utcHourDifference = getTimeDiffWithUTC();
            let searchPeriodType = router.query.periodType ?? 'registration';

            let body = {
                startDate,
                endDate,
                utcHourDifference,
                periodType: searchPeriodType
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchLastMonthAnalysis(body, headers);
        }

        fetchInit();
    }, [router.location])

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchLastMonthAnalysis = async (body, headers) => {
        toggleIsLoading(true);
        await salesAnalysisDataConnect().searchForConditionalAnalysis(body, headers)
            .then(res => {
                if (res.status === 200) {
                    setLastMonthAnalysis(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
        toggleIsLoading(false);
    }

    return {
        isLoading,
        lastMonthAnalysis,
        reqSearchLastMonthAnalysis
    }
}