import { BoxWrapper, ChannelDataBox, ContentBox, DailyChannelAnalysisFieldWrapper, DataContentWrapper, DataLabelBox } from "../../style/DailyAnalysis.styled";
import SkeletonEl from "../../../../../../../components/skeleton/v1/SkeletonEl";

export default function DailyChannelAnalysisSkeletonFieldView () {
    return (
        <DailyChannelAnalysisFieldWrapper>
            <DataBoxField />
            <DataBoxField />
        </DailyChannelAnalysisFieldWrapper>
    )
}

function DataBoxField() {
    return (
        <BoxWrapper>
            <ChannelDataBox>
                <DataLabelBox>
                    <SkeletonEl variant="rounded" width={120} height={30}
                    />
                </DataLabelBox>
                <DataContentWrapper>
                    <ContentBox>
                        <SkeletonEl variant="rounded" width={120} height={30} />
                    </ContentBox>
                </DataContentWrapper>
            </ChannelDataBox>
        </BoxWrapper>
    )
}