
import SkeletonEl from "../../../../../../../components/skeleton/v1/SkeletonEl";
import { BoxWrapper, ContentValue, ContentBox, MonthlyAnalysisFieldWrapper, TitleText } from "../../style/MonthlyAnalysis.styled";

function AnalysisBoxField () {
    return (
        <BoxWrapper>
            <TitleText><SkeletonEl variant="rounded" width={140} height={30} /></TitleText>
            <div style={{ width: '100%' }}>
                <ContentBox>
                    <div><SkeletonEl variant="rounded" width={80} height={20} /></div>
                    <ContentValue><SkeletonEl variant="rounded" width={120} height={20} /></ContentValue>
                </ContentBox>
                <ContentBox>
                    <div><SkeletonEl variant="rounded" width={80} height={20} /></div>
                    <ContentValue><SkeletonEl variant="rounded" width={120} height={20} /></ContentValue>
                </ContentBox>
                <ContentBox>
                    <div><SkeletonEl variant="rounded" width={80} height={20} /></div>
                    <ContentValue><SkeletonEl variant="rounded" width={120} height={20} /></ContentValue>
                </ContentBox>
            </div>
        </BoxWrapper>
    )
}

export default function MonthlyAnalysisSkeletonFieldView() {
    return (
        <MonthlyAnalysisFieldWrapper>
            <AnalysisBoxField />
            <AnalysisBoxField />
        </MonthlyAnalysisFieldWrapper>
    )
}