import CustomImage from "../../../../../../components/image/v1/CustomImage";
import { getDayName } from "../../../../../../utils/dateFormatUtils";
import { getTrendPercentage, toPriceUnitFormat } from "../../../../../../utils/numberFormatUtils";
import { BoxWrapper, DailyAnalysisFieldWrapper, DataBox, DataBoxGroup, DataLabelBox, MainContentBox, SubContentBox, TrendContentWrapper, TrendInfoBox } from "../style/DailyAnalysis.styled";

function TrendInfoField({ dayInfo, trendValue }) {
    return (
        <TrendContentWrapper>
            <div>{dayInfo}</div>
            <div style={{ width: '100px', marginTop: '5px' }}>
                {trendValue >= 0 ?
                    <TrendInfoBox color='var(--defaultRedColor)'>
                        <CustomImage
                            src='/images/icon/trending_up_fill_e56767.svg'
                            style={{ width: '20px' }}
                            alt=""
                            loading='lazy'
                        />
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{Math.abs(trendValue)}%</div>
                    </TrendInfoBox>
                    :
                    <TrendInfoBox color='var(--defaultBlueColor)'>
                        <CustomImage
                            src='/images/icon/trending_down_fill_418cff.svg'
                            style={{ width: '20px' }}
                            alt=""
                            loading='lazy'
                        />
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{Math.abs(trendValue)}%</div>
                    </TrendInfoBox>
                }
            </div>
        </TrendContentWrapper>
    )
}

export default function DailyAnalysisFieldView({ todayData, yesterdayData, prev7DaysData, prev8DaysData }) {
    return (
        <DailyAnalysisFieldWrapper>
            {/* YESTERDAY PERFORMANCE */}
            {yesterdayData &&
                <BoxWrapper>
                    <DataBoxGroup>
                        <DataBox datetime='yesterday'>
                            <MainContentBox>
                                <DataLabelBox>주문 금액</DataLabelBox>
                                <div className='data-content'>
                                    {toPriceUnitFormat(yesterdayData.orderPayAmount)}
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev8DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(yesterdayData.orderPayAmount, prev8DaysData.orderPayAmount)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='yesterday'>
                            <MainContentBox>
                                <DataLabelBox>판매 금액</DataLabelBox>
                                <div className='data-content'>
                                    {toPriceUnitFormat(yesterdayData.salesPayAmount)}
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev8DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(yesterdayData.salesPayAmount, prev8DaysData.salesPayAmount)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='yesterday'>
                            <MainContentBox>
                                <DataLabelBox>미판매 금액</DataLabelBox>
                                <div className='data-content'>
                                    {toPriceUnitFormat(yesterdayData.unsalesPayAmount)}
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev8DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(yesterdayData.unsalesPayAmount, prev8DaysData.unsalesPayAmount)}
                                />
                            </SubContentBox>
                        </DataBox>
                    </DataBoxGroup>
                    
                    <DataBoxGroup>
                        <DataBox datetime='yesterday'>
                            <MainContentBox>
                                <DataLabelBox>주문 건수</DataLabelBox>
                                <div className='data-content'>
                                    {yesterdayData.orderRegistration} 건
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev8DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(yesterdayData.orderRegistration, prev8DaysData.orderRegistration)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='yesterday'>
                            <MainContentBox>
                                <DataLabelBox>판매 건수</DataLabelBox>
                                <div className='data-content'>
                                    {yesterdayData.salesRegistration} 건
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev8DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(yesterdayData.salesRegistration, prev8DaysData.salesRegistration)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='yesterday'>
                            <MainContentBox>
                                <DataLabelBox>미판매 건수</DataLabelBox>
                                <div className='data-content'>
                                    {yesterdayData.unsalesRegistration} 건
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev8DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(yesterdayData.unsalesRegistration, prev8DaysData.unsalesRegistration)}
                                />
                            </SubContentBox>
                        </DataBox>
                    </DataBoxGroup>

                    <DataBoxGroup>
                        <DataBox datetime='yesterday'>
                            <MainContentBox>
                                <DataLabelBox>주문 수량</DataLabelBox>
                                <div className='data-content'>
                                    {yesterdayData.orderUnit} 개
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev8DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(yesterdayData.orderUnit, prev8DaysData.orderUnit)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='yesterday'>
                            <MainContentBox>
                                <DataLabelBox>판매 수량</DataLabelBox>
                                <div className='data-content'>
                                    {yesterdayData.salesUnit} 개
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev8DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(yesterdayData.salesUnit, prev8DaysData.salesUnit)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='yesterday'>
                            <MainContentBox>
                                <DataLabelBox>미판매 수량</DataLabelBox>
                                <div className='data-content'>
                                    {yesterdayData.unsalesUnit} 개
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev8DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(yesterdayData.unsalesUnit, prev8DaysData.unsalesUnit)}
                                />
                            </SubContentBox>
                        </DataBox>
                    </DataBoxGroup>
                </BoxWrapper>
            }

            {/* TODAY PERFORMANCE */}
            {todayData &&
                <BoxWrapper>
                    <DataBoxGroup>
                        <DataBox datetime='today'>
                            <MainContentBox>
                                <DataLabelBox>주문 금액</DataLabelBox>
                                <div className='data-content'>
                                    {toPriceUnitFormat(todayData.orderPayAmount)}
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`어제 (${getDayName(yesterdayData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.orderPayAmount, yesterdayData.orderPayAmount)}
                                />
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev7DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.orderPayAmount, prev7DaysData.orderPayAmount)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='today'>
                            <MainContentBox>
                                <DataLabelBox>판매 금액</DataLabelBox>
                                <div className='data-content'>
                                    {toPriceUnitFormat(todayData.salesPayAmount)}
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`어제 (${getDayName(yesterdayData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.salesPayAmount, yesterdayData.salesPayAmount)}
                                />
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev7DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.salesPayAmount, prev7DaysData.salesPayAmount)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='today'>
                            <MainContentBox>
                                <DataLabelBox>미판매 금액</DataLabelBox>
                                <div className='data-content'>
                                    {toPriceUnitFormat(todayData.unsalesPayAmount)}
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`어제 (${getDayName(yesterdayData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.unsalesPayAmount, yesterdayData.unsalesPayAmount)}
                                />
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev7DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.unsalesPayAmount, prev7DaysData.unsalesPayAmount)}
                                />
                            </SubContentBox>
                        </DataBox>
                    </DataBoxGroup>

                    <DataBoxGroup>
                        <DataBox datetime='today'>
                            <MainContentBox>
                                <DataLabelBox>주문 건수</DataLabelBox>
                                <div className='data-content'>
                                    {todayData.orderRegistration} 건
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`어제 (${getDayName(yesterdayData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.orderRegistration, yesterdayData.orderRegistration)}
                                />
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev7DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.orderRegistration, prev7DaysData.orderRegistration)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='today'>
                            <MainContentBox>
                                <DataLabelBox>판매 건수</DataLabelBox>
                                <div className='data-content'>
                                    {todayData.salesRegistration} 건
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`어제 (${getDayName(yesterdayData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.salesRegistration, yesterdayData.salesRegistration)}
                                />
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev7DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.salesRegistration, prev7DaysData.salesRegistration)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='today'>
                            <MainContentBox>
                                <DataLabelBox>미판매 건수</DataLabelBox>
                                <div className='data-content'>
                                    {todayData.unsalesRegistration} 건
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`어제 (${getDayName(yesterdayData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.unsalesRegistration, yesterdayData.unsalesRegistration)}
                                />
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev7DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.unsalesRegistration, prev7DaysData.unsalesRegistration)}
                                />
                            </SubContentBox>
                        </DataBox>
                    </DataBoxGroup>

                    <DataBoxGroup>
                        <DataBox datetime='today'>
                            <MainContentBox>
                                <DataLabelBox>주문 수량</DataLabelBox>
                                <div className='data-content'>
                                    {todayData.orderUnit} 개
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`어제 (${getDayName(yesterdayData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.orderUnit, yesterdayData.orderUnit)}
                                />
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev7DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.orderUnit, prev7DaysData.orderUnit)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='today'>
                            <MainContentBox>
                                <DataLabelBox>판매 수량</DataLabelBox>
                                <div className='data-content'>
                                    {todayData.salesUnit} 개
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`어제 (${getDayName(yesterdayData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.salesUnit, yesterdayData.salesUnit)}
                                />
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev7DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.salesUnit, prev7DaysData.salesUnit)}
                                />
                            </SubContentBox>
                        </DataBox>
                        <DataBox datetime='today'>
                            <MainContentBox>
                                <DataLabelBox>미판매 수량</DataLabelBox>
                                <div className='data-content'>
                                    {todayData.unsalesUnit} 개
                                </div>
                            </MainContentBox>
                            <SubContentBox>
                                <TrendInfoField
                                    dayInfo={`어제 (${getDayName(yesterdayData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.unsalesUnit, yesterdayData.unsalesUnit)}
                                />
                                <TrendInfoField
                                    dayInfo={`지난주 (${getDayName(prev7DaysData.datetime)}) 대비`}
                                    trendValue={getTrendPercentage(todayData.unsalesUnit, prev7DaysData.unsalesUnit)}
                                />
                            </SubContentBox>
                        </DataBox>
                    </DataBoxGroup>
                </BoxWrapper>
            }
        </DailyAnalysisFieldWrapper>
    )
}