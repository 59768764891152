import { Container } from "./style/ConditionField.styled";
import ConditionFieldView from "./view/ConditionField.view";

export default function ConditionFieldComponent({ periodType, onChangePeriodType }) {
    return (
        <Container>
            <ConditionFieldView
                periodType={periodType}
                onChangePeriodType={onChangePeriodType}
            />
        </Container>
    )
}