import { Container } from "./style/GraphOperator.styled";
import GraphOperatorFieldView from "./view/GraphOperatorField.view";

export default function GraphOperatorComponent (props) {
    return (
        <Container>
            <GraphOperatorFieldView
                searchDimension={props.searchDimension}
                checkedSwitch={props.checkedSwitch}

                onChangeSwitch={props.onChangeSwitch}
                onChangeSearchDimension={props.onChangeSearchDimension}
            />
        </Container>
    )
}

