import _ from "lodash";
import { useEffect, useState } from "react";
import { dateToYYYYMMDD, setPlusDate } from "../../../../../utils/dateFormatUtils";
import useChannelAnalysisHook from "./hooks/useChannelAnalysisHook";
import useDashboardHook from "./hooks/useDashboardHook";
import { Container } from "./style/DailyAnalysis.styled";
import DailyAnalysisFieldView from "./view/DailyAnalysisField.view";
import DailyChannelAnalysisFieldView from "./view/DailyChannelAnalysisField.view";
import HeaderInfoFieldView from "./view/HeaderInfoField.view";
import DailyChannelAnalysisSkeletonFieldView from "./view/skeleton/DailyChannelAnalysisSkeletonField.view";
import DailyAnalysisSkeletonFieldView from "./view/skeleton/DailyAnalysisSkeletonField.view";

const TODAY = new Date();
const YESTERDAY = setPlusDate(TODAY, 0, 0, -1);
const PREV_7DAYS = setPlusDate(TODAY, 0, 0, -7);
const PREV_8DAYS = setPlusDate(TODAY, 0, 0, -8);

export default function DailyAnalysisComponent () {
    const [todayData, setTodayData] = useState(null);
    const [yesterdayData, setYesterdayData] = useState(null);
    const [prev7DaysData, setPrev7DaysData] = useState(null);
    const [prev8DaysData, setPrev8DaysData] = useState(null);

    const [channelAnalysisData, setChannelAnalysisData] = useState(null);

    const {
        isLoading: isDashboardLoading,
        dashboard
    } = useDashboardHook();

    const {
        isLoading: isChannelAnalysisLoading,
        channelAnalysis
    } = useChannelAnalysisHook();

    useEffect(() => {
        if(!dashboard) {
            return;
        }

        if(!channelAnalysis) {
            return;
        }

        handleInitDashboardData();
        handleInitChannelAnalysisData();
    }, [dashboard, channelAnalysis])

    const handleInitDashboardData = () => {
        dashboard.forEach(r => {
            let data = {
                ...r,
                unsalesPayAmount: r.orderPayAmount - r.salesPayAmount,
                unsalesUnit: r.orderUnit - r.salesUnit,
                unsalesRegistration: r.orderRegistration - r.salesRegistration
            }

            switch(data.datetime) {
                case dateToYYYYMMDD(TODAY):
                    setTodayData({...data});
                    return;
                case dateToYYYYMMDD(YESTERDAY):
                    setYesterdayData({...data});
                    return;
                case dateToYYYYMMDD(PREV_7DAYS):
                    setPrev7DaysData({...data});
                    return;
                case dateToYYYYMMDD(PREV_8DAYS):
                    setPrev8DaysData({...data})
                    return;
            }
        })
    }

    const handleInitChannelAnalysisData = () => {
        let todayData = {};
        let yesterdayData = {};
        
        channelAnalysis.forEach(r => {
            let sortedPerformances = _.orderBy(r.performances, 'salesPayAmount', 'desc');
            let salesPayAmountSum = 0;
            
            r.performances.forEach(performance => {
                salesPayAmountSum += performance.salesPayAmount;
            })

            let data = {
                ...r,
                channelPayAmount: sortedPerformances,
                totalPayAmount: salesPayAmountSum
            }

            // 초기화된 데이터 세팅
            switch(data.datetime) {
                case dateToYYYYMMDD(TODAY):
                    todayData = {...data};
                    return;
                case dateToYYYYMMDD(YESTERDAY):
                    yesterdayData = {...data};
                    return;
            }
        })

        setChannelAnalysisData({todayData, yesterdayData});
    }

    return (
        <>
            <Container>
                <HeaderInfoFieldView />
                {isChannelAnalysisLoading ?
                    <DailyChannelAnalysisSkeletonFieldView />
                    :
                    <DailyChannelAnalysisFieldView
                        channelAnalysisData={channelAnalysisData}
                    />
                }

                {(isDashboardLoading || !(todayData && yesterdayData)) ?
                    <DailyAnalysisSkeletonFieldView />
                    :
                    <DailyAnalysisFieldView
                        todayData={todayData}
                        yesterdayData={yesterdayData}
                        prev7DaysData={prev7DaysData}
                        prev8DaysData={prev8DaysData}
                    />
                }
            </Container>
        </>
    )
}