import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
    .title {
        font-size : 1.2rem;
        font-weight: 700;
    }

    .info-text {
        margin-top: 5px;
        font-size: 14px;
    }
`;

export default function Title({
    title,
    info
}) {
    return (
        <Container>
            <div className='title'>{title}</div>
            <div className='info-text'>{info}</div>
        </Container>
    )
}

Title.propTypes = {
    title: PropTypes.string,
    info: PropTypes.string
}
