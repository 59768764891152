import PrimaryNavbarMainComponent from "../../views/navbar/primary-navbar";
import SecondaryNavbarMainComponent from "../../views/navbar/secondary-navbar-v2";
import WorkspaceRequireComponent from "../../views/workspace-require";

export default function WorkspaceRequirePage () {
    return (
        <>
            <PrimaryNavbarMainComponent />
            <SecondaryNavbarMainComponent />
            <WorkspaceRequireComponent />
        </>
    )
}