import React from "react";
import { toPriceUnitFormat } from "../../../../../../utils/numberFormatUtils";
import { FixedTh, ItemTr, LeftTr, TableEl, TableFieldWrapper } from "../style/OptionItem.styled";

export default function TableFieldView ({ pageOrderByColumn, tableData }) {
    return (
        <TableFieldWrapper>
            <div>
                <TableEl width='100%'>
                    <thead>
                        <tr>
                            <FixedTh scope='col' width={70}>{pageOrderByColumn === 'payAmount' ? '매출' : '수량'}</FixedTh>
                            <FixedTh scope='col' width={150}>상품명</FixedTh>
                            <FixedTh scope='col' width={200}>옵션명</FixedTh>
                            <FixedTh scope='col' width={150}>판매매출</FixedTh>
                            <FixedTh scope='col' width={100}>판매 수량</FixedTh>
                            <FixedTh scope='col' width={150}>미판매 매출</FixedTh>
                            <FixedTh scope='col' width={100}>미판매 수량</FixedTh>
                        </tr>
                    </thead>
                    <tbody style={{ borderTop: 'none' }}>
                        {tableData?.map((r, idx) => {
                            let tdBackgroundColor = (idx % 2 === 1) ? 'var(--defaultGrayColor)' : '#ffffff';
                            let rowSpanSize = r.performance.length + 1;

                            return (
                                <React.Fragment key={idx}>
                                    <LeftTr backgroundColor={tdBackgroundColor}>
                                        <td
                                            rowSpan={rowSpanSize}
                                        >
                                            [{idx + 1}]
                                        </td>
                                        <td
                                            rowSpan={rowSpanSize}
                                            style={{ borderRight: '1px solid rgb(224 224 224)' }}
                                        >
                                            {r.productName}
                                        </td>
                                    </LeftTr>
                                    {r.performance?.map((r2, idx2) => {
                                        return (
                                            <ItemTr
                                                key={'option-data-idx' + idx2}
                                                style={{ backgroundColor: tdBackgroundColor }}
                                            >
                                                <td>{r2.optionName}</td>
                                                <td style={{ color: 'var(--mainColor)', fontWeight: 600 }}>{toPriceUnitFormat(r2.salesPayAmount)}</td>
                                                <td>{r2.salesUnit}</td>
                                                <td style={{ color: '#ff3060', fontWeight: 600 }}>{toPriceUnitFormat(r2.orderPayAmount - r2.salesPayAmount)}</td>
                                                <td>{r2.orderUnit - r2.salesUnit}</td>
                                            </ItemTr>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </tbody>
                </TableEl>
            </div>
        </TableFieldWrapper>
    )
}