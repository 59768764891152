import styled from "styled-components";

const Container = styled.div`

`;

const GraphOperatorFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export {
    Container,
    GraphOperatorFieldWrapper
}