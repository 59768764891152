import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { productCategoryDataConnect } from "../../../../../data_connect/productCategoryDataConnect";
import defaultErrorHandler from "../../../../../handler/dataConnectErrorHandler";

export default function useProductCategoriesHook() {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [productCategories, setProductCategories] = useState(null);

    useEffect(() => {
        async function fetchInit() {
            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchCategoryAnalysis(headers);
        }

        fetchInit();
    }, [])

    const reqSearchCategoryAnalysis = async (body, headers) => {
        await productCategoryDataConnect().searchAll(body, headers)
            .then(res => {
                if (res.status === 200) {
                    setProductCategories(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
    }

    return {
        productCategories
    }
}