import { useState } from "react";
import { useSelector } from "react-redux";
import CustomCommonModal from "../../../../../components/modal/common/v1/CustomCommonModal";

import { getEndDate, getStartDate, getTimeDiffWithUTC, setPlusDate } from "../../../../../utils/dateFormatUtils";
import useProductHook from "./hooks/useProductHook";
import ProductListModalComponent from "./modal/product-list/ProductListModal.component";
import { Container } from "./style/Operator.styled";
import ButtonFieldView from "./view/ButtonField.view";
import SearchFieldView from "./view/SearchField.view";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { customToast, defaultOptions } from "../../../../../components/toast/custom-react-toastify/v1";

const MAXIMUM_SEARCH_SIZE = 10;

export default function OperatorComponent (props) {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [productListModalOpen, setProductListModalOpen] = useState(false);
    
    const router = useRouterHookV2();

    const {
        isLoading: isProductSearchLoading,
        products,
        reqSearchProductAll,
        onActionClearProducts
    } = useProductHook();

    const handleActionOpenProductListModal = () => {
        setProductListModalOpen(true);
    }

    const handleActionCloseProductListModal = () => {
        setProductListModalOpen(false);
        onActionClearProducts();
    }

    const handleActionSelectProduct = (id) => {
        let selectedProduct = selectedProducts.some(r => r.id === id);
        if (selectedProduct) {
            return;
        }

        if(selectedProducts.length >= MAXIMUM_SEARCH_SIZE) {
            let memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`;
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        let product = products?.content?.filter(product => product.id === id)[0];
        let selectedList = [...selectedProducts].concat(product);
        setSelectedProducts(selectedList);
    }

    const handleActionRemoveProduct = (e, id) => {
        e.preventDefault();

        let updatedList = selectedProducts?.filter(product => product.id !== id);
        setSelectedProducts(updatedList);
    }

    const handleActionResetSelectedProduct = () => {
        setSelectedProducts([]);
        props.onActionResetPerformance();
    }

    const handleChangeSelectedProduct = () => {
        props.onActionChangeSelectedProduct(selectedProducts);
        let memo = '';
        
        if (!(selectedProducts?.length > 0)) {
            props.onActionResetPerformance();
            memo = '상품을 먼저 선택해주세요.';
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        if(selectedProducts.length > MAXIMUM_SEARCH_SIZE) {
            memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`;
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        let searchProductIds = selectedProducts.map(r => r.id);
        let searchStartDate = setPlusDate(new Date(), 0, 0, -13);
        let searchEndDate = new Date();
        let periodType = router.query.periodType ?? 'registration';

        if (router.query.startDate && router.query.endDate) {
            searchStartDate = new Date(router.query.startDate);
            searchEndDate = new Date(router.query.endDate);
        }

        let body = {
            startDate: getStartDate(searchStartDate),
            endDate: getEndDate(searchEndDate),
            utcHourDifference: getTimeDiffWithUTC(),
            productIds: searchProductIds,
            periodType: periodType
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        props.onSubmitSearchPerformance(body, headers);
    }
    
    const handleSubmitSearchProductPage = async (params, headers) => {
        await reqSearchProductAll(params, headers);
    }

    return (
        <>
            <Container>
                <SearchFieldView
                    selectedProducts={selectedProducts}

                    onActionOpenProductListModal={handleActionOpenProductListModal}
                    onActionRemoveProduct={handleActionRemoveProduct}
                />
                <ButtonFieldView
                    onActionResetSelectedProduct={handleActionResetSelectedProduct}
                    onActionChangeSelectedProduct={handleChangeSelectedProduct}
                />
            </Container>

            {productListModalOpen &&
                <CustomCommonModal
                    open={productListModalOpen}
                    onClose={handleActionCloseProductListModal}
                    element={
                        <ProductListModalComponent
                            isProductSearchLoading={isProductSearchLoading}
                            products={products}
    
                            onActionSearchProductPage={handleSubmitSearchProductPage}
                            onConfirm={handleActionSelectProduct}
                            onClose={handleActionCloseProductListModal}
                        />
                    }
                />
            }
        </>
    )
}