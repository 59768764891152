import { CustomDatePeriodButtonGroup } from "../../../../../../../components/buttons/date-period/v1/CustomDatePeriodButtonGroup";
import { FixDateRangeSelectorFieldWrapper } from "../style/DateOperator.styled";

export default function FixDateRangeSelectorFieldView({ onActionSearchDateRange }) {
    return (
        <FixDateRangeSelectorFieldWrapper>
            <CustomDatePeriodButtonGroup className='custom-button-group'>
                <CustomDatePeriodButtonGroup.Weeks1Button
                    className='custom-button'
                    rippleColor={'var(--mainColorOpacity30)'}
                    callback={(result) => onActionSearchDateRange(result)}
                />
                <CustomDatePeriodButtonGroup.Weeks2Button
                    className='custom-button'
                    rippleColor={'var(--mainColorOpacity30)'}
                    callback={(result) => onActionSearchDateRange(result)}
                />
                <CustomDatePeriodButtonGroup.Days30Button
                    className='custom-button'
                    rippleColor={'var(--mainColorOpacity30)'}
                    callback={(result) => onActionSearchDateRange(result)}
                />
                <CustomDatePeriodButtonGroup.Days90Button
                    className='custom-button'
                    rippleColor={'var(--mainColorOpacity30)'}
                    callback={(result) => onActionSearchDateRange(result)}
                />
                <CustomDatePeriodButtonGroup.ThisMonthButton
                    className='custom-button'
                    rippleColor={'var(--mainColorOpacity30)'}
                    callback={(result) => onActionSearchDateRange(result)}
                />
                <CustomDatePeriodButtonGroup.LastMonthButton
                    className='custom-button'
                    rippleColor={'var(--mainColorOpacity30)'}
                    callback={(result) => onActionSearchDateRange(result)}
                />
            </CustomDatePeriodButtonGroup>
        </FixDateRangeSelectorFieldWrapper>
    )
}