import styled from "styled-components";
import DateButton from "./DateButton";
import WeekButton from "./WeekButton";
import MonthButton from "./MonthButton";

const Container = styled.div`
    display: flex;
`;

function PeriodTypeButtonGroupMain({ children, ...props }) {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
}

export const CustomPeriodTypeButtonGroup = Object.assign(PeriodTypeButtonGroupMain, {
    DateButton: DateButton,
    WeekButton: WeekButton,
    MonthButton: MonthButton
})