import styled from "styled-components";

const Container = styled.div`
    padding: 20px;
`;

const ButtonFieldWrapper = styled.div`
    padding-bottom: 30px;
`;

const Title = styled.div`
    font-size: 1.2rem;
    padding: 5px;

    @media screen and (max-width: 992px) {
        font-size: 14px;
    }
`;

const ButtonGroup = styled.div`
    padding: 10px 0;
`;

const ButtonEl = styled.button`
    width: 100%;
    padding: 10px;
    border: 1px solid #e1e1e1;
    background-color: white;
    box-shadow: var(--defaultBoxShadow);
    font-size: 1.2rem;
    border-radius: 10px;
    transition: 0.15s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;

    @media screen and (max-width: 992px) {
        flex-direction: column;
        font-size: 14px;
    }
`;

export {
    Container,
    ButtonFieldWrapper,
    ButtonEl,
    Title,
    ButtonGroup
}