import { axiosAuthInterceptor } from "./axiosInterceptors"

const AUTH_API_ADDRESS = process.env.REACT_APP_AUTH_API_ADDRESS;

const workspaceDataConnect = () => {
    return {
        getWorkspace: async function (workspaceId) {
            return await axiosAuthInterceptor.get(`${AUTH_API_ADDRESS}/auth/v1/workspaces/${workspaceId}`, {
                withCredentials: true,
                xsrfCookieName: 'x_auth_csrf_token',
                xsrfHeaderName: 'X-XSRF-TOKEN'
            })
        }
    }
}

export {
    workspaceDataConnect
}