import { Dialog } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CustomBoxImage from '../../../image/box-image/CustomBoxImage';

const HeaderBox = styled.div`
    padding: 10px 20px;
`;

const CloseBox = styled.div`
    display: flex;
    justify-content: flex-end;

    .button-el {
        width: 45px;
        height: 45px;
        position: relative;
        overflow: hidden;
        padding: 0;

        border: none;
        background-color: inherit;
        border-radius: 50%;
        cursor: pointer;

        :hover {
            transition: 0.15s;
            background-color: #efefef;
        }
    }
`;

const TitleBox = styled.div`
    font-size: 1.3rem;
    font-weight: 600;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
`;

const ElementBox = styled.div`
    font-size: 14px;
    font-weight: 600;
    max-height: 70vh;
    overflow: auto;
`;

/**
 * 
 * @param {Object} props
 * @param {boolean} props.open
 * @param {boolean} props.fullWidth
 * @param {string} props.maxWidth
 * @param {function} props.onClose
 * @param {string} props.title
 * @param {string} props.element
 * @returns 
 */
const CustomCommonModal = ({ open, fullWidth, maxWidth, onClose, title, element, ...props }) => {
    
    const _onClose = (e) => {
        e.preventDefault();
        onClose(e);
    }

    return (
        <>
            <Dialog
                open={open ?? false}
                fullWidth={fullWidth ?? true}
                maxWidth={maxWidth || 'xs'}
                onClose={(e) => _onClose(e) || {}}
            >
                <div style={{ backgroundColor: 'var(--defaultBackground)' }}>
                    <HeaderBox>
                        <CloseBox>
                            <button
                                type='button'
                                className='button-el'
                                onClick={(e) => _onClose(e) || {}}
                            >
                                <CustomBoxImage
                                    src='/images/icon/close_default_959eae.svg'
                                    size={45}
                                />
                            </button>
                        </CloseBox>
                        <TitleBox>{title || ''}</TitleBox>
                    </HeaderBox>
                    <ElementBox>
                        {element || ''}
                    </ElementBox>
                </div>
            </Dialog>
        </>
    );
}

CustomCommonModal.prototype = {
    open: PropTypes.bool.isRequired,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    onClose: PropTypes.func,
    title: PropTypes.string,
    element: PropTypes.string
}

export default CustomCommonModal;