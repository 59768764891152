import { BoxWrapper, DailyAnalysisFieldWrapper, DataBox, DataBoxGroup, MainContentSkeletonBox, SubContentSkeletonBox, TrendContentWrapper, TrendInfoBox, TrendInfoSkeletonBox } from "../../style/DailyAnalysis.styled";
import SkeletonEl from "../../../../../../../components/skeleton/v1/SkeletonEl";

function TrendInfoField() {
    return (
        <TrendContentWrapper>
            <div style={{ width: '100px', marginTop: '5px' }}>
                <TrendInfoSkeletonBox>
                    <SkeletonEl variant="rounded" width={100} height={30} />
                </TrendInfoSkeletonBox>
            </div>
        </TrendContentWrapper>
    )
}

function DataBoxGroupField({ datetime }) {
    return (
        <DataBoxGroup>
            <DataBox>
                <MainContentSkeletonBox>
                    <SkeletonEl variant="rounded" width={70} height={20} />
                    <SkeletonEl variant="rounded" width={50} height={30} />
                </MainContentSkeletonBox>
                {datetime === 'today' ?
                    <SubContentSkeletonBox>
                        <TrendInfoField />
                        <TrendInfoField />
                    </SubContentSkeletonBox>
                    :
                    <SubContentSkeletonBox>
                        <TrendInfoField />
                    </SubContentSkeletonBox>
                }
            </DataBox>
            <DataBox>
                <MainContentSkeletonBox>
                    <SkeletonEl variant="rounded" width={70} height={20} />
                    <SkeletonEl variant="rounded" width={50} height={30} />
                </MainContentSkeletonBox>
                {datetime === 'today' ?
                    <SubContentSkeletonBox>
                        <TrendInfoField />
                        <TrendInfoField />
                    </SubContentSkeletonBox>
                    :
                    <SubContentSkeletonBox>
                        <TrendInfoField />
                    </SubContentSkeletonBox>
                }
            </DataBox>
            <DataBox>
                <MainContentSkeletonBox>
                    <SkeletonEl variant="rounded" width={70} height={20} />
                    <SkeletonEl variant="rounded" width={50} height={30} />
                </MainContentSkeletonBox>
                {datetime === 'today' ?
                    <SubContentSkeletonBox>
                        <TrendInfoField />
                        <TrendInfoField />
                    </SubContentSkeletonBox>
                    :
                    <SubContentSkeletonBox>
                        <TrendInfoField />
                    </SubContentSkeletonBox>
                }
            </DataBox>
        </DataBoxGroup>
    )
}

export default function DailyAnalysisSkeletonFieldView() {
    return (
        <DailyAnalysisFieldWrapper>
            <BoxWrapper>
                <DataBoxGroupField datetime='yesterday' />
                <DataBoxGroupField datetime='yesterday' />
                <DataBoxGroupField datetime='yesterday' />
            </BoxWrapper>

            <BoxWrapper>
                <DataBoxGroupField datetime='today' />
                <DataBoxGroupField datetime='today' />
                <DataBoxGroupField datetime='today' />
            </BoxWrapper>
        </DailyAnalysisFieldWrapper>
    )
}