import styled from "styled-components";

const Container = styled.div`
    padding: 0 20px;
`;

const MonthlyAnalysisFieldWrapper = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    font-size: 1rem;

    @media screen and (max-width: 1280px) {
        flex-direction: column;
    }
`;

const BoxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    box-shadow: var(--defaultBoxShadow);
    border-radius: 10px;
    margin-right: 10px;
    padding: 10px 20px;
    width: 350px;
    height: 150px;
    border: 1px solid #a2a8b1;
    background-color: #465265;
    color: #d7d7d7;

    @media screen and (max-width: 1280px) {
        width: 100%;
    }
`;

const TitleText = styled.div`
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 1.1rem;
`;

const ContentBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const ContentValue = styled.div`
    font-weight: 700;
    color: white;
`;

export {
    Container,
    MonthlyAnalysisFieldWrapper,
    BoxWrapper,
    TitleText,
    ContentBox,
    ContentValue
}