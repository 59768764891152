import Ripple from "../../../../../../components/ripple/v1/Ripple";
import { SearchButtonEl, SearchFieldWrapper } from "../style/Operator.styled";

export default function SearchFieldView({ selectedProductCategory, onActionOpenProductCategorySelectorModal }) {
    return (
        <SearchFieldWrapper>
            <SearchButtonEl
                type='button'
                onClick={() => onActionOpenProductCategorySelectorModal()}
            >
                {selectedProductCategory?.name || '상위 카테고리 선택'}
                <Ripple color={'#e0e0e0'} duration={1000} />
            </SearchButtonEl>
        </SearchFieldWrapper>
    )
}