import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useBestProductAnalysisHook from "./hooks/useBestProductAnalysisHook";
import useBestProductRelatedOptionAnalysisHook from "./hooks/useBestProductRelatedOptionAnalysisHook";
import { getEndDate, getStartDate, getTimeDiffWithUTC, setPlusDate } from "../../../../utils/dateFormatUtils";
import { Container } from "./index.styled";
import NavBarComponent from "../../navbar/NavBar.component";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import TextInfoComponent from "./text-info/TextInfo.component";
import BestProductGraphComponent from "./best-product-graph/BestProductGraph.component";
import GraphDataPagenationComponent from "./graph-data-pagenation/GraphDataPagenation.component";
import OptionItemComponent from "./option-item/OptionItem.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";
import DetailGraphSelectorModalComponent from "./modal/detail-graph-selector/DetailGraphSelector.component";
import useRouterHookV2 from "../../../../hooks/router/useRouterHookV2";

const DEFAULT_SEARCH_START_DATE = setPlusDate(new Date(), 0, 0, -13);
const DEFAULT_SEARCH_END_DATE = new Date();

export default function SalesAnalysis_ProductBestProductComponent () {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [checkedSwitch, setCheckedSwitch] = useState(false);
    
    const [detailGraphSelectorModalOpen, setDetailGraphSelectorModalOpen] = useState(null);
    
    const [pageOrderByColumn, setPageOrderByColumn] = useState('payAmount');
    const [pageIndex, setPageIndex] = useState(0);

    const router = useRouterHookV2();

    const {
        isLoading: isProductAnalysisLoading,
        analysis : productAnalysis,
        reqSearchBestProductAnalysis,
    } = useBestProductAnalysisHook({ pageOrderByColumn });

    const {
        isLoading: isOptionAnalysisLoading,
        analysis : optionAnalysis
    } = useBestProductRelatedOptionAnalysisHook({ productAnalysis });

    useEffect(() => {
        if(productAnalysis?.content?.length > 0) {
            setPageIndex(productAnalysis?.number)
        }
    }, [productAnalysis])

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }

    const handleChangePrevPageIndex = () => {
        let page = pageIndex - 1;

        let body = {
            startDate: getStartDate(router.query.startDate ?? DEFAULT_SEARCH_START_DATE),
            endDate: getEndDate(router.query.endDate ?? DEFAULT_SEARCH_END_DATE),
            utcHourDifference: getTimeDiffWithUTC(),
            periodType: router.query.periodType ?? 'registration'
        }

        let params = {
            page: page + 1,
            sort: pageOrderByColumn + '_desc'
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        setPageIndex(page);
        handleSubmitSearchPerformance(body, params, headers);
    }

    const handleChangeNextPageIndex = () => {
        let page = pageIndex + 1;

        let body = {
            startDate: getStartDate(router.query.startDate ?? DEFAULT_SEARCH_START_DATE),
            endDate: getEndDate(router.query.endDate ?? DEFAULT_SEARCH_END_DATE),
            utcHourDifference: getTimeDiffWithUTC(),
            periodType: router.query.periodType ?? 'registration'
        }

        let params = {
            page: page + 1,
            sort: pageOrderByColumn + '_desc'
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        setPageIndex(page);
        handleSubmitSearchPerformance(body, params, headers);
    }

    const handleChangePageOrderByColumn = (e) => {
        let value = e.target.value;

        let body = {
            startDate: getStartDate(router.query.startDate ?? DEFAULT_SEARCH_START_DATE),
            endDate: getEndDate(router.query.endDate ?? DEFAULT_SEARCH_END_DATE),
            utcHourDifference: getTimeDiffWithUTC(),
            periodType: router.query.periodType ?? 'registration'
        }

        let params = {
            page: 0,
            sort: value + '_desc'
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        setPageOrderByColumn(value);
        setPageIndex(0);
        handleSubmitSearchPerformance(body, params, headers);
    }

    const handleActionOpenDetailGraphSelectorModal = () => {
        setDetailGraphSelectorModalOpen(true);
    }

    const handleActionCloseDetailGraphSelectorModal = () => {
        setDetailGraphSelectorModalOpen(false);
    }

    const handleSubmitSearchPerformance = async (body, params, headers) => {
        await reqSearchBestProductAnalysis(body, params, headers)
    }

    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'상품 성과'}
                    headerName={'상품 순위'}
                    sidebarColor={'#ffffff'}
                >
                    <>
                        <TextInfoComponent />

                        <GraphOperatorComponent
                            checkedSwitch={checkedSwitch}

                            onActionChangeSwitch={handleChangeSwitch}
                        />

                        <BestProductGraphComponent
                            isProductAnalysisLoading={isProductAnalysisLoading}
                            checkedSwitch={checkedSwitch}
                            analysis={productAnalysis?.content}
                            
                            onActionOpenDetailGraphSelectorModal={handleActionOpenDetailGraphSelectorModal}
                        />

                        <GraphDataPagenationComponent
                            pageOrderByColumn={pageOrderByColumn}
                            productAnalysis={productAnalysis}
                            pageIndex={pageIndex}

                            onChangePrevPageIndex={handleChangePrevPageIndex}
                            onChangeNextPageIndex={handleChangeNextPageIndex}
                            onActionChangePageOrderByColumn={handleChangePageOrderByColumn}
                        />

                        <OptionItemComponent
                            isOptionAnalysisLoading={isOptionAnalysisLoading}
                            checkedSwitch={checkedSwitch}
                            optionAnalysis={optionAnalysis}
                            pageOrderByColumn={pageOrderByColumn}
                            productAnalysis={productAnalysis?.content}
                        />
                    </>
                </NavBarComponent>

                <DateOperatorComponent
                    pageOrderByColumn={pageOrderByColumn}
                    onSubmitSearchPerformance={handleSubmitSearchPerformance}
                />
            </Container>

            <DetailGraphSelectorModalComponent
                modalOpen={detailGraphSelectorModalOpen}

                onActionCloseModal={handleActionCloseDetailGraphSelectorModal}
            />
        </>
    )
}
