import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { salesAnalysisDataConnect } from "../../../../../data_connect/salesAnalysisDataConnect";

import { getEndDate, getStartDate, getTimeDiffWithUTC, setPlusDate } from "../../../../../utils/dateFormatUtils";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import defaultErrorHandler from "../../../../../handler/dataConnectErrorHandler";

export default function useCategoryProductAnalysisHook({ pageOrderByColumn }) {
    const workspaceRedux = useSelector(state => state.workspaceRedux);
    
    const [isLoading, setIsLoading] = useState(false);
    const [analysis, setAnalysis] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        async function fetchInit() {
            let searchStartDate = setPlusDate(new Date(), 0, 0, -13);
            let searchEndDate = new Date();
            let periodType = router.query.periodType ?? 'registration';

            if (router.query.startDate && router.query.endDate) {
                searchStartDate = new Date(router.query.startDate);
                searchEndDate = new Date(router.query.endDate);
            }

            let body = {
                startDate: getStartDate(searchStartDate),
                endDate: getEndDate(searchEndDate),
                utcHourDifference: getTimeDiffWithUTC(),
                productCategoryIds: router.state?.productCategoryIds,
                includeUndefinedCategoryFlag: router.state?.includeUndefinedCategoryFlag,
                periodType
            }

            let params = {
                page: 0,
                sort: pageOrderByColumn + '_desc'
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchCategoryBestProductAnalysis(body, params, headers);
        }

        fetchInit();
    }, [])

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchCategoryBestProductAnalysis = async (body, params, headers) => {
        toggleIsLoading(true);
        await salesAnalysisDataConnect().searchBestProductAnalysis(body, params, headers)
            .then(res => {
                if (res.status === 200) {
                    setAnalysis(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
        toggleIsLoading(false);
    }

    return {
        isLoading,
        analysis,
        reqSearchCategoryBestProductAnalysis
    }
}