import { BoardTitle, TableBoardFieldWrapper } from "../style/SummaryTable.styled";

export default function TableBoardFieldView (props) {
    return (
        <TableBoardFieldWrapper>
            <div>
                <BoardTitle>매출 BEST</BoardTitle>
                <div style={{ fontSize: '14px' }}>
                    <span>조회된 기간의 판매 데이터를 표시합니다. </span>
                    <span>판매 매출액 기준으로 정렬됩니다. </span>
                </div>
            </div>
        </TableBoardFieldWrapper>
    )
}