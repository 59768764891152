import withMainApiCsrfWrapper from "../utils/withMainApiCsrfWrapper";
import { axiosAuthInterceptor } from "./axiosInterceptors"

const API_ADDRESS = process.env.REACT_APP_MAIN_API_ADDRESS;

const productDataConnect = () => {
    return {
        /**
         * 
         * @param {object} params
         * @param {string} params.productCategoryId
         * @param {string} params.productSubCategoryId
         * @param {string} params.searchCondition
         * @param {string} params.searchQuery
         * @param {string} params.sort
         * @param {int} params.page
         * @param {int} params.size
         * @param {object} headers 
         * @param {string} headers.wsId
         * @returns 
         */
        searchAll: async function (params, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.get(`${API_ADDRESS}/api/v1/products/page/related:productCategoryAndProductSubCategory`, {
                    headers,
                    params,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} params
         * @param {string} params.productId
         * @param {object} headers 
         * @param {string} headers.wsId
         * @returns 
         */
        searchOne: async function (params, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.get(`${API_ADDRESS}/api/v1/products/${params.productId}`, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
    }
}

export {
    productDataConnect
}