import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import { useRef } from 'react';
import PropTypes from "prop-types";

const Container = styled.div`
    font-weight: 400;
    height: 100%;

    .react-datepicker {
        border: none;
        box-shadow: var(--defaultBoxShadow);
        width: 100%;
    }

    .react-datepicker__day-names {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .react-datepicker__day-names, .react-datepicker__week {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .react-datepicker__header {
        background:white;
        padding: 15px;
    }

    .react-datepicker__navigation{
        top: 15px;
    }

    .react-datepicker__triangle{
        display: none;
    }

    .react-datepicker__day--selected {
        background-color: var(--mainColor);
    }

    .react-datepicker__day--keyboard-selected {
        background-color: var(--mainColor);
    }

    .react-datepicker__month-text--keyboard-selected {
        background-color: var(--mainColor);

        :hover {
            background-color: var(--mainColor);
        }
    }

    .react-datepicker__month {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 200px;
    }

    .react-datepicker__month-wrapper {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .react-datepicker__month-container {
        width: 100%;
    }

    .react-datepicker__month .react-datepicker__month-text {
        padding: 10px 5px;
    }

    .react-datepicker__month--selected {
        background-color: var(--mainColor) !important;
    }
`;

const CustomDatePicker = ({ selected, onChange, isMonthYearPicker, props }) => {
    const calendar = useRef(null);

    return (
        <>
            <Container>
                <DatePicker
                    locale={ko}
                    dateFormat={'yyyy-MM-dd'}
                    selected={selected || null}
                    onChange={onChange}
                    showMonthYearPicker={isMonthYearPicker ?? false}
                    shouldCloseOnSelect={false}
                    inline
                    ref={calendar}
                    disabledKeyboardNavigation
                    wrapperClassName="w-full"
                    maxDate={new Date()}
                >
                </DatePicker>
            </Container>
        </>
    );
}

export default CustomDatePicker;
