import styled from "styled-components";

const Container = styled.div`
    padding: 0 15px;
    margin-top: 30px;
`;

const SearchFieldWrapper = styled.div`
    height: 320px;
`;

const ProductSearchButtonEl = styled.button`
    width: 200px;
    padding: 5px 0;
    height: 48px;
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
    color: white;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    -webkit-transition: all .1s;
    transition: all .1s;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;

    &:hover {
        background-color: var(--mainColorHover);
    }
`;

const SearchFieldBox = styled.div`
    max-height: 200px;
    min-height: 200px;
    border-radius: 5px;
    width: 200px;

    .image-box {
        border: 1px solid #e1e1e1;
        box-shadow: var(--defaultBoxShadow);
        border-radius: 5px;
    }
`;

const PageRouterSelectBox = styled.div`
    margin-top: 5px;
    height: 50px;
    font-size: 14px;
    color: var(--mainColor);
    font-weight: 700;
    display: inline-block;
`;

const SelectItem = styled.div`
    text-decoration: underline;
    text-underline-offset: 5px;
    cursor: pointer;
    transition: 0.1s;
    margin-top: 5px;

    &:hover {
        opacity: 0.6;
    }
`;

export {
    Container,
    SearchFieldWrapper,
    ProductSearchButtonEl,
    SearchFieldBox,
    PageRouterSelectBox,
    SelectItem
}