import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomBoxImage from "../../../../../components/image/box-image/CustomBoxImage";
import CustomConfirmModal from "../../../../../components/modal/confirm/v1/CustomConfirmModal";
import { dateToYYYYMMDD, getEndDate, getEndDateOfMonth, getStartDate, getStartDateOfMonth, getTimeDiffWithUTC, isSearchablePeriod, setPlusDate } from "../../../../../utils/dateFormatUtils";
import { CalendarButtonEl, Container } from "./style/DateOperator.styled";
import DateSelectorFieldView from "./view/DateSelectorField.view";
import FixDateRangeSelectorFieldView from "./view/FixDateRangeSelectorFieldView";
import SearchFieldView from "./view/SearchField.view";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { customToast, defaultOptions } from "../../../../../components/toast/custom-react-toastify/v1";

const DEFAULT_START_DATE = setPlusDate(new Date(), 0, 0, -13);
// const SEARCHABLE_PERIOD = 365;  // 날짜 검색 최대 기간
const SEARCHABLE_PERIOD = 100;  // 날짜 검색 최대 기간

export default function DateOperatorComponent (props) {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [dateOperatorModalOpen, setDateOperatorModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [periodType, setPeriodType] = useState('registration');
    
    const [monthDatePickerOpen, setMonthDatePickerOpen] = useState(false);

    const router = useRouterHookV2();

    useEffect(() => {
        if(!dateOperatorModalOpen) {
            return;
        }

        let date1 = DEFAULT_START_DATE;
        let date2 = new Date();

        if(router.query.startDate && router.query.endDate) {
            date1 = new Date(router.query.startDate);
            date2 = new Date(router.query.endDate);
        }

        if(router.query.periodType) {
            let searchPeriodType = router.query.periodType;
            setPeriodType(searchPeriodType);
        }

        setStartDate(date1);
        setEndDate(date2);
    }, [dateOperatorModalOpen])

    const handleActionOpenDatePickerModal = () => {
        setDateOperatorModalOpen(true);
    }

    const handleActionCloseDatePickerModal = () => {
        setMonthDatePickerOpen(false);
        setDateOperatorModalOpen(false);
    }

    const handleChangePeriodType = (e) => {
        let value = e.target.value;
        setPeriodType(value);
    }

    const handleChangeStartDate = (value) => {
        let start = value;
        setStartDate(start);
    }

    const handleChangeEndDate = (value) => {
        let end = value;
        setEndDate(end);
    }

    const handleChangeStartAndEndDate = (value) => {
        let start = getStartDateOfMonth(value);
        let end = getEndDateOfMonth(value);

        if(end >= new Date()) {
            end = new Date();
        }

        setStartDate(start);
        setEndDate(end);

        handleActionToggleMonthDatePicker();
    }

    const handleChangeSearchDateRange = (value) => {
        let start = value.startDateTime;
        let end = value.endDateTime;

        setStartDate(start);
        setEndDate(end);
    }

    const handleActionToggleMonthDatePicker = () => {
        let state = !monthDatePickerOpen;
        setMonthDatePickerOpen(state);
    }

    const handleSubmitSearchPerformance = () => {
        try {
            if (!periodType) {
                throw new Error('날짜 검색 기준이 선택되지 않았습니다.')
            }

            if (startDate && !endDate) {
                throw new Error('종료일 날짜를 선택해 주세요.')
            }

            if (!startDate && endDate) {
                throw new Error('시작일 날짜를 선택해 주세요.')
            }

            if (!(startDate && endDate)) {
                throw new Error('시작일과 종료일을 선택해 주세요.')
            }

            if ((endDate - startDate < 0)) {
                throw new Error('조회기간을 정확히 선택해 주세요.')
            }

            if (!isSearchablePeriod(startDate, endDate, SEARCHABLE_PERIOD)) {
                throw new Error(`조회기간은 최대 ${SEARCHABLE_PERIOD}일까지 가능합니다.`)
            }

            if (!props.selectedProductCategory) {
                throw new Error(`카테고리를 먼저 선택해 주세요.`);
            }
        } catch (err) {
            customToast.error(err.message, {
                ...defaultOptions,
                toastId: err.message
            });
            return;
        }

        // 파라미터 날짜값 세팅
        router.query.startDate = dateToYYYYMMDD(startDate);
        router.query.endDate = dateToYYYYMMDD(endDate);
        router.query.periodType = periodType;
        
        router.replace({
            state: {
                ...router.state
            }
        });

        let searchProductCategoryIds = [props.selectedProductCategory?.id] || [];
        
        let body = {
            startDate: getStartDate(startDate),
            endDate: getEndDate(endDate),
            utcHourDifference: getTimeDiffWithUTC(),
            productCategoryIds: searchProductCategoryIds,
            periodType
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        props.onSubmitSearchPerformance(body, headers);
        handleActionCloseDatePickerModal();
    }

    return (
        <>
            <Container>
                {!dateOperatorModalOpen &&
                    <CalendarButtonEl onClick={(e) => handleActionOpenDatePickerModal(e)}>
                        <CustomBoxImage
                            src='/images/icon/calendar_default_ffffff.svg'
                            size={40}
                        />
                    </CalendarButtonEl>
                }

                {dateOperatorModalOpen &&
                    <CustomConfirmModal
                        open={dateOperatorModalOpen}
                        title={'검색 설정'}
                        maxWidth={'sm'}
                        onConfirm={() => handleSubmitSearchPerformance()}
                        onClose={() => handleActionCloseDatePickerModal()}
                    >
                        <Container>
                            <SearchFieldView
                                periodType={periodType}
                                startDate={startDate}
                                monthDatePickerOpen={monthDatePickerOpen}
                                onChangePeriodType={handleChangePeriodType}
                                onActionToggleMonthDatePicker={handleActionToggleMonthDatePicker}
                                onChangeStartAndEndDate={handleChangeStartAndEndDate}
                            />

                            <DateSelectorFieldView
                                startDate={startDate}
                                endDate={endDate}
                                onChangeStartDateValue={handleChangeStartDate}
                                onChangeEndDateValue={handleChangeEndDate}
                            />

                            <FixDateRangeSelectorFieldView
                                onActionSearchDateRange={handleChangeSearchDateRange}
                            />
                        </Container>
                    </CustomConfirmModal>
                }
            </Container>
        </>
    )
}