import styled from "styled-components"
import { toPriceUnitFormat } from "../../../utils/numberFormatUtils";
import PropTypes from "prop-types";
import CustomDotIcon from "../../icon/dot-icon/v1/CustomDotIcon";

const Container = styled.div`
    min-width: 250px;
    margin-left: 10px;
    height: 400px;
    background-color: var(--defaultGrayColor);
    border: 1px solid var(--defaultGrayColor);
    border-radius: 10px;
`;

const Title = styled.div`
    font-size: 1.1rem;
    font-weight: 600;
    padding: 15px 10px;
    text-align: center;
    height: 50px;
`;

const ContentBox = styled.div`
    height: 350px;
    overflow: auto;

    @media screen and (max-width: 992px) {
        font-size: 14px;
    }

    ul {
        list-style: none;
        padding: 10px;
    }
`;

const ListEl = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;

    font-size: 14px;
    height: 38px;
    margin-bottom: 10px;
    border-bottom: 2px solid #fff;

    &:last-child {
        margin-bottom: 0;
    }
`;

export default function Summary({
    title,
    data,
    format='price'
}) {
    return (
        <Container>
            <Title>{title}</Title>
            <ContentBox>
                <ul>
                    {data?.map((r, idx) => {
                        let label = r.label?.length > 10 ? r.label.substring(0, 10) + '...' : r.label;

                        return (
                            <ListEl key={'graph-summary' + idx}>
                                <div style={{ wordBreak: 'break-all' }}>
                                    <CustomDotIcon color={r.color} />
                                    <span> {label} </span>
                                </div>
                                <div style={{ fontWeight: 700 }}>
                                    {format === 'price' ? toPriceUnitFormat(r.value) : `${(r.value || 0).toLocaleString()} 개`}
                                </div>
                            </ListEl>
                        )
                    })}
                    {!(data && data.length > 0) &&
                        <ListEl>
                            <span>데이터가 존재하지 않습니다.</span>
                        </ListEl>
                    }
                </ul>
            </ContentBox>
        </Container>
    )
}

Summary.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    format: PropTypes.oneOf(["price", "unit"])
}
