import { useEffect, useState } from "react";
import NavBarComponent from "../../navbar/NavBar.component";
import ConditionSelectorComponent from "./condition-selector/ConditionSelector.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import useCategoryAnalysisHook from "./hooks/useCategoryAnalysisHook";
import { Container } from "./index.styled";
import DetailGraphSelectorModalComponent from "./modal/detail-graph-selector/DetailGraphSelector.component";
import PayAmountDayOfWeekGraphComponent from "./pay-amount-day-of-week-graph/PayAmountDayOfWeekGraph.component";
import PayAmountGraphComponent from "./pay-amount-graph/PayAmountGraph.component";
import RegistrationAndUnitGraphComponent from "./registration-and-unit-graph/RegistrationAndUnitGraph.component";
import TextInfoComponent from "./text-info/TextInfo.component";
import useRouterHookV2 from "../../../../hooks/router/useRouterHookV2";
import _ from "lodash";
import { customToast, defaultOptions } from "../../../../components/toast/custom-react-toastify/v1";

export default function SalesAnalysis_CategoryIndexComponent () {
    const [searchDimension, setSearchDimension] = useState('date');
    const [checkedSwitch, setCheckedSwitch] = useState(false);

    const [categories, setCategories] = useState(null);

    const [detailGraphSelectorModalOpen, setDetailGraphSelectorModalOpen] = useState(false);

    const router = useRouterHookV2();
    
    const {
        isLoading: isCategoryAnalysisLoading,
        analysis,
        reqSearchCategoryAnalysis
    } = useCategoryAnalysisHook();

    useEffect(() => {
        if(!analysis) {
            return;
        }
        
        handleActionUpdateCategory();
    }, [analysis])

    const handleActionUpdateCategory = () => {
        let categoryIds = new Set([]);
        let category = [];

        analysis.forEach(r => r.performances?.forEach(r2 => {
            if(!categoryIds.has(r2.productCategoryId)) {
                categoryIds.add(r2.productCategoryId);
                category.push({
                    id: r2.productCategoryId,
                    name: r2.productCategoryName
                })
            }
        }))

        // 미지정 데이터를 맨앞으로 정렬
        let sortedData = _.sortBy(category, 'name');
        setCategories(sortedData);

        // 조회된 카테고리에 포함되지 않는 카테고리가 선택된 경우
        let searchedCategoryIds = router.state?.productCategoryIds ?? [];
        let searchableCategoryIds = searchedCategoryIds.filter(id => categoryIds.has(id));
        
        if(searchedCategoryIds?.length > 0 && !searchableCategoryIds?.length > 0) {
            let memo = '검색 카테고리의 판매 데이터가 존재하지 않습니다.';
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })

            let data = {
                state: {
                    ...router.state,
                    productCategoryIds: searchableCategoryIds
                }
            }
            router.replace(data);
        }
    }

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }

    const handleChangeDimension = (value) => {
        setSearchDimension(value);
    }

    const handleActionOpenDetailGraphSelectorModal = () => {
        if(!(router.state?.productCategoryIds?.length > 0)) {
            let memo = '카테고리를 먼저 선택해주세요.';
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        setDetailGraphSelectorModalOpen(true);
    }

    const handleActionCloseDetailGraphSelectorModal = () => {
        setDetailGraphSelectorModalOpen(false);
    }

    const handleSubmitSearchPerformance = async (body, headers) => {
        await reqSearchCategoryAnalysis(body, headers);
    }

    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'카테고리 성과'}
                    headerName={'총 매출액 & 판매 건'}
                    sidebarColor={'#ffffff'}
                >
                    <>
                        <TextInfoComponent />
                        
                        <ConditionSelectorComponent
                            isCategoryAnalysisLoading={isCategoryAnalysisLoading}
                            categories={categories}
                        />

                        <GraphOperatorComponent
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}

                            onChangeSwitch={handleChangeSwitch}
                            onChangeSearchDimension={handleChangeDimension}
                        />

                        <PayAmountGraphComponent
                            isCategoryAnalysisLoading={isCategoryAnalysisLoading}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}
                            categories={categories}

                            onActionOpenDetailGraphSelectorModal={handleActionOpenDetailGraphSelectorModal}
                        />

                        <RegistrationAndUnitGraphComponent
                            isCategoryAnalysisLoading={isCategoryAnalysisLoading}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}
                            categories={categories}

                            onActionOpenDetailGraphSelectorModal={handleActionOpenDetailGraphSelectorModal}
                        />

                        <PayAmountDayOfWeekGraphComponent
                            isCategoryAnalysisLoading={isCategoryAnalysisLoading}
                            analysis={analysis}
                            categories={categories}
                        />
                    </>
                </NavBarComponent>

                <DateOperatorComponent
                    onSubmitSearchPerformance={handleSubmitSearchPerformance}
                />
            </Container>

            <DetailGraphSelectorModalComponent
                modalOpen={detailGraphSelectorModalOpen}

                onActionCloseModal={handleActionCloseDetailGraphSelectorModal}
            />
        </>
    )
}