
function getRandomColors(size) {
    let colors = [];
    for (let i = 0; i < size; i++) {
        let randomColor = `#${Math.round(Math.random() * 0xFFFFFF).toString(16)}`;
        colors.push(randomColor);
    }

    return colors;
}

export {
    getRandomColors
}