import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css';
import './styles/mgl.flex.css';
import './styles/mgl.font.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// redux
import { legacy_createStore as createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './redux/reducers/rootReducer';
import UserPermissionComponent from './redux/dispatch_component/UserPermissionComponent';
import WorkspacePermissionComponent from './redux/dispatch_component/WorkspacePermissionComponent';

// helmet
import { HelmetProvider } from 'react-helmet-async';

// custom component
import { CustomToastContainer } from './components/toast/custom-react-toastify/v1';
import { CustomDefaultBackdrop } from './components/backdrop/default/v1/components';

const store = createStore(rootReducer);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
        <UserPermissionComponent />
        <WorkspacePermissionComponent />
        <HelmetProvider>
          <App />
        </HelmetProvider>
        <CustomToastContainer />
        <CustomDefaultBackdrop />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
