import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { salesAnalysisDataConnect } from "../../../../../../data_connect/salesAnalysisDataConnect";
import { getEndDate, getStartDate, getTimeDiffWithUTC, setPlusDate } from "../../../../../../utils/dateFormatUtils";
import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import defaultErrorHandler from "../../../../../../handler/dataConnectErrorHandler";

const TODAY = new Date();
const YESTERDAY = setPlusDate(TODAY, 0, 0, -1);

export default function useChannelAnalysisHook() {
    const workspaceRedux = useSelector(state => state.workspaceRedux);
    const [channelAnalysis, setChannelAnalysis] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const router = useRouterHookV2();

    useEffect(() => {
        async function fetchInit() {
            let startDate = getStartDate(YESTERDAY);
            let endDate = getEndDate(TODAY);
            let utcHourDifference = getTimeDiffWithUTC();
            let searchPeriodType = router.query.periodType ?? 'registration';

            let body = {
                startDate,
                endDate,
                utcHourDifference,
                periodType: searchPeriodType
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchChannelAnalysis(body, headers);
        }

        fetchInit();
    }, [router.location])

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchChannelAnalysis = async (body, headers) => {
        toggleIsLoading(true);
        await salesAnalysisDataConnect().searchChannelAnalysis(body, headers)
            .then(res => {
                if (res.status === 200) {
                    setChannelAnalysis(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler())

        toggleIsLoading(false);
    }

    return {
        isLoading,
        channelAnalysis,
        reqSearchChannelAnalysis
    }
}