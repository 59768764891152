import { useState } from "react";
import NavBarComponent from "../../navbar/NavBar.component";
import { Container } from "./index.styled";
import OperatorComponent from "./operator/Operator.component";
import TextInfoComponent from "./text-info/TextInfo.component";
import useProductAnalysisHook from "./hooks/useProductAnalysisHook";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import PayAmountGraphComponent from "./pay-amount-graph/PayAmountGraph.component";
import RegistrationAndUnitGraphComponent from "./registration-and-unit-graph/RegistrationAndUnitGraph.component";
import PayAmountDayOfWeekGraphComponent from "./pay-amount-day-of-week-graph/PayAmountDayOfWeekGraph.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";

export default function SalesAnalysis_ProductIndexComponent() {
    const [searchDimension, setSearchDimension] = useState('date');
    const [checkedSwitch, setCheckedSwitch] = useState(false);

    const [searchedProducts, setSearchedProducts] = useState([]);

    const {
        isLoading: isProductAnalysisLoading,
        analysis,
        reqSearchProductAnalysis
    } = useProductAnalysisHook();

    const handleActionResetPerformance = () => {
        setSearchedProducts([]);
    }

    const handleChangeSelectedProduct = (data) => {
        setSearchedProducts([...data]);
    }

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }

    const handleChangeDimension = (value) => {
        setSearchDimension(value);
    }

    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'상품 성과'}
                    headerName={'총 매출액 & 판매 건'}
                    sidebarColor={'#ffffff'}
                >
                    <>
                        <TextInfoComponent />
                        
                        <OperatorComponent
                            onActionResetPerformance={handleActionResetPerformance}
                            onActionChangeSelectedProduct={handleChangeSelectedProduct}
                            onSubmitSearchPerformance={reqSearchProductAnalysis}
                        />

                        <GraphOperatorComponent
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}

                            onChangeSwitch={handleChangeSwitch}
                            onChangeSearchDimension={handleChangeDimension}
                        />

                        <PayAmountGraphComponent
                            isProductAnalysisLoading={isProductAnalysisLoading}
                            searchedProducts={searchedProducts}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}
                        />

                        <RegistrationAndUnitGraphComponent
                            isProductAnalysisLoading={isProductAnalysisLoading}
                            searchedProducts={searchedProducts}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}
                        />

                        <PayAmountDayOfWeekGraphComponent
                            isProductAnalysisLoading={isProductAnalysisLoading}
                            searchedProducts={searchedProducts}
                            analysis={analysis}
                        />
                    </>
                </NavBarComponent>

                <DateOperatorComponent
                    searchedProducts={searchedProducts}
                    onSubmitSearchPerformance={reqSearchProductAnalysis}
                />
            </Container>
        </>
    )
}