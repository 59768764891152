import styled, { css } from 'styled-components';

export const Container = styled.div`
    padding: 30px 20px;
`;

export const LinkItem = styled.div`
    padding: 10px 5px;
    font-size: 18px;
    -webkit-tap-highlight-color: #00000000;
    margin-bottom: 10px;
`;

export const ButtonEl = styled.button`
    font-size: 18px;
    border: none;
    background-color: inherit;
    color: ${props => props.actived ? 'var(--mainColor) !important' : '#aaa'};
    font-weight: ${props => props.actived ? '600 !important' : ''};
    cursor: pointer;
    transition: 0.15s;
    padding-bottom: 10px;
    display: inline-block;
    position:relative;
    font-weight: 500;

    :hover {
        color: var(--mainColor);
    }
`;

export const ItemTitle = styled.div`
    color: #6d6d6d;
    font-weight: 600;
    padding: 15px 0;
    margin-bottom: 5px;
`
