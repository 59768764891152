import styled from "styled-components";

const ImageEl = styled.img`
    width:100%;
    height: 100%;
`;

export default function CustomImage({
    src,
    className,
    size,
    ...props
}) {
    return (
        <ImageEl
            src={src || '/images/normal/image.png'}
            // layout='responsive'
            width={size}
            objectFit={'cover'}
            alt='image'
            loading='lazy'
            className={className}
            {...props}
        ></ImageEl>
    );
}