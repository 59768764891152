import Ripple from "../../../../../../components/ripple/v1/Ripple";
import { PageRouterSelectBox, SearchButtonEl, SearchFieldWrapper, SelectItem } from "../style/Operator.styled";

export default function SearchFieldView({ selectedProductCategory, onActionOpenProductCategorySelectorModal, onActionRouteToPage }) {
    return (
        <SearchFieldWrapper>
            <div>
                <SearchButtonEl
                    type='button'
                    onClick={() => onActionOpenProductCategorySelectorModal()}
                >
                    {selectedProductCategory?.name || '상위 카테고리 선택'}
                    <Ripple color={'#e0e0e0'} duration={1000} />
                </SearchButtonEl>
            </div>

            <PageRouterSelectBox>
                {selectedProductCategory &&
                    <SelectItem onClick={() => onActionRouteToPage('/sales-analysis/category')}>
                        <span>{selectedProductCategory?.name}</span>
                        <span>[총 매출액 & 판매 건] 확인</span>
                    </SelectItem>
                }
            </PageRouterSelectBox>
        </SearchFieldWrapper>
    )
}