import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productSubCategoryDataConnect } from "../../../../../data_connect/productSubCategoryDataConnect";

import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import defaultErrorHandler from "../../../../../handler/dataConnectErrorHandler";

export default function useProductSubCategoriesHook () {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [productSubCategories, setProductSubCategories] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        async function fetchInit() {
            let params = {
                productCategoryId: router.state.productCategoryIds[0]
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchSubCategoryAnalysis(params, headers);
        }

        let selectedProductCategory = router.state?.productCategoryIds ?? null;
        if (!selectedProductCategory) {
            return;
        }
        
        fetchInit();
    }, [])

    const reqSearchSubCategoryAnalysis = async (params, headers) => {
        await productSubCategoryDataConnect().searchList(params, headers)
            .then(res => {
                if (res.status === 200) {
                    setProductSubCategories(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
    }

    return {
        productSubCategories
    }
}