import styled from "styled-components";

const Container = styled.div`
`;

const ConditionFieldWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    padding: 20px;

    @media screen and (max-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ButtonBox = styled.div`
    padding: 0 10px;

    @media screen and (max-width: 992px) {
        padding: 0;
    }
`;

const ButtonEl = styled.button`
    margin: 0px;
    padding: 0px;
    width: 150px;
    height: 40px;
    border: none;
    position: relative;
    overflow: hidden;
    font-size: 16px;
    border-radius: 5px;
    background: var(--mainColor);
    color: rgb(255, 255, 255);
    box-shadow: var(--defaultBoxShadow);
    cursor: pointer;
`;

export {
    Container,
    ConditionFieldWrapper,
    ButtonBox,
    ButtonEl
}