export const nullLastAndSort = (arr) => {
    if(!arr) return;

    let sortedArr = arr.sort((a, b) => {
        if(a === null) return 1;
        if(b === null) return -1;
        return a < b ? -1 : 1;
    })
    return sortedArr;
}

// sort by field and null come last
export const nullLastAndSortByField = (arr, field) => {
    if(field === null) return;

    let sortedArr = arr.sort((a, b) => {
        if(a[field] === null) return 1;
        if(b[field] === null) return -1;
        return a[field] < b[field] ? -1 : 1;
    })

    return sortedArr;
}