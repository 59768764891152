import styled from "styled-components"
import Ripple from "../../ripple/v1/Ripple";
import PropTypes from "prop-types";
import CustomBoxImage from "../../image/box-image/CustomBoxImage";

const Container = styled.div`
    display: flex;

    @media screen and (max-width: 992px) {
        width: 100%;
        justify-content: flex-end;
    }
`;

const ButtonEl = styled.button`
    overflow: hidden;
    position: relative;
    width: 45px;
    height: 45px;
    padding: 2px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid var(--defaultBorderColor);
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: all .1s;
    transition: all .1s;

    :hover {
        background-color: #fafafa;
    }

    @media all and (max-width:992px) {
        margin: 0;
        font-size: 14px;
    }
`;

export default function SearchModalButton({
    onActionModalOpen
}) {
    return (
        <Container>
            <ButtonEl onClick={() => onActionModalOpen()}>
                <CustomBoxImage
                    src='/images/icon/graph_search_default_000000.svg'
                    size={32}
                />
                <Ripple color={'#e0e0e0'} duration={500} />
            </ButtonEl>
        </Container>
    )
}

SearchModalButton.protoTypes = {
    onActionModalOpen: PropTypes.func
}