import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productDataConnect } from "../../../../../../data_connect/productDataConnect";
import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import defaultErrorHandler from "../../../../../../handler/dataConnectErrorHandler";

export default function useProductHook () {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        async function fetchInit() {
            let searchProductId = router.state?.productIds[0];

            let params = {
                productId: searchProductId
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }
            
            await reqSearchProductOne(params, headers);
        }
        
        
        if(!router.state) {
            setProduct(null);
            return;
        }

        if(router.state?.productIds?.length > 0) {
            fetchInit();
        }
    }, [router.state])

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchProductOne = async (params, headers) => {
        toggleIsLoading(true);
        await productDataConnect().searchOne(params, headers)
            .then(res => {
                if (res.status === 200) {
                    setProduct(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
        toggleIsLoading(false);
    }

    return {
        isLoading,
        product,
        reqSearchProductOne
    }
}