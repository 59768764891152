import Ripple from "../../../../../../components/ripple/v1/Ripple";
import { ButtonBox, ButtonEl, ConditionFieldWrapper } from "../style/ConditionField.styled";

export default function ConditionFieldView(props) {
    return (
        <ConditionFieldWrapper>
            <div>검색 기준 : </div>
            <ButtonBox>
                <ButtonEl
                    className='button-el'
                    onClick={() => props.onChangePeriodType()}
                >
                    {props.periodType === 'registration' ? '주문 등록일' : '주문 일시'}
                    <Ripple color={'#e0e0e0'} duration={500} />
                </ButtonEl>
            </ButtonBox>
        </ConditionFieldWrapper>
    )
}