import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    height: 60vh;
`;

const TextBox = styled.div`
    color: rgb(48, 48, 48);
    font-weight: 600;
    font-size: 1.1rem;
    word-break: keep-all;
`;

const ButtonBox = styled.div`
    width: 300px;
`;

const ButtonEl = styled.button`
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 0px;
    margin: 0px;
    height: 56px;
    background: var(--mainColor);
    color: white;
    border: 1px solid var(--mainColor);
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: all .1s;
    transition: all .1s;
`;

export {
    Container,
    TextBox,
    ButtonBox,
    ButtonEl
}