import CustomBoxImage from "../../../../../../components/image/box-image/CustomBoxImage";
import { ImageButtonEl, ControlButtonBox, ItemBoxWrapper, SelectorFieldWrapper, ButtonEl, ButtonBox } from "../style/ConditionSelector.styled";

const MAXIMUM_SEARCH_SIZE = 10;

export default function SelectorFieldView ({
    salesChannel,
    selectedChannel,
    onActionCheckAll,
    onActionCheckCancelAll,
    returnIsCheckedOne,
    onActionCheckOne
}) {
    return (
        <SelectorFieldWrapper>
            <ControlButtonBox>
                <div>
                    <ImageButtonEl
                        color='#5fcf80'
                        onClick={(e) => onActionCheckAll(e)}
                    >
                        <CustomBoxImage
                            src='/images/icon/check_all_default_5fcf80.svg'
                            size={25}
                        />
                    </ImageButtonEl>
                </div>
                <div>
                    <ImageButtonEl
                        color='#e56767'
                        onClick={(e) => onActionCheckCancelAll(e)}
                    >
                        <CustomBoxImage
                            src='/images/icon/check_remove_all_default_e56767.svg'
                            size={25}
                        />
                    </ImageButtonEl>
                </div>
            </ControlButtonBox>
            <ItemBoxWrapper>
                {salesChannel?.map((r, idx) => {
                    let channelName = r ?? '미지정';

                    return (
                        <ButtonBox
                            key={'channel-idx' + idx}
                            onClick={(e) => onActionCheckOne(e, r)}
                        >
                            <ButtonEl
                                type='button'
                                checked={returnIsCheckedOne(r)}
                                onChange={(e) => onActionCheckOne(e, r)}
                                isUndefinedItem={channelName === '미지정'}
                            >{channelName}</ButtonEl>
                        </ButtonBox>
                    )
                })}
                {!(salesChannel?.length > 0) &&
                    <div style={{ color: '#666' }}>
                        조회 결과 없음
                    </div>
                }
            </ItemBoxWrapper>
            <div style={{ padding: '3px', color: '#888', float: 'right' }}>
                {(selectedChannel?.length ?? 0) + ' / ' + MAXIMUM_SEARCH_SIZE}
            </div>
        </SelectorFieldWrapper>
    )
}