import { ButtonEl, ButtonFieldWrapper, ButtonGroup, Title } from "../DetailGraphSelector.styled";

export default function ButtonFieldView({ onActionRouteToPage }) {
    return (
        <ButtonFieldWrapper>
            <div>
                <Title>총 매출액 & 판매 건 확인</Title>
                <ButtonGroup>
                    <ButtonEl onClick={() => onActionRouteToPage('/sales-analysis/sub-category')}>서브 카테고리 별</ButtonEl>
                </ButtonGroup>
            </div>
            <div>
                <Title>상품 순위 확인</Title>
                <ButtonGroup>
                    <ButtonEl onClick={() => onActionRouteToPage('/sales-analysis/category/best-product')}>카테고리 별</ButtonEl>
                </ButtonGroup>
            </div>
        </ButtonFieldWrapper>
    )
}