import { toPriceUnitFormat } from "../../../../../../utils/numberFormatUtils";
import { BoxWrapper, ChannelDataBox, ContentBox, DailyChannelAnalysisFieldWrapper, DataContentWrapper, DataLabelBox } from "../style/DailyAnalysis.styled";

export default function DailyChannelAnalysisFieldView ({ channelAnalysisData }) {
    return (
        <DailyChannelAnalysisFieldWrapper>
            <DataBoxField
                datetime='yesterday'
                performance={channelAnalysisData?.yesterdayData?.channelPayAmount}
                totalPayAmount={channelAnalysisData?.yesterdayData?.totalPayAmount}
            />
            <DataBoxField
                datetime='today'
                performance={channelAnalysisData?.todayData?.channelPayAmount}
                totalPayAmount={channelAnalysisData?.todayData?.totalPayAmount}
            />
        </DailyChannelAnalysisFieldWrapper>
    )
}

function DataBoxField({ datetime, performance, totalPayAmount }) {
    return (
        <BoxWrapper>
            <ChannelDataBox datetime={datetime}>
                <DataLabelBox>스토어 판매 매출</DataLabelBox>
                <DataContentWrapper>
                    {performance?.map((r, idx) => {
                        return (
                            <ContentBox
                                key={'channel-idx' + idx}
                            >
                                <div className='content-title'>
                                    {idx + 1}. {r.salesChannel ?? '미지정'}
                                </div>
                                <div className='content-value'>
                                    <span className='channel-rate'>{(totalPayAmount !== 0 ? (r.salesPayAmount / totalPayAmount) * 100 : 0).toFixed(1)}% </span>
                                    <span style={{ fontSize: '12px', fontWeight: '400' }}>({toPriceUnitFormat(r.salesPayAmount)})</span>
                                </div>
                            </ContentBox>
                        )
                    })}
                    {performance?.length === 0 &&
                        <div>
                            <div style={{ width: '110px', fontSize: '16px', color: '#888' }}>
                                판매데이터 없음
                            </div>
                        </div>
                    }
                </DataContentWrapper>
            </ChannelDataBox>
        </BoxWrapper>
    )
}