import Ripple from "../../../../../../components/ripple/v1/Ripple";
import { ButtonEl, ButtonFieldWrapper } from "../style/Operator.styled";

export default function ButtonFieldView({
    onActionResetSelectedProduct,
    onActionChangeSelectedProduct
}) {
    return (
        <ButtonFieldWrapper>
            <ButtonEl
                type='button'
                onClick={() => onActionResetSelectedProduct()}
                style={{ backgroundColor: 'var(--defaultModalCloseColor)' }}
            >
                초기화
                <Ripple color={'#e0e0e0'} duration={1000}></Ripple>
            </ButtonEl>
            <ButtonEl
                type='button'
                onClick={() => onActionChangeSelectedProduct()}
            >
                조회
                <Ripple color={'#e0e0e0'} duration={1000}></Ripple>
            </ButtonEl>
        </ButtonFieldWrapper>
    );
}