import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productSubCategoryDataConnect } from "../../../../../data_connect/productSubCategoryDataConnect";
import defaultErrorHandler from "../../../../../handler/dataConnectErrorHandler";

export default function useProductSubCategoriesHook ({ selectedProductCategory }) {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [isLoading, setIsLoading] = useState(false);
    const [productSubCategories, setProductSubCategories] = useState(null);

    useEffect(() => {
        async function fetchInit() {
            let params = {
                productCategoryId: [selectedProductCategory.id]
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchSubCategoryAnalysis(params, headers);
        }

        if(!selectedProductCategory) {
            return;
        }
        
        fetchInit();
    }, [selectedProductCategory])

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchSubCategoryAnalysis = async (params, headers) => {
        toggleIsLoading(true);
        await productSubCategoryDataConnect().searchList(params, headers)
            .then(res => {
                if (res.status === 200) {
                    setProductSubCategories(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
        toggleIsLoading(false);
    }

    return {
        isLoading,
        productSubCategories
    }
}