import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
    font-size: 1.1rem;
    font-weight: 600;
    padding: 15px 10px;
    text-align: center;
    height: 50px;
`;

export default function SummaryTitle({
    children
}) {
    return (
        <Container>
            {children}
        </Container>
    )
}

SummaryTitle.propTypes = {
    children: PropTypes.string
}
