import CustomBoxImage from "../../../../../../components/image/box-image/CustomBoxImage";
import Ripple from "../../../../../../components/ripple/v1/Ripple";
import { ItemBox, ItemButtonEl, ProductSearchButtonEl, SearchFieldBox, SearchFieldWrapper } from "../style/Operator.styled";

const MAXIMUM_SEARCH_SIZE = 10;

export default function SearchFieldView({
    selectedProducts,
    onActionOpenProductListModal,
    onActionRemoveProduct
}) {
    return (
        <SearchFieldWrapper>
            <div>
                <div>
                    <ProductSearchButtonEl
                        type='button'
                        onClick={(e) => onActionOpenProductListModal(e)}
                    >
                        조회 상품 추가
                        <Ripple color={'#e0e0e0'} duration={1000} />
                    </ProductSearchButtonEl>
                </div>
                <div style={{ padding: '3px', color: '#888', float: 'right' }}>
                    {(selectedProducts?.length ?? 0) + ' / ' + MAXIMUM_SEARCH_SIZE}
                </div>
            </div>

            <SearchFieldBox>
                {selectedProducts?.map((r, idx) => {
                    return (
                        <ItemBox
                            key={'search-box-idx' + idx}
                            onClick={(e) => onActionRemoveProduct(e, r.id)}
                        >
                            <div className="close-box">
                                <CustomBoxImage
                                    src='/images/icon/close_default_e56767.svg'
                                    className='close-button-box'
                                    size={15}
                                />
                            </div>
                            <ItemButtonEl type='button'>
                                <div>{r.name}</div>
                            </ItemButtonEl>
                        </ItemBox>
                    )
                })}
            </SearchFieldBox>
        </SearchFieldWrapper>
    )
}