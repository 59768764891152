import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { GraphDataset, createGraphData, createGraphOption } from "../../../../../utils/chartUtils";
import { getRandomColors } from "../../../../../utils/colorUtils";
import { getDayName, getWeekName } from "../../../../../utils/dateFormatUtils";
import { toPriceUnitFormat } from "../../../../../utils/numberFormatUtils";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";
import FieldCircleLoading from "../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const SALES_GRAPH_BG_COLOR = ['#FFDE88', '#CCCA80', '#96B97D', '#5C9C80', '#FFC988', '#EEE8A9', '#70dbc2', '#D5CABD', '#389091'];

export default function PayAmountDayOfWeekGraphComponent (props) {
    const [avgGraphData, setAvgGraphData] = useState(null);
    const [graphOption, setGraphOption] = useState(null);

    useEffect(() => {
        if(!(props.searchedProducts)) {
            handleActionResetGraphData();
            return;
        }

        if(!props.analysis) {
            return;
        }

        handleActionCreateAvgGraphData();
    }, [props.searchedProducts, props.analysis])

    useEffect(() => {
        if(!avgGraphData) {
            return;
        }

        handleActionCreateGraphOption();
    }, [avgGraphData])

    const handleActionResetGraphData = () => {
        setAvgGraphData(null);
    }

    const handleActionCreateAvgGraphData = () => {
        let graphColor = SALES_GRAPH_BG_COLOR;
        
        let salesPayAmountData = [];
        let salesDatasets = [];
        let graphLabels = getWeekName();
        let products = [...props.searchedProducts];

        let productPayAmount = products.map(r => {
            let data = graphLabels.map(r2 => {
                return {
                    dayName: r2,
                    salesPayAmount: 0,
                    frequency: 0
                }
            })

            return {
                productId: r.id,
                productName: r.name,
                payAmountData: data
            }
        });

        productPayAmount = productPayAmount.map(r => {
            let productPayAmountData = [...r.payAmountData];

            props.analysis.forEach(data => {
                let day = getDayName(data.datetime);

                data.performances.forEach(r2 => {
                    if (r2.productId === r.productId) {
                        productPayAmountData = productPayAmountData.map(r3 => {
                            if (r3.dayName === day) {
                                return {
                                    ...r3,
                                    salesPayAmount: r3.salesPayAmount + r2.salesPayAmount,
                                    frequency: r3.frequency + 1
                                }
                            } else {
                                return r3;
                            }
                        });
                    };
                });
            })

            return {
                ...r,
                payAmountData: productPayAmountData
            }
        });

        productPayAmount.forEach(r => {
            let productSalesPayAmountData = [];
            r.payAmountData.forEach(r2 => {
                let salesAvg = parseInt((r2.salesPayAmount) / r2.frequency);
                productSalesPayAmountData.push(salesAvg);
            })
            salesPayAmountData.push(productSalesPayAmountData);
        })

        let randomColor = getRandomColors(products.length - SALES_GRAPH_BG_COLOR.length)
        graphColor = [...graphColor, ...randomColor];

        if (products.size === 0) {
            let barGraphOfSales = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '판매 매출액',
                data: [],
                borderColor: graphColor[0],
                backgroundColor: graphColor[0],
                borderWidth: 0,
                order: 0
            }
            salesDatasets.push(barGraphOfSales);
        } else {
            productPayAmount.forEach((r, idx) => {
                let barGraphOfSales = {
                    ...new GraphDataset().toJSON(),
                    type: 'bar',
                    label: r.productName,
                    data: salesPayAmountData[idx],
                    borderColor: graphColor[idx],
                    backgroundColor: graphColor[idx],
                    borderWidth: 0,
                    order: 0
                }
                salesDatasets.push(barGraphOfSales);
            })
        }

        // 매출 그래프 데이터 생성
        setAvgGraphData({
            labels: graphLabels,
            datasets: salesDatasets
        });
    }

    const handleActionCreateGraphOption = () => {
        let option = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                y: {
                    ticks: {
                        callback: function (value, index, ticks) {
                            return toPriceUnitFormat(value);
                        }
                    }
                },
                x: {
                    grid: {
                        display: false
                    }
                }
            }
        }

        setGraphOption(option);
    }

    return (
        <CustomChartGroupV2>
            <CustomChartGroupV2.BoardGroup>
                <CustomChartGroupV2.BoardGroup.Title>요일별 매출액</CustomChartGroupV2.BoardGroup.Title>
                <CustomChartGroupV2.BoardGroup.Info>조회된 기간의 요일별 평균 판매액이 표시됩니다.</CustomChartGroupV2.BoardGroup.Info>
            </CustomChartGroupV2.BoardGroup>

            <div style={{ position: 'relative' }}>
                {props.isProductAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }
                <CustomChartGroupV2.BodyGroup>
                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {avgGraphData && graphOption &&
                            <Chart
                                data={createGraphData(avgGraphData)}
                                options={createGraphOption(graphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>
                </CustomChartGroupV2.BodyGroup>
            </div>
        </CustomChartGroupV2>
    )
}