import styled from "styled-components";

const Container = styled.div`
    background-color: #fff;
    border-radius: 10px;
    box-shadow: var(--defaultBoxShadow);
    margin-top: 30px;
`;

const TableBoardFieldWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
`;

const BoardTitle = styled.div`
    font-size : 1.2rem;
    font-weight: 700;
`;

const TableFieldWrapper = styled.div`
    overflow: auto;
    min-height: 400px;
    text-align: center;
    font-size: 14px;

    @media screen and (max-width: 992px) {
        font-size: 12px;
    }
`;

const TableEl = styled.table`
    position:relative;
    text-align: center;
    table-layout: fixed;
    border: none;
    word-break: break-all;
    border-collapse: collapse;
    caption-side: bottom;
    min-height: 500px;

    thead {
        width: 100%;
    }
`;

const FixedTh = styled.th`
    height: 40px;
    position: sticky;
    top: 0;
    z-index:10;
    color: #404040;
    background-color: white;
    box-shadow: 0 -1.5px 0 0 var(--mainColor) inset;
`;

const TableTr = styled.tr`
    border: 1px solid #eee;
    height: 40px;
    color: #555;
    transition: 1ms;

    :hover{
        background: #2c73d208;
    }

    &.first-tr {
        background-color: #2c73d212 !important;
        font-weight: 500;
        color: #000;
    }
`;

export {
    Container,
    TableBoardFieldWrapper,
    BoardTitle,
    TableFieldWrapper,
    TableEl,
    TableTr,
    FixedTh
}