import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { GraphDataset, createGraphData, createGraphOption } from "../../../../../utils/chartUtils";
import { getRandomColors } from "../../../../../utils/colorUtils";
import { getDayName, getWeekName } from "../../../../../utils/dateFormatUtils";
import { toPriceUnitFormat } from "../../../../../utils/numberFormatUtils";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";
import FieldCircleLoading from "../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const SALES_GRAPH_BG_COLOR = ['#B9B4EB', '#F0B0E8', '#FF9999', '#B0E89B', '#FFFF85', '#ADDCFF', '#8EF5DD', '#88CAD3', '#FFCF99', '#BDD2FF'];

export default function PayAmountDayOfWeekGraphComponent (props) {
    const [avgGraphData, setAvgGraphData] = useState(null);
    const [graphOption, setGraphOption] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        if(!(router.state?.salesChannels)) {
            handleActionResetGraphData();
            return;
        }

        if(!props.analysis) {
            return;
        }

        handleActionCreateAvgGraphData();
    }, [router.state, props.analysis])

    useEffect(() => {
        if(!avgGraphData) {
            return;
        }

        handleActionCreateGraphOption();
    }, [avgGraphData])

    const handleActionResetGraphData = () => {
        setAvgGraphData(null);
    }

    const handleActionCreateAvgGraphData = () => {
        let graphColor = SALES_GRAPH_BG_COLOR;
        
        let salesPayAmountData = [];
        let salesDatasets = [];
        let graphLabels = getWeekName();
        let channel = [...router.state.salesChannels];

        let channelPayAmount = channel.map(r => {
            let data = graphLabels.map(r2 => {
                return {
                    dayName: r2,
                    salesPayAmount: 0,
                    frequency: 0
                }
            })

            return {
                channel: r,
                payAmountData: data
            }
        });

        /**
         * channelPayAmount = [
         *  {
         *      channel: A,
         *      payAmountData: [
         *          {dayName: '일', salesPayAmount: -, frequency: -},
         *          {dayName: '월', salesPayAmount: -. freqiemcy: -},
         *          ...
         *      ]
         *  }, {...}, {...}]
         */
        channelPayAmount = channelPayAmount.map(r => {
            let channelPayAmountData = [...r.payAmountData];

            props.analysis.forEach(data => {
                let day = getDayName(data.datetime);

                data.performances.forEach(r2 => {
                    if (r2.salesChannel === r.channel) {
                        channelPayAmountData = channelPayAmountData.map(r3 => {
                            if (r3.dayName === day) {
                                return {
                                    ...r3,
                                    salesPayAmount: r3.salesPayAmount + r2.salesPayAmount,
                                    frequency: r3.frequency + 1
                                }
                            } else {
                                return r3;
                            }
                        });
                    };
                });
            })

            return {
                ...r,
                payAmountData: channelPayAmountData
            }
        });

        channelPayAmount.forEach(r => {
            let channelSalesPayAmountData = [];
            r.payAmountData.forEach(r2 => {
                let salesAvg = parseInt((r2.salesPayAmount) / r2.frequency);
                channelSalesPayAmountData.push(salesAvg);
            })
            salesPayAmountData.push(channelSalesPayAmountData);
        })

        let randomColor = getRandomColors(channel.length - SALES_GRAPH_BG_COLOR.length)
        graphColor = [...graphColor, ...randomColor];

        if (channel.size === 0) {
            let barGraphOfSales = {
                ...new GraphDataset().toJSON(),
                type: 'bar',
                label: '판매 매출액',
                data: [],
                borderColor: graphColor[0],
                backgroundColor: graphColor[0],
                borderWidth: 0,
                order: 0
            }
            salesDatasets.push(barGraphOfSales);
        } else {
            channelPayAmount.forEach((r, idx) => {
                let channelName = r.channel ?? '미지정';

                let barGraphOfSales = {
                    ...new GraphDataset().toJSON(),
                    type: 'bar',
                    label: channelName,
                    data: salesPayAmountData[idx],
                    borderColor: graphColor[idx],
                    backgroundColor: graphColor[idx],
                    borderWidth: 0,
                    order: 0
                }
                salesDatasets.push(barGraphOfSales);
            })
        }

        // 매출 그래프 데이터
        setAvgGraphData({
            labels: graphLabels,
            datasets: salesDatasets
        });
    }

    const handleActionCreateGraphOption = () => {
        let option = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            scales: {
                y: {
                    ticks: {
                        callback: function (value, index, ticks) {
                            return toPriceUnitFormat(value);
                        }
                    }
                },
                x: {
                    grid: {
                        display: false
                    }
                }
            }
        }

        setGraphOption(option);
    }

    return (
        <CustomChartGroupV2>
            <CustomChartGroupV2.BoardGroup>
                <CustomChartGroupV2.BoardGroup.Title>요일별 매출액</CustomChartGroupV2.BoardGroup.Title>
                <CustomChartGroupV2.BoardGroup.Info>조회된 기간의 요일별 평균 판매액이 표시됩니다.</CustomChartGroupV2.BoardGroup.Info>
            </CustomChartGroupV2.BoardGroup>

            <div style={{ position: 'relative' }}>
                {props.isSalesChannelAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }
                <CustomChartGroupV2.BodyGroup className='flex-item'>
                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {avgGraphData && graphOption &&
                            <Chart
                                data={createGraphData(avgGraphData)}
                                options={createGraphOption(graphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>
                </CustomChartGroupV2.BodyGroup>
            </div>
        </CustomChartGroupV2>
    )
}