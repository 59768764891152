import { useEffect } from "react";
import { useSelector } from "react-redux";
import PrimaryNavbarMainComponent from "../../../../views/navbar/primary-navbar";
import SecondaryNavbarMainComponent from "../../../../views/navbar/secondary-navbar-v2";
import SalesAnalysis_SalesChannelMultiProductComponent from "../../../../views/sales-analysis/sales-channel/multi-product";
import WorkspaceRequirePage from "../../../workspace-require/WorkspaceRequirePage";
import FooterMain from "../../../../views/footer/FooterMain";
import { Helmet } from "react-helmet-async";

const MAIN_CLIENT_ORIGIN = process.env.REACT_APP_MAIN_CLIENT_ADDRESS;

export default function SalesAnalysis_SalesChannelMultiProductPage() {
    const workspaceRedux = useSelector(state => state.workspaceRedux);
    const userRedux = useSelector(state => state?.userRedux);

    useEffect(() => {
        if (userRedux?.isLoading) {
            return;
        }

        if (!userRedux?.userInfo?.id) {
            window.location.replace(`${MAIN_CLIENT_ORIGIN}`);
        }
    }, [userRedux?.userInfo?.id, userRedux?.isLoading]);

    if (workspaceRedux?.isLoading) {
        return null;
    }

    if (!workspaceRedux?.isLoading && !workspaceRedux?.workspaceInfo?.id) {
        return <WorkspaceRequirePage />;
    }

    return (
        <>
            <Helmet title={'판매스토어 성과 | 셀러툴'} />
            <PrimaryNavbarMainComponent />
            <SecondaryNavbarMainComponent />
            <SalesAnalysis_SalesChannelMultiProductComponent />
            <FooterMain />
        </>
    )
}