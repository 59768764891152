import { useEffect, useState } from "react";
import useLastMonthAnalysisHook from "./hooks/useLastMonthAnalysisHook";
import useThisMonthAnalysisHook from "./hooks/useThisMonthAnalysisHook";
import { Container } from "./style/MonthlyAnalysis.styled";
import MonthlyAnalysisFieldView from "./view/MonthlyAnalysisField.view";
import MonthlyAnalysisSkeletonFieldView from "./view/skeleton/MonthlyAnalysisSkeletonField.view";

export default function MonthlyAnalysisComponent() {
    const [lastMonthAvgData, setLastMonthAvgData] = useState(null);
    const [thisMonthAvgData, setThisMonthAvgData] = useState(null);

    const {
        isLoading: isThisMonthAnalysisLoading,
        thisMonthAnalysis
    } = useThisMonthAnalysisHook();

    const {
        isLoading: isLastMonthAnalysisLoading,
        lastMonthAnalysis
    } = useLastMonthAnalysisHook();

    useEffect(() => {
        if(!thisMonthAnalysis) {
            return;
        }

        if(!lastMonthAnalysis) {
            return;
        }

        handleInitThisMonthAvgAnalysis();
        handleInitLastMonthAvgAnalysis();
    }, [thisMonthAnalysis, lastMonthAnalysis])

    const handleInitThisMonthAvgAnalysis = () => {
        let data = [...thisMonthAnalysis];
        let payAmount = 0;
        let registration = 0;
        let unit = 0;

        // 판매데이터 계산
        data.forEach(r => {
            payAmount += r.salesPayAmount;
            registration += r.salesRegistration;
            unit += r.salesUnit;
        })

        payAmount = Math.round(payAmount / data.length);
        registration = Math.round(registration / data.length);
        unit = Math.round(unit / data.length);

        let monthAvgData = {
            payAmount,
            registration,
            unit,
        }
        setThisMonthAvgData(monthAvgData);
    }

    const handleInitLastMonthAvgAnalysis = () => {
        let data = [...lastMonthAnalysis];
        let payAmount = 0;
        let registration = 0;
        let unit = 0;

        // 판매데이터 계산
        data.forEach(r => {
            payAmount += r.salesPayAmount;
            registration += r.salesRegistration;
            unit += r.salesUnit;
        })

        payAmount = Math.round(payAmount / data.length);
        registration = Math.round(registration / data.length);
        unit = Math.round(unit / data.length);

        let monthAvgData = {
            payAmount,
            registration,
            unit,
        }
        setLastMonthAvgData(monthAvgData);
    }

    return (
        <>
            <Container>
                {(isThisMonthAnalysisLoading || isLastMonthAnalysisLoading) ?
                    <MonthlyAnalysisSkeletonFieldView />
                    :
                    <MonthlyAnalysisFieldView
                        thisMonthAvgData={thisMonthAvgData}
                        lastMonthAvgData={lastMonthAvgData}
                    />
                }
            </Container>
        </>
    )
}
