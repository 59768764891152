import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
    font-size : 1.2rem;
    font-weight: 700;
`;

export default function Title({
    children
}) {
    return (
        <Container>
            {children}
        </Container>
    )
}

Title.propTypes = {
    children: PropTypes.string
}
