import { toPriceUnitFormat } from "../../../../../../utils/numberFormatUtils";
import { BoxWrapper, ContentValue, ContentBox, MonthlyAnalysisFieldWrapper, TitleText } from "../style/MonthlyAnalysis.styled";

export default function MonthlyAnalysisFieldView({ thisMonthAvgData, lastMonthAvgData }) {
    return (
        thisMonthAvgData && lastMonthAvgData &&
        <MonthlyAnalysisFieldWrapper>
            <BoxWrapper>
                <TitleText>지난달 일일 평균</TitleText>
                <div style={{ width: '100%' }}>
                    <ContentBox>
                        <div>판매 매출액</div>
                        <ContentValue>{toPriceUnitFormat(lastMonthAvgData.payAmount || 0)}</ContentValue>
                    </ContentBox>
                    <ContentBox>
                        <div>판매 건</div>
                        <ContentValue>{lastMonthAvgData.registration || 0} 건</ContentValue>
                    </ContentBox>
                    <ContentBox>
                        <div>판매 수량</div>
                        <ContentValue>{lastMonthAvgData.unit || 0} 개</ContentValue>
                    </ContentBox>
                </div>
            </BoxWrapper>
            
            <BoxWrapper>
                <TitleText>이번달 일일 평균</TitleText>
                <div style={{ width: '100%' }}>
                    <ContentBox>
                        <div>판매 매출액</div>
                        <ContentValue>{toPriceUnitFormat(thisMonthAvgData.payAmount || 0)}</ContentValue>
                    </ContentBox>
                    <ContentBox>
                        <div>판매 건</div>
                        <ContentValue>{thisMonthAvgData.registration || 0} 건</ContentValue>
                    </ContentBox>
                    <ContentBox>
                        <div>판매 수량</div>
                        <ContentValue>{thisMonthAvgData.unit || 0} 개</ContentValue>
                    </ContentBox>
                </div>
            </BoxWrapper>
        </MonthlyAnalysisFieldWrapper>
    )
}