import Ripple from "../../../components/ripple/v1/Ripple";
import { ButtonBox, ButtonEl, Container, TextBox } from "./InfoField.styled";

const MAIN_CLIENT_ORIGIN = process.env.REACT_APP_MAIN_CLIENT_ADDRESS;

export default function InfoFieldComponent () {

    const __handle = {
        action: {
            routeToSelect: () => {
                window.location.href = `${MAIN_CLIENT_ORIGIN}/workspace/select`
            }
        }
    }

    return (
        <Container>
            <TextBox>
                작업하실 워크스페이스를 먼저 선택해 주세요.
            </TextBox>
            <ButtonBox>
                <ButtonEl
                    onClick={() => __handle.action.routeToSelect()}
                >
                    워크스페이스 선택창으로 이동
                    <Ripple color="#e0e0e0" />
                </ButtonEl>
            </ButtonBox>
        </Container>
    )
}