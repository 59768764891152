import PagenationComponent from "../../../../modules/pagenation/PagenationComponent";
import { Container } from "./GraphDataPagenation.styled";
import OrderBySelectorFieldView from "./view/OrderBySelectorField.view";

const GraphDataPagenationComponent = (props) => {
    return (
        <Container>
            <OrderBySelectorFieldView
                pageOrderByColumn={props.pageOrderByColumn}
                onActionChangePageOrderByColumn={props.onActionChangePageOrderByColumn}
            />
            <div style={{ height: '40px' }}>
                {props.productAnalysis &&
                    <PagenationComponent
                        align={'right'}
                        pageIndex={props.pageIndex}
                        totalPages={props.productAnalysis?.totalPages}
                        isFirst={props.productAnalysis?.first}
                        isLast={props.productAnalysis?.last}
                        totalElements={props.productAnalysis?.totalElements}
                        sizeElements={10}
                        onChangePrevPageIndex={props.onChangePrevPageIndex}
                        onChangeNextPageIndex={props.onChangeNextPageIndex}
                    ></PagenationComponent>
                }
            </div>
        </Container>
    );
}
export default GraphDataPagenationComponent;