import styled from "styled-components";

const Container = styled.div`
    padding: 0 20px;
`;

const HeaderInfoFieldWrapper = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1280px) {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
    }
`;

const HeaderBox = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;
    padding: 0 10px;

    @media screen and (max-width: 1280px) {
        display: none;
    }
`;

const HeaderItem = styled.div`
    border: none;
    border-bottom: 2px solid #a2a8b1;
    padding-bottom: 10px;
    width: 100%;
    font-size: 1.2rem;
    color: #444;
    text-align: center;
    font-weight: 700;
`;

const MobileHeaderBox = styled.div`
    display: none;
        
    @media screen and (max-width: 1280px){
        display: flex;
        padding: 0 10px;
        gap: 10px;
    }
`;

const DailyChannelAnalysisFieldWrapper = styled.div`
    padding: 10px 0;
    display: flex;
    align-items: center;
    width: 100%;
    
    @media screen and (max-width: 1280px) {
        flex-direction: column;
        row-gap: 20px;
    }
`;

const BoxWrapper = styled.div`
    width: 50%;
    margin: 0 10px;
    border-bottom: 1px solid #a2a8b1;

    @media screen and (max-width: 1280px) {
        width: 100%;
    }
`;

const ChannelDataBox = styled.div`
    padding: 15px;
    min-height: 150px;
    max-height: 150px;
    box-shadow: var(--defaultBoxShadow);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
        
    @media screen and (max-width: 1280px) {
        flex-direction: column;
        align-items: flex-start;
        max-height: fit-content;
        border: 1px solid ${props => props.datetime === 'yesterday' ? '#ffae4c' : 'var(--mainColor)'};
    }
`;

const DataLabelBox = styled.div`
    font-size: 1.1rem;
    color: #444;
    margin-bottom: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
`;

const DataContentWrapper = styled.div`
    overflow: auto;
    padding: 0 20px;

    &::-webkit-scrollbar{
        background: #e1e1e130;
        height:3px;
        width: 5px;
    }

    &::-webkit-scrollbar-track{
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb{
        background-color: #00000020;
        border-radius: 10px;
    }

    @media screen and (max-width: 1280px) {
        height: 80px;
        width: 100%;
    }
`;

const ContentBox = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
    }

    .content-title {
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (max-width: 576px) {
            width: 100%;
        }
    }

    .content-value {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .content-value .channel-rate {
        width: 70px;
        text-align: right;
        padding-right: 5px;
        display: inline-block;

        @media screen and (max-width: 576px) {
            text-align: left;
        }
    }
`;

const DailyAnalysisFieldWrapper = styled.div`
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1280px) {
        flex-direction: column;
        row-gap: 20px;
    }
`;

const DataBoxGroup = styled.div`
    margin-bottom: 20px;
    border-bottom: 1px solid #a2a8b1;

    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
    }
`;

const DataBox = styled.div`
    padding: 15px;
    min-height: 100px;
    max-height: 100px;
    box-shadow: var(--defaultBoxShadow);
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;

    @media screen and (max-width: 1280px) {
        flex-direction: column;
        align-items: flex-start;
        max-height: fit-content;
        border: 1px solid ${props => props.datetime === 'yesterday' ? '#ffae4c' : 'var(--mainColor)'};
    }

    .data-content {
        font-size: 1.2rem;
        font-weight: 700;
    }
`;

const MainContentBox = styled.div`

    @media screen and (max-width: 1280px){
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
`;

const MainContentSkeletonBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (max-width: 1280px){
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 5px;
    }
`;

const SubContentBox = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    font-size: 14px;
    color: #777;
    vertical-align: middle;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;

    @media screen and (max-width: 1280px) {
        flex-direction: column;
    }
`;

const SubContentSkeletonBox = styled.div`
    position: relative;
    display: flex;

    @media screen and (max-width: 1280px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const TrendContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 1px solid #c8c8c8;
    padding: 10px;
    /* justify-content: flex-end; */
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 65px;

    @media screen and (max-width: 1280px) {
        height: 30px;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
    }
`;

const TrendInfoBox = styled.div`
    display: flex;
    justify-content: space-around;
    font-weight: 700;
    color: ${props => props.color};

    @media screen and (max-width: 1280px) {
        justify-content: space-evenly;
    }
`;

const TrendInfoSkeletonBox = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 700;
    color: ${props => props.color};
    gap: 10px;

    @media screen and (max-width: 1280px) {
        flex-direction: row;
        justify-content: space-evenly;
    }
`;

export {
    Container,
    HeaderInfoFieldWrapper,
    HeaderBox,
    HeaderItem,
    MobileHeaderBox,
    DailyChannelAnalysisFieldWrapper,
    BoxWrapper,
    ChannelDataBox,
    DataLabelBox,
    DataContentWrapper,
    ContentBox,
    DailyAnalysisFieldWrapper,
    DataBoxGroup,
    DataBox,
    MainContentBox,
    SubContentBox,
    SubContentSkeletonBox,
    TrendContentWrapper,
    TrendInfoBox,
    MainContentSkeletonBox,
    TrendInfoSkeletonBox
}