import _ from "lodash";
import { useEffect, useState } from "react";
import { Container } from "./style/SummaryTable.styled";
import TableBoardFieldView from "./view/TableBoardField.view";
import TableFieldView from "./view/TableField.view";

export default function SummaryTableComponent (props) {
    const [tableData, setTableData] = useState(null);
    const [totalSumData, setTotalSumData] = useState(null);

    useEffect(() => {
        if(!props.analysis) {
            return;
        }

        handleActionCreateTotalSumData();
        handleActionCreateTableData();
    }, [props.analysis])

    const handleActionCreateTotalSumData = () => {
        let totalSumData = {
            datetime: '전체',
            orderRegistration: 0,
            orderUnit: 0,
            orderPayAmount: 0,
            salesRegistration: 0,
            salesUnit: 0,
            salesPayAmount: 0,
            unsalesRegistration: 0,
            unsalesUnit: 0,
            unsalesPayAmount: 0
        };

        props.analysis.forEach(r => {
            totalSumData = {
                ...totalSumData,
                orderRegistration: totalSumData.orderRegistration + r.orderRegistration,
                orderUnit: totalSumData.orderUnit + r.orderUnit,
                orderPayAmount: totalSumData.orderPayAmount + r.orderPayAmount,
                salesRegistration: totalSumData.salesRegistration + r.salesRegistration,
                salesUnit: totalSumData.salesUnit + r.salesUnit,
                salesPayAmount: totalSumData.salesPayAmount + r.salesPayAmount
            }
        });

        totalSumData = {
            ...totalSumData,
            unsalesRegistration: totalSumData.orderRegistration - totalSumData.salesRegistration,
            unsalesUnit: totalSumData.orderUnit - totalSumData.salesUnit,
            unsalesPayAmount: totalSumData.orderPayAmount - totalSumData.salesPayAmount
        }
        
        setTotalSumData(totalSumData);
    }

    const handleActionCreateTableData = () => {
        let tableData = props.analysis.map(r => {
            return {
                ...r,
                unsalesRegistration: r.orderRegistration - r.salesRegistration,
                unsalesUnit: r.orderUnit - r.salesUnit,
                unsalesPayAmount: r.orderPayAmount - r.salesPayAmount
            }
        });

        tableData = _.orderBy(tableData, 'salesPayAmount', 'desc');
        setTableData(tableData);
    }
    
    return (
        <Container>
            <TableBoardFieldView />

            <TableFieldView
                isTotalAnalysisLoading={props.isTotalAnalysisLoading}
                totalSumData={totalSumData}
                tableData={tableData}
            />
        </Container>
    )
}