import { Container, ItemBox, ItemEl } from "./ProductCategorySelectorModal.styled";

export default function ProductCategorySelectorModalComponent ({
    productCategories,
    onConfirm
}) {
    return (
        <Container>
            <div style={{ padding: '10px' }}>
                <ItemBox>
                    {productCategories?.map(category => {
                        return (
                            <ItemEl
                                key={category.id}
                                onClick={() => onConfirm(category.id)}
                            >
                                <div className='category-info'>
                                    {category.name}
                                </div>
                            </ItemEl>
                        );
                    })}
                </ItemBox>
            </div>
        </Container>
    )
}