import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GraphDataset, createGraphData, createGraphOption } from "../../../../../../utils/chartUtils";
import TableFieldView from "./view/TableField.view";
import _ from "lodash";
import { CustomChartGroupV2 } from "../../../../../../components/chart/v2/CustomChartGroup";
import BoardControlButtonFieldView from "./view/BoardControlButtonField.view";
import { Container } from "./style/OptionItem.styled";
import FieldCircleLoading from "../../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const SALES_GRAPH_BG_COLOR = ['#00695C', '#00895F', '#59A061', '#96B56C', '#B2C892', '#CFD586', '#E7DF88', '#DFE69A', '#D3D3D3'];
const OPTION_MAX_SIZE = 8;

export default function OptionItemComponent (props) {
    const [viewType, setViewType] = useState('graph');

    const [tableData, setTableData] = useState(null);

    const [salesPayAmountGraphData, setSalesPayAmountGraphData] = useState(null);
    const [salesUnitGraphData, setSalesUnitGraphData] = useState(null);

    const [payAmountGraphOption, setPayAmountGraphOption] = useState(null);
    const [unitGraphOption, setUnitGraphOption] = useState(null);
    
    useEffect(() => {
        if (!props.analysis) {
            return;
        }

        handleActionInitTableData();
    }, [props.analysis])


    useEffect(() => {
        if(!props.analysis) {
            return;
        }

        if(!(props.selectedOptions?.length > 0)) {
            handleActionResetGraphData();
            return;
        }

        handleActionCreateBestPayAmountGraphData();
        handleActionCreateBestUnitGraphData();
        handleActionCreateGraphOption();
    }, [props.analysis, props.selectedOptions])

    const handleActionResetGraphData = () => {
        setSalesPayAmountGraphData(null);
        setSalesUnitGraphData(null);
    }

    const handleActionInitTableData = () => {
        let tableData = [...new Set(props.analysis.map(r => JSON.stringify({
            productId: r.productId,
            productName: r.productName,
            performance: []
        })))].map(r => JSON.parse(r));

        props.analysis.forEach(r => {
            tableData = tableData.map(r2 => {
                if (r.productId === r2.productId) {
                    let data = r2.performance;
                    data.push(r);

                    return {
                        ...r2,
                        performance: data
                    }
                } else {
                    return r2;
                }
            });
        })

        setTableData(tableData);
    }

    const handleChangeViewType = (e) => {
        let value = e.target.value;
        setViewType(value);
    }

    const returnIsCheckedOne = (id) => props.selectedOptions.some(r => r.id === id);
    
    const handleActionCreateBestPayAmountGraphData = () => {
        let optionIds = props.selectedOptions.map(r => r.id);
        let performances = props.analysis.filter(r => optionIds.includes(r.optionId));
        let totalPayAmountSum = 0;
        let worstOptionPayAmountSum = 0;

        let sortedPayAmountPerformance = _.orderBy(performances, 'salesPayAmount', 'desc');
        let bestPayAmountPerformance = sortedPayAmountPerformance.slice(0, OPTION_MAX_SIZE);
        let payAmountGraphLabels = bestPayAmountPerformance.map((r, idx) => (idx + 1) + '. ' + r.optionName);

        sortedPayAmountPerformance.forEach((r, idx) => {
            if(idx >= OPTION_MAX_SIZE) {
                worstOptionPayAmountSum += r.salesPayAmount;
            }
            totalPayAmountSum += r.salesPayAmount;
        })

        let payAmountData = bestPayAmountPerformance.map(r => Math.round(((r.salesPayAmount / totalPayAmountSum) * 100) || 0));
        // BEST10 이하 데이터 추가
        if (performances.length > OPTION_MAX_SIZE && worstOptionPayAmountSum) {
            payAmountGraphLabels.push('기타');
            payAmountData.push(Math.round(((worstOptionPayAmountSum / totalPayAmountSum) * 100) || 0));
        }

        let salesPayAmountDatasets = {
            ...new GraphDataset().toJSON(),
            type: 'doughnut',
            label: '판매 매출액',
            data: payAmountData,
            backgroundColor: SALES_GRAPH_BG_COLOR,
            borderColor: '#fff',
            borderRadius: 0
        }

        setSalesPayAmountGraphData({
            labels: [...payAmountGraphLabels],
            datasets: [salesPayAmountDatasets]
        });
    }

    const handleActionCreateBestUnitGraphData = () => {
        let optionIds = props.selectedOptions.map(r => r.id);
        let performances = props.analysis.filter(r => optionIds.includes(r.optionId));
        let totalUnitSum = 0;
        let worstOptionUnitSum = 0;

        let sortedUnitPerformance = _.orderBy(performances, 'salesUnit', 'desc');
        let bestUnitPerformance = sortedUnitPerformance.slice(0, OPTION_MAX_SIZE);
        let unitGraphLabels = bestUnitPerformance.map((r, idx) => (idx + 1) + '. ' + r.optionName);

        sortedUnitPerformance.forEach((r, idx) => {
            if(idx >= OPTION_MAX_SIZE) {
                worstOptionUnitSum += r.salesUnit;
            }
            totalUnitSum += r.salesUnit;
        })

        let unitData = bestUnitPerformance.map(r => Math.round(((r.salesUnit / totalUnitSum) * 100) || 0));
        // BEST10 이하 데이터 추가
        if (performances.length > OPTION_MAX_SIZE && worstOptionUnitSum) {
            unitGraphLabels.push('기타');
            unitData.push(Math.round(((worstOptionUnitSum / totalUnitSum) * 100) || 0));
        }

        let salesUnitDatasets = {
            ...new GraphDataset().toJSON(),
            type: 'doughnut',
            label: '판매 수량',
            data: unitData,
            backgroundColor: SALES_GRAPH_BG_COLOR,
            borderColor: '#fff',
            borderRadius: 0
        }

        setSalesUnitGraphData({
            labels: [...unitGraphLabels],
            datasets: [salesUnitDatasets]
        });
    }

    const handleActionCreateGraphOption = () => {
        let payAmountOption = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '40%',
            plugins: {
                legend: {
                    position: 'right'
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            var label = tooltipItem?.label || '';
                            var value = tooltipItem?.parsed || 0;
                            return label + " : " + value + "%";
                        }
                    }
                },
                datalabels: {
                    formatter:function(value, context){
                        return (value >= 5) ? (value + "%") : '';
                    },
                    font: {
                        size: '14',
                        weight: '700',
                    },
                    color: 'white'
                },
                title: {
                    display: true,
                    text: '판매 매출액 비율',
                    font: {
                        size: '14'
                    }
                }
            }
        }

        let unitOption = {
            ...payAmountOption,
            plugins: {
                ...payAmountOption.plugins,
                title: {
                    ...payAmountOption.plugins.title,
                    text: '판매 수량 비율'
                }
            }
        }

        setPayAmountGraphOption(payAmountOption);
        setUnitGraphOption(unitOption);
    }

    return (
        <Container>
            <CustomChartGroupV2>
                <CustomChartGroupV2.BoardGroup className='flex-item'>
                    <div>
                        <CustomChartGroupV2.BoardGroup.Title>옵션 성과</CustomChartGroupV2.BoardGroup.Title>
                        <CustomChartGroupV2.BoardGroup.Info>선택된 상품의 옵션 판매 데이터를 표시합니다.</CustomChartGroupV2.BoardGroup.Info>
                    </div>
                    <BoardControlButtonFieldView
                        viewType={viewType}
                        onChangeViewType={handleChangeViewType}
                    />
                </CustomChartGroupV2.BoardGroup>

                <div style={{ position: 'relative' }}>
                    {props.isProductOptionAnalysisSearchLoading &&
                        <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                    }

                    <div style={{ position: 'relative', minHeight: '500px' }}>
                        {viewType === 'table' && tableData &&
                            <CustomChartGroupV2.BodyGroup style={{ padding: '0px', overflow: 'auto' }}>
                                <TableFieldView
                                    tableData={tableData}
                                    pageOrderByColumn={props.pageOrderByColumn}
                                    returnIsCheckedOne={returnIsCheckedOne}
                                />
                            </CustomChartGroupV2.BodyGroup>
                        }

                        {viewType === 'graph' &&
                            <CustomChartGroupV2.BodyGroup className='flex-item'>
                                <CustomChartGroupV2.BodyGroup.ChartBody height='320px'>
                                    <div className='half-type'>
                                        {salesPayAmountGraphData && payAmountGraphOption &&
                                            <Doughnut
                                                plugins={[ChartDataLabels]}
                                                data={createGraphData(salesPayAmountGraphData)}
                                                options={createGraphOption(payAmountGraphOption)}
                                            />
                                        }
                                    </div>
                                </CustomChartGroupV2.BodyGroup.ChartBody>

                                <CustomChartGroupV2.BodyGroup.ChartBody height='320px'>
                                    <div className='half-type'>
                                        {salesUnitGraphData && unitGraphOption &&
                                            <Doughnut
                                                plugins={[ChartDataLabels]}
                                                data={createGraphData(salesUnitGraphData)}
                                                options={createGraphOption(unitGraphOption)}
                                            />
                                        }
                                    </div>
                                </CustomChartGroupV2.BodyGroup.ChartBody>
                            </CustomChartGroupV2.BodyGroup>
                        }
                    </div>
                </div>
            </CustomChartGroupV2>
        </Container>
    )
}