import styled from "styled-components";

const Container = styled.div`
    padding: 0 20px;
`;

const GraphOperatorFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const ButtonBoxWrapper = styled.div`
    display: flex;
    gap: 3px;
`;


export {
    Container,
    GraphOperatorFieldWrapper,
    ButtonBoxWrapper
}