import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { salesAnalysisDataConnect } from "../../../../../../data_connect/salesAnalysisDataConnect";
import { getEndDate, getStartDate, getStartDateOfMonth, getTimeDiffWithUTC, setPlusDate } from "../../../../../../utils/dateFormatUtils";
import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import defaultErrorHandler from "../../../../../../handler/dataConnectErrorHandler";

const TODAY = new Date();
const YESTERDAY = setPlusDate(TODAY, 0, 0, -1);

export default function useThisMonthAnalysisHook () {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [thisMonthAnalysis, setThisMonthAnalysis] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const router = useRouterHookV2();

    useEffect(() => {
        async function fetchInit() {
            let startDate = getStartDate(getStartDateOfMonth(TODAY));
            let endDate = YESTERDAY.getDate() > TODAY.getDate() ? getEndDate(TODAY) : getEndDate(YESTERDAY);
            let utcHourDifference = getTimeDiffWithUTC();
            let searchPeriodType = router.query.periodType ?? 'registration';

            let body = {
                startDate,
                endDate,
                utcHourDifference,
                periodType: searchPeriodType
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchThisMonthAnalysis(body, headers);
        }

        fetchInit();
    }, [router.location])

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchThisMonthAnalysis = async (body, headers) => {
        toggleIsLoading(true);
        await salesAnalysisDataConnect().searchForConditionalAnalysis(body, headers)
            .then(res => {
                if (res.status === 200) {
                    setThisMonthAnalysis(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
        toggleIsLoading(false);
    }

    return {
        isLoading,
        thisMonthAnalysis,
        reqSearchThisMonthAnalysis
    }
}