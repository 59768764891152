import styled, { css } from 'styled-components';

const Container = styled.div`
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: flex-end;

    @media all and (max-width: 992px){
        padding: 10px;
    }
`;

const OrderBySelectorFieldWrapper = styled.div`
    display: flex;
    gap: 3px;

    .button-el {
        width: 80px;
        height: 30px;
        background-color: var(--defaultButtonColor);
        border: 1px solid var(--defaultBorderColor);
        border-radius: 5px;
        
        &.checked {
            background-color: #555 !important;
            border: 1px solid #555;
            color: white;
        }

        &:hover {
            background-color: var(--defaultHoverColor);
        }
    }
`;

const ButtonEl = styled.button`
    width: 80px;
    height: 30px;
    background-color: var(--defaultButtonColor);
    border: 1px solid var(--defaultBorderColor);
    border-radius: 5px;
    
    ${(props) => 
        props.checked &&
        css`
            background-color: #555 !important;
            border: 1px solid #555;
            color: white;
        `
    }

    &:hover {
        background-color: var(--defaultHoverColor);
    }
`;

export {
    Container,
    OrderBySelectorFieldWrapper,
    ButtonEl
}