import { FormControlLabel, Switch } from "@mui/material";
import { ButtonBoxWrapper, GraphOperatorFieldWrapper } from "../style/GraphOperator.styled";
import { CustomPeriodTypeButtonGroup } from "../../../../../../components/buttons/period-type/v1/CustomPeriodTypeButtonGroup";

export default function GraphOperatorFieldView({ checkedSwitch, searchDimension, onChangeSwitch, onChangeSearchDimension }) {
    return (
        <GraphOperatorFieldWrapper>
            <div>
                <FormControlLabel labelPlacement="start" control={<Switch checked={checkedSwitch} onChange={() => onChangeSwitch()} />} label="주문 데이터 표시" />
            </div>
            <ButtonBoxWrapper>
                <CustomPeriodTypeButtonGroup>
                    <CustomPeriodTypeButtonGroup.DateButton
                        checked={searchDimension === 'date'}
                        callback={(result) => onChangeSearchDimension(result)}
                        style={{ marginRight: '3px' }}
                    />
                    <CustomPeriodTypeButtonGroup.WeekButton
                        checked={searchDimension === 'week'}
                        callback={(result) => onChangeSearchDimension(result)}
                        style={{ marginRight: '3px' }}
                    />
                    <CustomPeriodTypeButtonGroup.MonthButton
                        checked={searchDimension === 'month'}
                        callback={(result) => onChangeSearchDimension(result)}
                    />
                </CustomPeriodTypeButtonGroup>
            </ButtonBoxWrapper>
        </GraphOperatorFieldWrapper>
    )
}