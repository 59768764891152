import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { GraphDataset, createGraphData, createGraphOption } from "../../../../../utils/chartUtils";
import { getEndDate, getStartDate } from "../../../../../utils/dateFormatUtils";
import { toPriceUnitFormat } from "../../../../../utils/numberFormatUtils";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";
import { customToast, defaultOptions } from "../../../../../components/toast/custom-react-toastify/v1";
import FieldCircleLoading from "../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const DEFAULT_GRAPH_BG_COLOR = ['#AFA0D0', '#BFC8E4'];

export default function BestProductGraphComponent (props) {
    const [salesPayAmountGraphData, setSalesPayAmountGraphData] = useState(null);
    const [totalPayAmountGraphData, setTotalPayAmountGraphData] = useState(null);
    
    const [salesUnitGraphData, setSalesUnitGraphData] = useState(null);
    const [totalUnitGraphData, setTotalUnitGraphData] = useState(null);

    const [payAmountGraphOption, setPayAmountGraphOption] = useState(null);
    const [unitGraphOption, setUnitGraphOption] = useState(null);

    const [payAmountGraphLabels, setPayAmountGraphLabels] = useState(null);
    const [unitGraphLabels, setUnitGraphLabels] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        if (!props.analysis) {
            return;
        }

        handleActionCreatePayAmountGraphData();
        handleActionCreateUnitGraphDate();
    }, [props.analysis])

    useEffect(() => {
        if(!(totalPayAmountGraphData && totalUnitGraphData)) {
            return;
        }

        handleActionCreatePayAmountGraphOption();
        handleActionCreateUnitGraphOption();
    }, [totalPayAmountGraphData, totalUnitGraphData])

    const handleActionCreatePayAmountGraphData = () => {
        let salesPayAmount = [];
        let orderPayAmount = [];
        let graphLabels = [];
        let productIdLabels = [];

        props.analysis.forEach(r => {
            productIdLabels.push(r.productId);
            graphLabels.push(r.productName ?? '미지정');
            salesPayAmount.push(r.salesPayAmount);
            orderPayAmount.push(r.orderPayAmount);
        });
        setPayAmountGraphLabels(productIdLabels);

        let orderPayAmountDatasets = {
            ...new GraphDataset().toJSON(),
            type: 'bar',
            label: '(주문) 매출액',
            data: orderPayAmount,
            fill: false,
            backgroundColor: DEFAULT_GRAPH_BG_COLOR[0] + '55',
            borderColor: DEFAULT_GRAPH_BG_COLOR[0] + '55',
            borderWidth: 0,
            order: 1
        }
        let salesPayAmountDatasets = {
            ...new GraphDataset().toJSON(),
            type: 'bar',
            label: '판매 매출액',
            data: salesPayAmount,
            backgroundColor: DEFAULT_GRAPH_BG_COLOR[0],
            borderColor: DEFAULT_GRAPH_BG_COLOR[0],
            borderWidth: 0,
            order: 0
        }

        setSalesPayAmountGraphData({
            labels: [...graphLabels],
            datasets: [salesPayAmountDatasets]
        });
        setTotalPayAmountGraphData({
            labels: [...graphLabels],
            datasets: [salesPayAmountDatasets, orderPayAmountDatasets]
        });
    }

    const handleActionCreateUnitGraphDate = () => {
        let salesUnit = [];
        let orderUnit = [];
        let graphLabels = [];
        let productIdLabels = [];

        props.analysis.forEach(r => {
            productIdLabels.push(r.productId);
            graphLabels.push(r.productName ?? '미지정');
            salesUnit.push(r.salesUnit);
            orderUnit.push(r.orderUnit);
        });
        setUnitGraphLabels(productIdLabels);

        let orderUnitDatasets = {
            ...new GraphDataset().toJSON(),
            type: 'bar',
            label: '(주문) 수량',
            data: orderUnit,
            fill: false,
            backgroundColor: DEFAULT_GRAPH_BG_COLOR[1] + '55',
            borderColor: DEFAULT_GRAPH_BG_COLOR[1] + '55',
            borderWidth: 0,
            order: 1
        }
        let salesUnitDatasets = {
            ...new GraphDataset().toJSON(),
            type: 'bar',
            label: '판매 수량',
            data: salesUnit,
            backgroundColor: DEFAULT_GRAPH_BG_COLOR[1],
            borderColor: DEFAULT_GRAPH_BG_COLOR[1],
            borderWidth: 0,
            order: 0
        }

        setSalesUnitGraphData({
            labels: [...graphLabels],
            datasets: [salesUnitDatasets]
        });
        setTotalUnitGraphData({
            labels: [...graphLabels],
            datasets: [salesUnitDatasets, orderUnitDatasets]
        });
    }

    const handleActionCreatePayAmountGraphOption = () => {
        let payAmountOption = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: true,
            },
            indexAxis: 'y',
            scales: {
                x: {
                    ticks: {
                        callback: function (value, index, ticks) {
                            return toPriceUnitFormat(value);
                        }
                    }
                },
                y: {
                    // label을 7글자로 제한
                    afterTickToLabelConversion: function (scaleInstance) {
                        let ticks = scaleInstance.ticks;

                        let updatedTicks = ticks.map(r => {
                            let updatedLabel = r.label
                            if (updatedLabel.length > 7) {
                                return {
                                    ...r,
                                    label: updatedLabel.substring(0, 7) + "..."
                                }
                            } else {
                                return r;
                            }
                        })

                        scaleInstance.ticks = updatedTicks;
                    },
                    // label width 고정
                    afterFit: function(scaleInstance) {
                        scaleInstance.width = 100; 
                    },
                    stacked: true,
                    grid: {
                        display: false
                    }
                }
            },
            onClick: function (e, item) {
                handleActionSetPayAmountGraphClickOption(e, item);
            },
            onHover: (e, item) => {
                const target = e.native ? e.native.target : e.target;
                target.style.cursor = item[0] ? 'pointer' : 'default';
            }
        }

        setPayAmountGraphOption(payAmountOption);
    }

    const handleActionCreateUnitGraphOption = () => {
        let unitOption = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'y',
                intersect: false,
            },
            indexAxis: 'y',
            scales: {
                y: {
                    // label을 7글자로 제한
                    afterTickToLabelConversion: function (scaleInstance) {
                        let ticks = scaleInstance.ticks;

                        let updatedTicks = ticks.map(r => {
                            let updatedLabel = r.label
                            if (updatedLabel.length > 7) {
                                return {
                                    ...r,
                                    label: updatedLabel.substring(0, 7) + "..."
                                }
                            } else {
                                return r;
                            }
                        })

                        scaleInstance.ticks = updatedTicks;
                    },
                    // label width 고정
                    afterFit: function(scaleInstance) {
                        scaleInstance.width = 100; 
                    },
                    stacked: true,
                    grid: {
                        display: false
                    }
                }
            },
            onClick: function (e, item) {
                handleActionSetUnitGraphClickOption(e, item);
            },
            onHover: (e, item) => {
                const target = e.native ? e.native.target : e.target;
                target.style.cursor = item[0] ? 'pointer' : 'default';
            }
        }

        setUnitGraphOption(unitOption);
    }

    const handleActionSetPayAmountGraphClickOption = (e, item) => {
        if (item.length === 0) return;

        var itemIdx = item[0].index;
        var label = payAmountGraphLabels[itemIdx];

        let startDate = getStartDate(router.query.startDate);
        let endDate = getEndDate(router.query.endDate);
        let productIds = [label];

        if(!label) {
            let memo = '\'미지정\' 상품은 상세 성과를 확인할 수 없습니다.';
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        let data = {
            state: {
                ...router.state,
                startDate,
                endDate,
                productIds
            }
        }

        router.replace(data);
        props.onActionOpenDetailGraphSelectorModal();
    }

    const handleActionSetUnitGraphClickOption = (e, item) => {
        if (item.length === 0) return;

        var itemIdx = item[0].index;
        var label = unitGraphLabels[itemIdx];

        if(!label) {
            let memo = '\'미지정\' 상품은 상세 성과를 확인할 수 없습니다.';
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        let startDate = getStartDate(router.query.startDate);
        let endDate = getEndDate(router.query.endDate);
        let productIds = [label];

        let data = {
            state: {
                ...router.state,
                startDate,
                endDate,
                productIds
            }
        }

        router.replace(data);
        props.onActionOpenDetailGraphSelectorModal();
    }

    return (
        <CustomChartGroupV2>
            <CustomChartGroupV2.BoardGroup>
                <CustomChartGroupV2.BoardGroup.Title>상품 매출 / 수량 순위</CustomChartGroupV2.BoardGroup.Title>
                <CustomChartGroupV2.BoardGroup.Info>상품의 매출액, 판매수량 순위가 표시됩니다. 매출, 수량순으로 정렬해 판매 순위를 확인할 수 있습니다.</CustomChartGroupV2.BoardGroup.Info>
            </CustomChartGroupV2.BoardGroup>

            <div style={{ position: 'relative' }}>
                {props.isProductAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                }
                <CustomChartGroupV2.BodyGroup className='flex-item'>
                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {totalPayAmountGraphData && payAmountGraphOption &&
                            <Chart
                                data={createGraphData(props.checkedSwitch ? totalPayAmountGraphData : salesPayAmountGraphData)}
                                options={createGraphOption(payAmountGraphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>

                    <CustomChartGroupV2.BodyGroup.ChartBody>
                        {totalUnitGraphData && unitGraphOption &&
                            <Chart
                                data={createGraphData(props.checkedSwitch ? totalUnitGraphData : salesUnitGraphData)}
                                options={createGraphOption(unitGraphOption)}
                            />
                        }
                    </CustomChartGroupV2.BodyGroup.ChartBody>
                </CustomChartGroupV2.BodyGroup>
            </div>
        </CustomChartGroupV2>
    )
}