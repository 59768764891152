import styled from "styled-components";

const Container = styled.div`
    background-color: #fff;
    box-shadow: var(--defaultBoxShadow);
    padding: 15px;
    margin-top: 30px;
    border-radius: 10px;
`;

const SearchFieldWrapper = styled.div`
    display: flex;
    gap: 10px;

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
`;

const ProductSearchButtonEl = styled.button`
    width: 200px;
    padding: 5px 0;
    height: 48px;
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    -webkit-transition: all .1s;
    transition: all .1s;

    &:hover {
        background-color: var(--defaultButtonHoverColor);
    }
`;

const SearchFieldBox = styled.div`
    width: 100%;
    overflow: auto;
    background-color: var(--defaultGrayColor);
    border: 1px solid var(--defaultGrayColor);
    max-height: 100px;
    min-height: 100px;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px;
    flex-wrap: wrap;
`;

const ItemBox = styled.div`
    position: relative;
    transition: all .1s;

    :hover {
        transform: scale(1.02);
    }

    .close-box {
        position: absolute;
        right: -2px;
        top: -5px;
    }

    .close-button-box {
        background-color: white;
        border: 1.5px solid #e56756;
        box-shadow: var(--defaultBoxShadow);
        border-radius: 50%;
    }
`;

const ItemButtonEl = styled.button`
    padding: 7px 20px;
    border-radius: 20px;
    font-size: 14px;
    background-color: white;
    border: 1px solid var(--mainColor);
    box-shadow: var(--defaultBoxShadow);
    color: #000;
    font-weight: 600;
    transition: 0.1s;
`;

const ButtonFieldWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 20px;
    gap: 10px;
`;

const ButtonEl = styled.button`
    width: 150px;
    margin: 0px;
    padding: 0px;
    height: 48px;
    background: var(--mainColor);
    color: white;
    font-size: 16px;
    border: none;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    box-shadow: var(--defaultBoxShadow);
`;

export {
    Container,
    SearchFieldWrapper,
    ButtonFieldWrapper,
    ButtonEl,
    ProductSearchButtonEl,
    SearchFieldBox,
    ItemBox,
    ItemButtonEl
}