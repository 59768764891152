import styled from "styled-components";

const Container = styled.div`
    .half-type {
        width: 50%;
        height: 320px;
        margin: 0 auto;

        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }
`;

export {
    Container
}