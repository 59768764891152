import _ from "lodash";
import { useEffect, useState } from "react";
import { BackdropHookComponent } from "../../../../hooks/backdrop/useBackdropHook";
import NavBarComponent from "../../navbar/NavBar.component";
import BestCategoryGraphComponent from "./best-category-graph/BestCategoryGraph.component";
import BestProductGraphComponent from "./best-product-graph/BestProductGraph.component";
import ConditionSelectorComponent from "./condition-selector/ConditionSelector.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import useProductCategoriesHook from "./hooks/useProductCategoriesHook";
import useSubCategoryAndRelatedProductAnalysisHook from "./hooks/useSubCategoryAndRelatedProductAnalysisHook";
import { Container } from "./index.styled";
import OperatorComponent from "./operator/Operator.component";
import TextInfoComponent from "./text-info/TextInfo.component";

export default function SalesAnalysis_SubCategoryBestComponent() {
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);

    const [selectedProductCategory, setSelectedProductCategory] = useState(null);

    const [bestPayAmountItem, setBestPayAmountItem] = useState(null);
    const [bestUnitItem, setBestUnitItem] = useState(null);

    const [checkedSwitch, setCheckedSwitch] = useState(false);

    const [optionListSize, setOptionListSize] = useState(10);

    const {
        productCategories: totalProductCategories
    } = useProductCategoriesHook();

    const {
        isLoading: isCategoryAnalysisLoading,
        analysis,
        reqSearchSubCategoryAndRelatedProductAnalysis
    } = useSubCategoryAndRelatedProductAnalysisHook({ selectedProductCategory });

    useEffect(() => {
        if(!analysis) {
            return;
        }
    
        handleActionInitData();
    }, [analysis])

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }

    const handleActionInitData = () => {
        let subCategory = [];
        let bestPayAmountItem = [];
        let bestUnitItem = [];

        analysis.forEach(r => {
            let subCategoryId = r.productSubCategoryId;
            let subCategoryName = r.productSubCategoryName;
            
            let payAmount = _.orderBy(r.performances, 'salesPayAmount', 'desc');
            let unit = _.orderBy(r.performances, 'salesUnit', 'desc');

            bestPayAmountItem.push({
                subCategoryId,
                subCategoryName,
                performances: payAmount
            });
            bestUnitItem.push({
                subCategoryId,
                subCategoryName,
                performances: unit
            });

            subCategory.push({
                id: subCategoryId,
                name: subCategoryName
            })
        });

        bestPayAmountItem = bestPayAmountItem.map(r =>  {
            return {
                ...r,
                performances: r.performances
            }
        });
        bestUnitItem = bestUnitItem.map(r => {
            return {
                ...r,
                performances: r.performances
            }
        });

        let sortedSubCategoryName = _.sortBy(subCategory, 'name');
        setSubCategories(sortedSubCategoryName);
        setBestPayAmountItem(bestPayAmountItem);
        setBestUnitItem(bestUnitItem)

        setSelectedSubCategories([]);
    }

    const returnIsCheckedOne = (id) => selectedSubCategories.some(subCategory => subCategory.id === id);

    const handleActionCheckOne = (e, id) => {
        e.stopPropagation();

        let data = new Set([...selectedSubCategories.map(r => r.id)]);

        if (data.has(id)) {
            data.delete(id);
        } else {
            data.add(id);
        }
        
        let updatedData = subCategories.filter(r => [...data].includes(r.id));
        setSelectedSubCategories(updatedData);
    }

    const handleActionCheckAll = (e) => {
        e.stopPropagation();
        setSelectedSubCategories([...subCategories]);
    }

    const handleActionCheckCancelAll = (e) => {
        e.stopPropagation();
        setSelectedSubCategories([]);
    }

    const handleActionUpdateSelectedProductCategory = (id) => {
        let category = totalProductCategories.filter(r => r.id === id)[0];
        setSelectedProductCategory(category);

        setSelectedSubCategories([]);
    }

    const handleSubmitSearchPerformance = async (body, headers) => {
        await reqSearchSubCategoryAndRelatedProductAnalysis(body, headers);
    }
    
    const handleChangeOptionListSize = (e) => {
        let value = e.target.value;
        setOptionListSize(value);
    }

    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'서브 카테고리 성과'}
                    headerName={'BEST 카테고리'}
                    sidebarColor={'#ffffff'}
                >
                    <TextInfoComponent />
                    
                    <OperatorComponent
                        productCategories={totalProductCategories}
                        selectedProductCategory={selectedProductCategory}
                        onActionUpdateSelectedProductCategory={handleActionUpdateSelectedProductCategory}
                    />

                    <BestCategoryGraphComponent
                        isCategoryAnalysisLoading={isCategoryAnalysisLoading}
                        subCategories={subCategories}
                        analysis={analysis}
                    />

                    <ConditionSelectorComponent
                        subCategories={subCategories}

                        returnIsCheckedOne={returnIsCheckedOne}
                        onActionCheckOne={handleActionCheckOne}
                        onActionCheckAll={handleActionCheckAll}
                        onActionCheckCancelAll={handleActionCheckCancelAll}
                    />

                    <GraphOperatorComponent
                        checkedSwitch={checkedSwitch}
                        optionListSize={optionListSize}

                        onActionChangeSwitch={handleChangeSwitch}
                        onActionChangeOptionListSize={handleChangeOptionListSize}
                    />

                    <BestProductGraphComponent
                        selectedSubCategories={selectedSubCategories}
                        subCategories={subCategories}
                        checkedSwitch={checkedSwitch}
                        bestPayAmountItem={bestPayAmountItem}
                        bestUnitItem={bestUnitItem}
                        optionListSize={optionListSize}
                    />
                </NavBarComponent>

                <DateOperatorComponent
                    selectedProductCategory={selectedProductCategory}
                    onSubmitSearchPerformance={handleSubmitSearchPerformance}
                />
            </Container>
        </>
    )
}