import styled from 'styled-components';

const IconBox = styled.i`
    position: relative;
    margin-left: 5px;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 1px;
    vertical-align: middle;
    border-radius: 50%;
    
    background-color: ${props => props.color};
`;

export default function CustomDotIcon({
    children,
    color,
    ariaLabel,
    ...props
}) {
    return (
        <IconBox
            aria-label={ariaLabel}
            color={color}
            {...props}
        >
            {children}
        </IconBox>
    );
}