import { useEffect, useState } from "react";
import CustomCommonModal from "../../../../../components/modal/common/v1/CustomCommonModal";
import ProductCategorySelectorModalComponent from "./modal/product-category-selector/ProductCategorySelectorModal.component";
import { Container } from "./style/Operator.styled";
import SearchFieldView from "./view/SearchField.view";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { dateToYYYYMMDD, setPlusDate } from "../../../../../utils/dateFormatUtils";

export default function OperatorComponent (props) {
    const [productCategoriesSelectorModalOpen, setProductCategoriesSelectorModalOpen] = useState(false);

    const router = useRouterHookV2();

    const handleActionOpenProductCategorySelectorModal = () => {
        setProductCategoriesSelectorModalOpen(true);
    }

    const handleActionCloseProductCategorySelectorModal = () => {
        setProductCategoriesSelectorModalOpen(false);
    }

    const handleActionSelectProductCategory = (id) => {
        props.onActionSelectProductCategory(id);
        handleActionCloseProductCategorySelectorModal();
    }

    const handleActionRouteToPage = (pathname) => {
        let searchStartDate = setPlusDate(new Date(), 0, 0, -13);
        let searchEndDate = new Date();
        let categoryId = props.selectedProductCategory.id;
        
        if (router.query.startDate && router.query.endDate) {
            searchStartDate = new Date(router.query.startDate);
            searchEndDate = new Date(router.query.endDate);
        }

        let routerState = {
            startDate: searchStartDate,
            endDate: searchEndDate,
            includeUndefinedCategoryFlag: false,
            productCategoryIds: [categoryId],
        }

        let data = {
            pathname,
            state: routerState
        }

        router.query.startDate = dateToYYYYMMDD(searchStartDate);
        router.query.endDate = dateToYYYYMMDD(searchEndDate);
        router.push(data);
    }

    return (
        <>
            <Container>
                <SearchFieldView
                    selectedProductCategory={props.selectedProductCategory}
                    onActionOpenProductCategorySelectorModal={handleActionOpenProductCategorySelectorModal}
                    onActionRouteToPage={handleActionRouteToPage}
                />
            </Container>

            {props.productCategories && 
                <CustomCommonModal
                    open={productCategoriesSelectorModalOpen}
                    onClose={handleActionCloseProductCategorySelectorModal}
                    element={
                        <ProductCategorySelectorModalComponent
                            productCategories={props.productCategories}

                            onConfirm={handleActionSelectProductCategory}
                            onClose={handleActionCloseProductCategorySelectorModal}
                        />
                    }
                />
            }
        </>
    )
}