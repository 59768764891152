import { useState } from "react"
import defaultErrorHandler from "../../../../../handler/dataConnectErrorHandler";
import { salesAnalysisDataConnect } from "../../../../../data_connect/salesAnalysisDataConnect";

export default function useSalesChannelProductAnalysisHook() {
    const [analysis, setAnalysis] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchChannelProductAnalysis = async (body, headers) => {
        toggleIsLoading(true);

        await salesAnalysisDataConnect().searchChannelAnalysis(body, headers)
            .then(res => {
                if (res.status === 200) {
                    setAnalysis(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))

        toggleIsLoading(false);
    }

    return {
        isLoading,
        analysis,
        reqSearchChannelProductAnalysis
    }
}