import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container } from "./InfoField.styled";

const MAIN_CLIENT_ORIGIN = process.env.REACT_APP_MAIN_CLIENT_ADDRESS;

const InfoFieldComponent = (props) => {
    const userRedux = useSelector(state => state.userRedux);

    return (
        <>
            {(userRedux.isLoading === false && userRedux.userInfo === null) &&
                <Container>
                    <Link
                        to={`${MAIN_CLIENT_ORIGIN}/login`}
                        // passHref
                    >
                        <div className='rt-el'>로그인</div>
                    </Link>
                </Container>
            }

            {(userRedux.isLoading === false && userRedux.userInfo) &&
                <Container>
                    <Link
                        to={`${MAIN_CLIENT_ORIGIN}/profile/account`}
                        // passHref
                    >
                        <div className='rt-el'>내 정보</div>
                    </Link>
                    <div className='rt-el' onClick={props.onLogout}>로그아웃</div>
                </Container>
            }
        </>
    );
}
export default InfoFieldComponent;