import { FormControlLabel, Switch } from "@mui/material";
import { GraphOperatorFieldWrapper, SelectEl } from "../style/GraphOperator.styled";

const viewSize = [5, 10, 20, 30];

export default function GraphOperatorFieldView({ checkedSwitch, onActionChangeSwitch, optionListSize, onActionChangeOptionListSize }) {
    return (
        <GraphOperatorFieldWrapper>
            <div>
                <FormControlLabel labelPlacement="start" control={<Switch checked={checkedSwitch} onChange={() => onActionChangeSwitch()} />} label="주문 데이터 표시" />
            </div>
            <div>
                <SelectEl onChange={(e) => onActionChangeOptionListSize(e)} value={optionListSize}>
                    {viewSize.map((r, idx) => {
                        return (
                            <option key={'view-option-idx' + idx} value={r}>{`${r}위 확인`}</option>
                        )
                    })}
                </SelectEl>
            </div>
        </GraphOperatorFieldWrapper>
    )
}