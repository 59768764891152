import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    
    .common-calendar .button-el {
        position: fixed;
        background-color: var(--mainColor);
        border: 1px solid var(--mainColor);
        cursor: pointer;
        box-shadow: 1px 1px 10px #484b6c78;
        right: 40px;
        bottom: 40px;
        text-align: center;
        border-radius: 50%;
        padding: 15px;
        z-index: 10;
        transition: 0.2s;

        &:hover {
            transform: scale(1.1);
        }
    }
`;

const CalendarButtonEl = styled.button`
    background-color: var(--mainColor);
    border: 1px solid var(--mainColor);
    cursor: pointer;
    box-shadow: 1px 1px 10px #484b6c78;
    text-align: center;
    border-radius: 50%;
    padding: 15px;
    z-index: 10;
    bottom: 40px;
    right: 40px;
    position: fixed;
    transition: 0.2s;
    animation-duration: .3s;
    animation-name: fadein;

    &:hover {
        transform: scale(1.1);
    }
`;

const SearchFieldWrapper = styled.div`
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    width: 60%;

    @media screen and (max-width: 992px){
        width: 100%;
    }
`;

const SelectEl = styled.select`
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none;
    background: url(/images/icon/arrowDropDown_default_808080.svg) 97% 50% / 25px no-repeat rgb(255, 255, 255);
    background-color: white;
        
    &:focus{
        outline: none;
    }
`;

const ButtonBox = styled.div`
    width: 80px;

    .date-selector-box {
        position: absolute;
        z-index: 10;

        @media screen and (max-width: 992px) {
            right: 20px;
        }
    }
`;

const ButtonEl = styled.button`
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 40px;
    font-weight: 600;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    -webkit-transition: all .1s;
    transition: all .1s;
    background-color: ${props => props.checked ? `#d9dbe2 !important` : '#fff'};

    &:hover {
        background-color: var(--defaultButtonHoverColor);
    }
`;

const DateSelectorFieldWrapper = styled.div`
    padding: 30px 20px;
    display: flex;
    gap: 5px;
    min-height: 400px;
    overflow: hidden;

    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
    
    .date-selector-box {
        width: 50%;
        position: static;

        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }

    .date-selector-box-title {
        font-weight: 700;
        padding: 10px;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
        color: #404040;
        background-color: #eef2f9;
        border-radius: 20px 20px 0 0;
    }
`;

const FixDateRangeSelectorFieldWrapper = styled.div`
    .custom-button-group {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 10px 20px;

        @media screen and (max-width: 992px) {
            flex-direction: column;
        }
    }

    .custom-button {
        width: 100%;
        padding: 4px 2px;
        border-radius: 5px;
        height: 38px;
        font-size: 12px;
        background-color: white;
        border: 1px solid var(--mainColorOpacity500);
        -webkit-transition: all .1s;
        transition: all .1s;
        margin-right: 5px;

        :last-child {
            margin-right: 0px;
        }

        &:hover{
            transform: scale(1.02);
        }
        
        @media all and (max-width:992px) {
            margin: 0;
            font-size: 14px;
        }
    }
`;

export {
    Container,
    CalendarButtonEl,
    SearchFieldWrapper,
    SelectEl,
    ButtonBox,
    ButtonEl,
    DateSelectorFieldWrapper,
    FixDateRangeSelectorFieldWrapper
}