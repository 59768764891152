import { ButtonEl, OrderBySelectorFieldWrapper } from "../GraphDataPagenation.styled";

export default function OrderBySelectorFieldView({ pageOrderByColumn, onActionChangePageOrderByColumn }) {
    return (
        <OrderBySelectorFieldWrapper>
            <div>
                <ButtonEl
                    type='button'
                    checked={pageOrderByColumn === 'payAmount'}
                    onClick={(e) => onActionChangePageOrderByColumn(e)}
                    value='payAmount'
                >
                    매출 순
                </ButtonEl>
            </div>
            <div>
                <ButtonEl
                    type='button'
                    checked={pageOrderByColumn === 'unit'}
                    onClick={(e) => onActionChangePageOrderByColumn(e)}
                    value='unit'
                >
                    수량 순
                </ButtonEl>
            </div>
        </OrderBySelectorFieldWrapper>
    )
}