import withMainApiCsrfWrapper from "../utils/withMainApiCsrfWrapper";
import { axiosAuthInterceptor } from "./axiosInterceptors"
import qs from 'qs';

const SALES_ANALYSIS_API_ADDRESS = process.env.REACT_APP_SALES_ANALISIS_API_ADDRESS;

const salesAnalysisDataConnect = () => {
    return {
        /**
         * 
         * @param {object} body
         * @param {string[]} body.searchDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {object} headers 
         * @param {string} headers.wsId
         * @returns 
         */
        searchDashboard: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/dashboard`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {string[]} body.productIds
         * @param {object} headers 
         * @param {string} headers.wsId
         * @returns 
         */
        searchChannelAnalysis: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/channel/label:datetime`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {object} headers 
         * @param {string} headers.wsId
         * @returns 
         */
        searchForConditionalAnalysis: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/label:datetime`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {bool} body.includeUndefinedSalesChannelFlag
         * @param {string[]} body.salesChannels
         * @param {bool} body.includeUndefinedCategoryFlag
         * @param {string[]} body.prodcutCategoryIds
         * @param {string[]} body.productSubCategoryIds
         * @param {object} params
         * @param {int} params.page
         * @param {string} params.sort
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchBestProductAnalysis: async function (body, params, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/product/best`, body, {
                    params,
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN',
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: 'brackets' })
                    }
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {bool} body.includeUndefinedSalesChannelFlag
         * @param {string[]} body.salesChannels
         * @param {bool} body.includeUndefinedCategoryFlag
         * @param {string[]} body.productCategoryIds
         * @param {string[]} body.productSubCategoryIds
         * @param {string[]} body.productIds
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchOptionAnalysisByProduct: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/product/best/product-option`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchCategoryAnalysis: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/category/label:datetime`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {string[]} body.productCategoryIds
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchSubCategoryAnalysis: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/sub-category/label:datetime`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchCategoryAndRelatedProductAnalysis: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/category/product/label:productCategory`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {string[]} body.productCategoryIds
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchSubCategoryAndRelatedProductAnalysis: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/sub-category/product/label:productSubCategory`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {string[]} body.productIds
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchProductAnalysis: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/product/label:datetime`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {string[]} body.productIds
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchProductOptionAnalysis: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/product-option/label:datetime`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {string[]} body.productIds
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchChannelAnalysisByProduct: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/product-option/channel/label:salesChannel`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        },
        /**
         * 
         * @param {object} body
         * @param {string} body.startDate
         * @param {string} body.endDate
         * @param {int} body.utcHourDifference
         * @param {string} body.periodType ['registration' | 'channelOrderDate']
         * @param {string[]} body.productIds
         * @param {object} headers
         * @param {string} headers.wsId
         * @returns 
         */
        searchProductOptionAnalysisByOption: async function (body, headers) {
            return await withMainApiCsrfWrapper(
                () => axiosAuthInterceptor.post(`${SALES_ANALYSIS_API_ADDRESS}/api/v1/sales-analysis/search/product-option/label:productOption`, body, {
                    headers,
                    withCredentials: true,
                    xsrfCookieName: 'x_api_csrf_token',
                    xsrfHeaderName: 'X-XSRF-TOKEN'
                })
            )
        }
    }
}

export {
    salesAnalysisDataConnect
}