import CustomCommonModal from "../../../../../../components/modal/common/v1/CustomCommonModal";
import { customToast, defaultOptions } from "../../../../../../components/toast/custom-react-toastify/v1";
import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import { dateToYYYYMMDD } from "../../../../../../utils/dateFormatUtils";
import { Container } from "./DetailGraphSelector.styled";
import ButtonFieldView from "./view/ButtonField.view";

export default function DetailGraphSelectorModalComponent(props) {
    const router = useRouterHookV2();

    const handleActionRouteToPage = (pathname) => {
        if(pathname === '/sales-analysis/sub-category') {
            let memo = '';
            if(router.state?.productCategoryIds?.length > 1 ) {
                memo = '하위카테고리 성과를 확인하기 위해 카테고리를 1개만 선택해주세요.';
                customToast.warning(memo, {
                    ...defaultOptions,
                    toastId: memo
                });
                return;
            }

            if(router.state?.productCategoryIds?.includes(null)) {
                memo = '\'미지정\' 카테고리는 하위카테고리 성과를 확인할 수 없습니다.';
                customToast.warning(memo, {
                    ...defaultOptions,
                    toastId: memo
                });
                return;
            }
        }

        let undefinedCategoryFlag = router.state?.productCategoryIds?.includes(null);
        let categoryIds = router.state?.productCategoryIds.filter(r => r);

        let routerState = {
            ...router.state,
            includeUndefinedCategoryFlag: undefinedCategoryFlag,
            productCategoryIds: categoryIds
        }

        let data ={
            pathname,
            state: routerState
        }
        
        router.query.startDate = dateToYYYYMMDD(router.state.startDate);
        router.query.endDate = dateToYYYYMMDD(router.state.endDate);
        router.push(data);
    }

    return (
        props.modalOpen && 
        <CustomCommonModal
            open={props.modalOpen}
            title={'그래프 선택'}
            element={
                <Container>
                    <ButtonFieldView
                        onActionRouteToPage={handleActionRouteToPage}
                    />
                </Container>
            }
            maxWidth={'xs'}

            onClose={() => props.onActionCloseModal()}
        />
    )
}