import { useEffect, useState } from "react";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { Container } from "./style/ConditionSearch.styled";
import SearchFieldView from "./view/SearchField.view";

export default function ConditionSearchComponent(props) {
    const [selectedSubCategories, setSelectedSubCategories] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        if(!props.productSubCategories) {
            return;
        }

        handleActionInitSelectedCategories();
    }, [props.productSubCategories])

    const handleActionInitSelectedCategories = () => {
        let subCategoryIds = router.state?.productSubCategoryIds ?? [];
        let selectedData = props.productSubCategories.filter(r => subCategoryIds.includes(r.id));
        setSelectedSubCategories(selectedData);
    }
    
    return (
        <Container>
            <SearchFieldView selectedSubCategories={selectedSubCategories} />
        </Container>
    )
}