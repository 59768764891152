import CustomBoxImage from "../../../../../../components/image/box-image/CustomBoxImage";
import FieldCircleLoading from "../../../../../../components/loading/field-loading/v1/FieldCircleLoading";
import HighlightedText from "../../../../../../components/text/highlight/HighlightedText";
import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import { ImageButtonEl, ControlButtonBox, ItemBoxWrapper, SelectorFieldWrapper, ButtonEl, ButtonBox, InputEl, CheckButtonBox } from "../style/ConditionSelector.styled";

const MAXIMUM_SEARCH_SIZE = 10;

export default function SelectorFieldView({ isSalesChannelAnalysisLoading, inputValue, salesChannel, onActionCheckAll, onActionCheckCancelAll, returnIsCheckedOne, onActionCheckOne, onChangeInputValue }) {
    const router = useRouterHookV2();

    return (
        <SelectorFieldWrapper>
            <ControlButtonBox>
                <InputEl
                    type='text'
                    onChange={(e) => onChangeInputValue(e)}
                    value={inputValue}
                    placeholder="판매스토어 검색"
                />
                <CheckButtonBox>
                    <div>
                        <ImageButtonEl
                            className='button-el'
                            color='#5fcf80'
                            onClick={(e) => onActionCheckAll(e)}
                        >
                            <CustomBoxImage
                                src='/images/icon/check_all_default_5fcf80.svg'
                                size={25}
                            />
                        </ImageButtonEl>
                    </div>
                    <div>
                        <ImageButtonEl
                            className='button-el'
                            color='#e56767'
                            onClick={(e) => onActionCheckCancelAll(e)}
                        >
                            <CustomBoxImage
                                src='/images/icon/check_remove_all_default_e56767.svg'
                                size={25}
                            />
                        </ImageButtonEl>
                    </div>
                </CheckButtonBox>
            </ControlButtonBox>
            <div style={{ position: 'relative' }}>
                {isSalesChannelAnalysisLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px', backgroundColor: '#ffffff' }} />
                }
                <ItemBoxWrapper>
                    {salesChannel?.map((r, idx) => {
                        let channelName = r ?? '미지정';
                        let isAccent = inputValue && channelName.includes(inputValue);

                        return (
                            <ButtonBox
                                key={'channel-idx' + idx}
                                onClick={(e) => onActionCheckOne(e, r)}
                            >
                                <ButtonEl
                                    type='button'
                                    checked={returnIsCheckedOne(r)}
                                    onChange={(e) => onActionCheckOne(e, r)}
                                    isUndefinedItem={channelName === '미지정'}
                                >
                                    {isAccent ?
                                        <HighlightedText
                                            text={channelName}
                                            query={inputValue}
                                            highlightColor={'var(--defaultRedColor)'}
                                        />
                                        :
                                        channelName
                                    }
                                </ButtonEl>
                            </ButtonBox>
                        )
                    })}
                    {!isSalesChannelAnalysisLoading && (salesChannel?.length === 0) &&
                        <div style={{ color: '#666' }}>
                            조회 결과 없음
                        </div>
                    }
                </ItemBoxWrapper>
            </div>
            <div style={{ padding: '3px', color: '#888', float: 'right' }}>
                {(router.state?.salesChannels?.length ?? 0) + ' / ' + MAXIMUM_SEARCH_SIZE}
            </div>
        </SelectorFieldWrapper>
    )
}