function toPriceUnitFormat(price) {
    let priceSize = price?.toString().length;

    if(priceSize > 8) {
        return parseFloat((price / (10 ** 8)).toFixed(2)) + ' 억원';
    }else if(priceSize > 7) {
        return parseFloat((price / (10 ** 7)).toFixed(2)) + ' 천만원';
    }else if(priceSize > 6) {
        return parseFloat((price / (10 ** 6)).toFixed(2)) + ' 백만원';
    }else if(priceSize > 4) {
        return parseFloat((price / (10 ** 4)).toFixed(2)) + ' 만원';
    }else if(priceSize > 3) {
        return parseFloat((price / (10 ** 3)).toFixed(2)) + ' 천원';
    }else {
        return price + ' 원';
    }
}

// old에 대한 new의 상승 백분율
function getTrendPercentage(newValue, oldValue) {
    if(!(newValue && oldValue)) {
        return 0;
    }

    // 유지
    if(newValue === oldValue) {
        return 0;
    }

    // old가 0이면 100% 감소
    if(oldValue === 0) {
        return 100;
    }
    return parseFloat(((newValue - oldValue) / oldValue) * 100).toFixed(1);
}

export{
    toPriceUnitFormat,
    getTrendPercentage
}
