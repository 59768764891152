import styled from "styled-components";

const Container = styled.div`
    margin-top: 20px;
`;

const TextFieldWrapper = styled.div`
    font-size: 13px;
    color: #404040;
    padding: 0 10px;
`;

const SelectorFieldWrapper = styled.div`
    margin: 10px 0;
    padding: 20px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(240, 240, 240);
    border-radius: 15px;
    box-shadow: var(--defaultBoxShadow);
    overflow: auto;
`;

const ControlButtonBox = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 10px;
`;

const ImageButtonEl = styled.button`
    width: 30px;
    height: 30px;
    position: relative;
    padding: 0;
    border-radius: 50%;
    border: 1px solid ${(props) => props.color};
    box-shadow: var(--defaultBoxShadow);
    background-color: #fff;
    transition: all 0.05s;

    &:hover {
        transform: scale(1.1);
    }
`;

const ItemBoxWrapper = styled.div`
    padding: 15px 10px;
    min-height: 65px;
    max-height: 100px;
    overflow: auto;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
`;

const ButtonEl = styled.button`
    transition: 0.1s;
    padding: 5px 23px;
    background-color: var(--defaultButtonColor);
    border-radius: 20px;
    font-size: 16px;
    box-shadow: var(--defaultBoxShadow);

    border: 1px solid ${props => props.checked ? `var(--mainColor)` : `var(--mainColorOpacity30)`};
    background-color: ${props => props.checked ? `var(--mainColor)` : `#fff`};
    color: ${props => props.checked ? 'white' : ''};

    &:hover {
        transform: scale(1.05);
    }
`;

export {
    Container,
    TextFieldWrapper,
    SelectorFieldWrapper,
    ControlButtonBox,
    ImageButtonEl,
    ItemBoxWrapper,
    ButtonEl
}