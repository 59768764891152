import { useEffect, useState } from "react";
import NavBarComponent from "../../navbar/NavBar.component";
import ConditionSelectorComponent from "./condition-selector/ConditionSelector.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import useSalesChannelProductAnalysisHook from "./hooks/useSalesChannelProductAnalysisHook";
import { Container } from "./index.styled";
import OperatorComponent from "./operator/Operator.component";
import PayAmountDayOfWeekGraphComponent from "./pay-amount-day-of-week-graph/PayAmountDayOfWeekGraph.component";
import PayAmountGraphComponent from "./pay-amount-graph/PayAmountGraph.component";
import RegistrationAndUnitGraphComponent from "./registration-and-unit-graph/RegistrationAndUnitGraph.component";
import TextInfoComponent from "./text-info/TextInfo.component";
import _ from "lodash";
import { nullLastAndSort } from "../../../../utils/sortUtils";
import { customToast, defaultOptions } from "../../../../components/toast/custom-react-toastify/v1";

const MAXIMUM_SEARCH_SIZE = 10;

export default function SalesAnalysis_SalesChannelMultiProductComponent() {
    const [searchDimension, setSearchDimension] = useState('date');
    const [checkedSwitch, setCheckedSwitch] = useState(false);

    const [searchedProduct, setSearchedProduct] = useState([]);
    
    const [salesChannel, setSalesChannel] = useState(null);
    const [selectedChannel, setSelectedChannel] = useState([]);

    const {
        isLoading: isChannelProductAnalysisLoading,
        analysis,
        reqSearchChannelProductAnalysis
    } = useSalesChannelProductAnalysisHook();

    useEffect(() => {
        if(!analysis) {
            return;
        }

        handleActionUpdateSalesChannel();
    }, [analysis])

    const handleActionUpdateSalesChannel = () => {
        let channel = new Set([]);
        analysis.forEach(r => r.performances?.forEach(r2 => {
            channel.add(r2.salesChannel);
        }))
        
        let channelName = nullLastAndSort([...channel]);
        setSalesChannel(channelName);
        setSelectedChannel([]);
    }

    const returnIsCheckedOne = (channel) => selectedChannel.includes(channel);

    const handleActionCheckOne = (e, channel) => {
        e.stopPropagation();
        let data = new Set(selectedChannel);

        if(data.has(channel)) {
            data.delete(channel);
        } else {
            data.add(channel);
        }

        if(data.size > MAXIMUM_SEARCH_SIZE) {
            let memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }
        
        setSelectedChannel([...data]);
    }

    const handleActionCheckAll = (e) => {
        e.stopPropagation();
        
        if(salesChannel?.length > MAXIMUM_SEARCH_SIZE) {
            let memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        setSelectedChannel([...salesChannel]);
    }

    const handleActionCheckCancelAll = (e) => {
        e.stopPropagation();
        setSelectedChannel([]);
    }

    const handleActionResetPerformance = () => {
        setSearchedProduct([]);
        setSelectedChannel([]);
        setSalesChannel(null);
    }

    const handleChangeSelectedProduct = (data) => {
        setSearchedProduct([...data]);
    }

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }

    const handleChangeDimension = (value) => {
        setSearchDimension(value);
    }

    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'판매스토어 성과'}
                    headerName={'다중 상품 성과'}
                    sidebarColor={'#ffffff'}
                >
                    <>
                        <TextInfoComponent />
                        
                        <OperatorComponent
                            onActionResetPerformance={handleActionResetPerformance}
                            onActionChangeSelectedProduct={handleChangeSelectedProduct}
                            onSubmitSearchPerformance={reqSearchChannelProductAnalysis}
                        />
                        
                        <ConditionSelectorComponent
                            salesChannel={salesChannel}
                            selectedChannel={selectedChannel}

                            returnIsCheckedOne={returnIsCheckedOne}
                            onActionCheckOne={handleActionCheckOne}
                            onActionCheckAll={handleActionCheckAll}
                            onActionCheckCancelAll={handleActionCheckCancelAll}
                        />

                        <GraphOperatorComponent
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}

                            onChangeSwitch={handleChangeSwitch}
                            onChangeSearchDimension={handleChangeDimension}
                        />

                        <PayAmountGraphComponent
                            isChannelProductAnalysisLoading={isChannelProductAnalysisLoading}
                            selectedChannel={selectedChannel}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}
                        />

                        <RegistrationAndUnitGraphComponent
                            isChannelProductAnalysisLoading={isChannelProductAnalysisLoading}
                            selectedChannel={selectedChannel}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}
                        />

                        <PayAmountDayOfWeekGraphComponent
                            isChannelProductAnalysisLoading={isChannelProductAnalysisLoading}
                            selectedChannel={selectedChannel}
                            analysis={analysis}
                        />
                    </>
                </NavBarComponent>

                <DateOperatorComponent
                    searchedProduct={searchedProduct}
                    onSubmitSearchPerformance={reqSearchChannelProductAnalysis}
                />
            </Container>
        </>
    )
}