import { useState } from "react";
import { productDataConnect } from "../../../../../../data_connect/productDataConnect";
import defaultErrorHandler from "../../../../../../handler/dataConnectErrorHandler";

export default function useProductHook () {
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState(null);

    const onActionClearProducts = () => {
        setProducts(null);
    }

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchProductAll = async (params, headers) => {
        toggleIsLoading(true);
        await productDataConnect().searchAll(params, headers)
            .then(res => {
                if (res.status === 200) {
                    setProducts(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
        toggleIsLoading(false);
    }

    return {
        isLoading,
        products,
        reqSearchProductAll,
        onActionClearProducts
    }
}