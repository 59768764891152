import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
    margin-top: 5px;
    font-size: 14px;
`;

export default function Info({
    children
}) {
    return (
        <Container>
            {children}
        </Container>
    )
}

Info.propTypes = {
    children: PropTypes.string
}
