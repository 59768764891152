import { Link } from "react-router-dom";
import { Container, LogoImgEl } from "./LogoField.styled";

const LogoSrc = '/images/logo/logo1.png';
const MAIN_CLIENT_ORIGIN = process.env.REACT_APP_MAIN_CLIENT_ADDRESS;

const LogoFieldComponent = (props) => {
    return (
        <>
            <Container>
                <Link
                    to={`${MAIN_CLIENT_ORIGIN}`}
                    // passHref
                >
                    <div className='logo-figure'>
                        <img className='logo-el' src={LogoSrc} alt='logo icon' />
                    </div>
                </Link>
            </Container>
        </>
    );
}
export default LogoFieldComponent;