import { useEffect, useState } from "react";
import { Container } from "./style/ConditionSearch.styled";
import SearchFieldView from "./view/SearchField.view";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { nullLastAndSort } from "../../../../../utils/sortUtils";

export default function ConditionSearchComponent(props) {
    const [selectedSalesChannels, setSelectedSalesChannels] = useState(null);
    const router = useRouterHookV2();

    useEffect(() => {
        if(!(router.state?.salesChannels?.length > 0) && !router.state?.includeUndefinedSalesChannelFlag) {
            return;
        }

        handleActionInitSelectedSalesChannels();
    }, [router.state])

    const handleActionInitSelectedSalesChannels = () => {
        let data = [];
        if(router.state?.includeUndefinedSalesChannelFlag) {
            data.push(null);
        }
        data.push(...router.state.salesChannels);

        let sortedData = nullLastAndSort(data);
        setSelectedSalesChannels(sortedData);
    }

    return (
        <Container>
            <SearchFieldView selectedSalesChannels={selectedSalesChannels} />
        </Container>
    )
}