import { customToast, defaultOptions } from "../../../../../components/toast/custom-react-toastify/v1";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { Container } from "./style/ConditionSelector.styled";
import SelectorFieldView from "./view/SelectorField.view";
import TextFieldView from "./view/TextField.view";

const MAXIMUM_SEARCH_SIZE = 10;

export default function ConditionSelectorComponent(props) {
    const router = useRouterHookV2();

    const returnIsCheckedOne = category => router.state?.productCategoryIds?.includes(category.id);

    const handleActionCheckOne = (e, category) => {
        e.stopPropagation();
        let selectedCategoryIds = new Set(router.state?.productCategoryIds);

        if(selectedCategoryIds.has(category.id)) {
            selectedCategoryIds.delete(category.id);
        } else {
            selectedCategoryIds.add(category.id);
        }

        if(selectedCategoryIds.size > MAXIMUM_SEARCH_SIZE) {
            let memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`;
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        router.replace({
            state: {
                ...router.state,
                productCategoryIds: [...selectedCategoryIds]
            }
        })
    }

    const handleActionCheckAll = (e) => {
        e.stopPropagation();
        if(props.categories?.length > MAXIMUM_SEARCH_SIZE) {
            let memo = `최대 검색 개수인 ${MAXIMUM_SEARCH_SIZE}개가 초과되었습니다.`;
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        let ids = props.categories.map(r => r.id);
        router.replace({
            state: {
                ...router.state,
                productCategoryIds: ids
            }
        })
    }

    const handleActionCheckCancelAll = (e) => {
        e.stopPropagation();

        router.replace({
            state: {
                ...router.state,
                productCategoryIds: []
            }
        })
    }

    return (
        <Container>
            <TextFieldView />

            <SelectorFieldView
                isCategoryAnalysisLoading={props.isCategoryAnalysisLoading}
                categories={props.categories}

                returnIsCheckedOne={returnIsCheckedOne}
                onActionCheckOne={handleActionCheckOne}
                onActionCheckAll={handleActionCheckAll}
                onActionCheckCancelAll={handleActionCheckCancelAll}
            />
        </Container>
    )
}