import styled from "styled-components"
import { BodyGroup } from "./body/BodyGroup";
import { BoardGroup } from "./board/BoardGroup";

const ChartGroupContainer = styled.div`
    background-color: #fff;
    border-radius: 10px;
    box-shadow: var(--defaultBoxShadow);
    margin-top: 30px;
`;

function ChartGroupMain({
    children
}) {
    return (
        <ChartGroupContainer>
            {children}
        </ChartGroupContainer>
    )
}

export const CustomChartGroupV2 = Object.assign(ChartGroupMain, {
    BoardGroup: BoardGroup,
    BodyGroup: BodyGroup
})