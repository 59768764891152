import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import qs from 'query-string';

export default function useRouterHookV2 () {
    const location = useLocation();
    const query = qs.parse(location.search);
    const navigate = useNavigate();
    const pathname = location.pathname;
    const state = location.state;

    const push = (data) => {
        let pushPathname = data?.pathname ?? pathname;
        let pushState = data?.state

        navigate({
            pathname: pushPathname,
            search: `?${createSearchParams({...query})}`,
        },
        {
            state: pushState
        })
    }

    const replace = (data) => {
        let replacePathname = data?.pathname ?? pathname;
        let replaceState = data?.state;

        navigate({
            pathname: replacePathname,
            search: `?${createSearchParams({...query})}`,
        },
        {
            state: replaceState,
            replace: true
        })
    }

    const clearParams = () => {
        navigate(location.pathname);
    }

    const back = () => {
        navigate(-1);
    }

    const reload = () => {
        navigate(0);
    }
    
    return {
        location,
        query,
        pathname,
        state,
        push,
        replace,
        clearParams,
        back,
        reload
    }
}