import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getEndDate, getStartDate, getTimeDiffWithUTC } from "../../../../utils/dateFormatUtils";
import NavBarComponent from "../../navbar/NavBar.component";
import BestProductGraphComponent from "./best-product-graph/BestProductGraph.component";
import ConditionSearchComponent from "./condition-search/ConditionSearch.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";
import GraphDataPagenationComponent from "./graph-data-pagenation/GraphDataPagenation.component";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import useCategoryOptionAnalysisHook from "./hooks/useCategoryOptionAnalysisHook";
import useCategoryProductAnalysisHook from "./hooks/useCategoryProductAnalysisHook";
import useProductSubCategoriesHook from "./hooks/useProductSubCategoriesHook";
import { Container } from "./index.styled";
import DetailGraphSelectorModalComponent from "./modal/detail-graph-selector/DetailGraphSelector.component";
import OptionItemComponent from "./option-item/OptionItem.component";
import TextInfoComponent from "./text-info/TextInfo.component";
import useRouterHookV2 from "../../../../hooks/router/useRouterHookV2";
import { customToast, defaultOptions } from "../../../../components/toast/custom-react-toastify/v1";

export default function SalesAnalysis_SubCategoryBestProductComponent() {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [checkedSwitch, setCheckedSwitch] = useState(false);
    
    const [detailGraphSelectorModalOpen, setDetailGraphSelectorModalOpen] = useState(null);
    
    const [pageOrderByColumn, setPageOrderByColumn] = useState('payAmount');
    const [pageIndex, setPageIndex] = useState(0);

    const router = useRouterHookV2();

    const {
        productSubCategories
    } = useProductSubCategoriesHook();

    const {
        isLoading: isProductAnalysisLoading,
        analysis : productAnalysis,
        reqSearchCategoryBestProductAnalysis,
    } = useCategoryProductAnalysisHook({ pageOrderByColumn });

    const {
        isLoading: isOptionAnalysisLoading,
        analysis : optionAnalysis
    } = useCategoryOptionAnalysisHook({ productAnalysis });

    useEffect(() => {
        if (!(router.state?.productSubCategoryIds?.length > 0)) {
            let memo = '서브 카테고리별 상품 순위를 확인하기 위해 카테고리를 먼저 선택해주세요.\n[서브 카테고리 총 매출액 & 판매 건] 페이지로 이동합니다.';
            customToast.warning(memo, {
                ...defaultOptions,
                toastId: memo
            })
            router.push({ pathname: '/sales-analysis/sub-category' });
            return;
        }
    }, [])

    useEffect(() => {
        if(productAnalysis?.content?.length > 0) {
            setPageIndex(productAnalysis?.number)
        }
    }, [productAnalysis])

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }

    const handleChangePrevPageIndex = () => {
        let page = pageIndex - 1;
        let periodType = router.query.periodType ?? 'registration';

        let body = {
            startDate: getStartDate(router.query.startDate),
            endDate: getEndDate(router.query.endDate),
            utcHourDifference: getTimeDiffWithUTC(),
            productSubCategoryIds: router.state?.productSubCategoryIds,
            periodType
        }

        let params = {
            page: page + 1,
            sort: pageOrderByColumn + '_desc'
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        setPageIndex(page);
        reqSearchCategoryBestProductAnalysis(body, params, headers);
    }

    const handleChangeNextPageIndex = () => {
        let page = pageIndex + 1;
        let body = {
            startDate: getStartDate(router.query.startDate),
            endDate: getEndDate(router.query.endDate),
            utcHourDifference: getTimeDiffWithUTC(),
            productSubCategoryIds: router.state?.productSubCategoryIds,
            periodType: router.query.periodType ?? 'registration'
        }

        let params = {
            page: page + 1,
            sort: pageOrderByColumn + '_desc'
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        setPageIndex(page);
        reqSearchCategoryBestProductAnalysis(body, params, headers);
    }

    const handleChangePageOrderByColumn = (e) => {
        let value = e.target.value;

        let body = {
            startDate: getStartDate(router.query.startDate),
            endDate: getEndDate(router.query.endDate),
            utcHourDifference: getTimeDiffWithUTC(),
            productSubCategoryIds: router.state?.productSubCategoryIds,
            periodType: router.query.periodType ?? 'registration'
        }

        let params = {
            page: 0,
            sort: value + '_desc'
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        setPageOrderByColumn(value);
        setPageIndex(0);
        reqSearchCategoryBestProductAnalysis(body, params, headers);
    }

    const handleActionOpenDetailGraphSelectorModal = () => {
        setDetailGraphSelectorModalOpen(true);
    }

    const handleActionCloseDetailGraphSelectorModal = () => {
        setDetailGraphSelectorModalOpen(false);
    }

    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'카테고리 성과'}
                    headerName={'상품 순위'}
                    sidebarColor={'#ffffff'}
                >
                    <>
                        <TextInfoComponent />
                        
                        <ConditionSearchComponent productSubCategories={productSubCategories}/>

                        <GraphOperatorComponent
                            checkedSwitch={checkedSwitch}

                            onActionChangeSwitch={handleChangeSwitch}
                        />

                        <BestProductGraphComponent
                            isProductAnalysisLoading={isProductAnalysisLoading}
                            checkedSwitch={checkedSwitch}
                            analysis={productAnalysis?.content}
                            
                            onActionOpenDetailGraphSelectorModal={handleActionOpenDetailGraphSelectorModal}
                        />

                        <GraphDataPagenationComponent
                            pageOrderByColumn={pageOrderByColumn}
                            productAnalysis={productAnalysis}
                            pageIndex={pageIndex}

                            onChangePrevPageIndex={handleChangePrevPageIndex}
                            onChangeNextPageIndex={handleChangeNextPageIndex}
                            onActionChangePageOrderByColumn={handleChangePageOrderByColumn}
                        />

                        <OptionItemComponent
                            isOptionAnalysisLoading={isOptionAnalysisLoading}
                            checkedSwitch={checkedSwitch}
                            optionAnalysis={optionAnalysis}
                            pageOrderByColumn={pageOrderByColumn}
                            productAnalysis={productAnalysis?.content}
                        />
                    </>
                </NavBarComponent>

                <DateOperatorComponent
                    pageOrderByColumn={pageOrderByColumn}
                    onSubmitSearchPerformance={reqSearchCategoryBestProductAnalysis}
                />
            </Container>

            <DetailGraphSelectorModalComponent
                modalOpen={detailGraphSelectorModalOpen}

                onActionCloseModal={handleActionCloseDetailGraphSelectorModal}
            />
        </>
    )    
}
