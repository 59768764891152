import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { salesAnalysisDataConnect } from "../../../../../data_connect/salesAnalysisDataConnect";
import { getEndDate, getStartDate, getTimeDiffWithUTC, setPlusDate } from "../../../../../utils/dateFormatUtils";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import defaultErrorHandler from "../../../../../handler/dataConnectErrorHandler";

export default function useTotalAnalysisHook() {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [isLoading, setIsLoading] = useState(false);
    const [analysis, setAnalysis] = useState(null);

    const router = useRouterHookV2();

    useEffect(() => {
        async function fetchInit() {
            let searchStartDate = setPlusDate(new Date(), 0, 0, -13);
            let searchEndDate = new Date();
            let periodType = router.query.periodType ?? 'registration';

            if (router.query.startDate && router.query.endDate) {
                searchStartDate = new Date(router.query.startDate);
                searchEndDate = new Date(router.query.endDate);
            }

            let body = {
                startDate: getStartDate(searchStartDate),
                endDate: getEndDate(searchEndDate),
                utcHourDifference: getTimeDiffWithUTC(),
                periodType
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }

            await reqSearchTotalAnalysis(body, headers);
        }

        fetchInit();
    }, []);

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchTotalAnalysis = async (body, headers) => {
        toggleIsLoading(true);
        await salesAnalysisDataConnect().searchForConditionalAnalysis(body, headers)
            .then(res => {
                if (res.status === 200) {
                    setAnalysis(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err));
        toggleIsLoading(false);
    }

    return {
        isLoading,
        analysis,
        reqSearchTotalAnalysis
    }
}