import useRouterHookV2 from "../../../../../../../hooks/router/useRouterHookV2";
import { dateToYYYYMMDDAndDayName, setPlusDate } from "../../../../../../../utils/dateFormatUtils";
import { DateTextBox, TextBox, TextInfoFieldWrapper } from "../style/TextInfo.style";

const DEFAULT_START_DATE = setPlusDate(new Date(), 0, 0, -13);
const DEFAULT_END_DATE = new Date();

export default function TextInfoFieldView () {
    const router = useRouterHookV2();
    const startDate = router.query.startDate ? new Date(router.query.startDate) : DEFAULT_START_DATE;
    const endDate = router.query.endDate ? new Date(router.query.endDate) : DEFAULT_END_DATE;
    const periodType = router.query.periodType === 'channelOrderDate' ? '주문일시' : '주문 등록일';

    return (
        <TextInfoFieldWrapper>
            <TextBox>
                <div style={{ paddingRight: '10px' }}>
                    <span>{periodType} : </span>
                </div>
                <DateTextBox>
                    <div>{dateToYYYYMMDDAndDayName(startDate)}</div>  
                    <div> ~ </div>
                    <div>{dateToYYYYMMDDAndDayName(endDate)}</div>
                </DateTextBox>
            </TextBox>
        </TextInfoFieldWrapper>
    )
}