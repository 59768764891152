import _ from "lodash";
import { useEffect, useState } from "react";
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GraphDataset, createGraphData, createGraphOption } from "../../../../../utils/chartUtils";
import { CustomChartGroupV2 } from "../../../../../components/chart/v2/CustomChartGroup";
import { Container } from "./style/BestCategoryGraph.style";
import FieldCircleLoading from "../../../../../components/loading/field-loading/v1/FieldCircleLoading";

const SALES_GRAPH_BG_COLOR = ['#698FC1', '#5FA6C7', '#6EB9C5', '#61CBC6', '#6CDCBA', '#8EEAA3', '#BFF388', '#CCE6B0', '#D3D3D3'];
const GRAPH_LABEL_MAX_SIZE = 8;

export default function BestCategoryGraphComponent(props) {
    const [payAmountGraphData, setPayAmountGraphData] = useState(null);
    const [unitGraphData, setUnitGraphData] = useState(null);

    const [payAmountGraphOption, setPayAmountGraphOption] = useState(null);
    const [unitGraphOption, setUnitGraphOption] = useState(null);

    useEffect(() => {
        if(!props.analysis) {
            return;
        }

        handleActionCreateBestPayAmountGraphData();
        handleActionCreateBestUnitGraphData();
        handleActionCreateGraphOption();
    }, [props.analysis])

    const handleActionCreateBestPayAmountGraphData = () => {
        let categoryPerformance = [...props.analysis];
        let worstCategoryPayAmountSum = 0;
        let totalPayAmountSum = 0;
        
        categoryPerformance = categoryPerformance.map(r => {
            let sum = 0;
            r.performances.forEach(performance => {
                sum += performance.salesPayAmount;
            });
            totalPayAmountSum += sum;
            
            return {
                category: r.productCategoryName,
                payAmount: sum
            };
        })
        
        categoryPerformance = _.orderBy(categoryPerformance, 'payAmount', 'desc');
        let bestPayAmountPerformance = categoryPerformance.slice(0, GRAPH_LABEL_MAX_SIZE);
        let payAmountGraphLabels = bestPayAmountPerformance.map((r, idx) => (idx + 1) + '. ' + r.category);
        let payAmountData = bestPayAmountPerformance.map(r => Math.round(((r.payAmount / totalPayAmountSum) * 100) || 0));
        
        categoryPerformance.slice(GRAPH_LABEL_MAX_SIZE).forEach(r => worstCategoryPayAmountSum += r.payAmount);

        // BEST10 이하 데이터 추가
        if(categoryPerformance.length > GRAPH_LABEL_MAX_SIZE && worstCategoryPayAmountSum) {
            payAmountGraphLabels.push('기타');
            payAmountData.push(Math.round(((worstCategoryPayAmountSum / totalPayAmountSum) * 100) || 0));
        }

        let payAmountGraphDataset = {
            ...new GraphDataset().toJSON(),
            type: 'doughnut',
            label: '판매 매출액',
            data: payAmountData,
            fill: true,
            backgroundColor: SALES_GRAPH_BG_COLOR,
            borderColor: '#fff',
            borderRadius: 0
        }

        setPayAmountGraphData({
            labels: [...payAmountGraphLabels],
            datasets: [payAmountGraphDataset]
        });
    }

    const handleActionCreateBestUnitGraphData = () => {
        let categoryPerformance = [...props.analysis];
        let totalUnitSum = 0;
        let worstCategoryUnitSum = 0;

        categoryPerformance = categoryPerformance.map(r => {
            let sum = 0;
            r.performances.forEach(performance => {
                sum += performance.salesUnit;
            });
            totalUnitSum += sum;

            return {
                category: r.productCategoryName,
                unit: sum
            };
        })

        categoryPerformance = _.orderBy(categoryPerformance, 'unit', 'desc');
        let bestUnitPerformance = categoryPerformance.slice(0, GRAPH_LABEL_MAX_SIZE);
        let unitGraphLabels = bestUnitPerformance.map((r, idx) => (idx + 1) + '. ' + r.category);
        let unitData = bestUnitPerformance.map(r => Math.round(((r.unit / totalUnitSum) * 100) || 0));

        categoryPerformance.slice(GRAPH_LABEL_MAX_SIZE).forEach(r => worstCategoryUnitSum += r.unit);

        // BEST10 이하 데이터 추가
        if(categoryPerformance.length > GRAPH_LABEL_MAX_SIZE && worstCategoryUnitSum) {
            unitGraphLabels.push('기타');
            unitData.push(Math.round(((worstCategoryUnitSum / totalUnitSum) * 100) || 0));
        }

        let unitGraphDataset = {
            ...new GraphDataset().toJSON(),
            type: 'doughnut',
            label: '판매 수량',
            data: unitData,
            fill: true,
            backgroundColor: SALES_GRAPH_BG_COLOR,
            borderColor: '#fff',
            borderRadius: 0
        }

        setUnitGraphData({
            labels: [...unitGraphLabels],
            datasets: [unitGraphDataset]
        });
    }

    const handleActionCreateGraphOption = () => {
        let payAmountOption = {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '40%',
            plugins: {
                legend: {
                    position: 'right',
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            var label = tooltipItem?.label || '';
                            var value = tooltipItem?.parsed || 0;
                            return label + " : " + value + "%";
                        }
                    }
                },
                datalabels: {
                    formatter:function(value, context){
                        return (value >= 5) ? (value + "%") : "";
                    },
                    font: {
                        size: '14',
                        weight: '700',
                    },
                    color: 'white'
                },
                title: {
                    display: true,
                    text: '판매 매출액 비율',
                    font: {
                        size: '14'
                    }
                }               
            }
        }

        let unitOption = {
            ...payAmountOption,
            plugins: {
                ...payAmountOption.plugins,
                title: {
                    ...payAmountOption.plugins.title,
                    text: '판매 수량 비율'
                }
            }
        }

        setPayAmountGraphOption(payAmountOption);
        setUnitGraphOption(unitOption);
    }

    return (
        <Container>
            <CustomChartGroupV2>
                <CustomChartGroupV2.BoardGroup>
                    <CustomChartGroupV2.BoardGroup.Title>판매 비율</CustomChartGroupV2.BoardGroup.Title>
                    <CustomChartGroupV2.BoardGroup.Info>{`BEST${GRAPH_LABEL_MAX_SIZE} 카테고리 판매 매출액 & 수량 비율을 표시합니다.`}</CustomChartGroupV2.BoardGroup.Info>
                </CustomChartGroupV2.BoardGroup>

                <div style={{ position: 'relative' }}>
                    {props.isCategoryAnalysisLoading &&
                        <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                    }
                    <CustomChartGroupV2.BodyGroup className='flex-item'>
                        <CustomChartGroupV2.BodyGroup.ChartBody height='320px'>
                            <div className='half-type'>
                                {payAmountGraphData && payAmountGraphOption &&
                                    <Doughnut
                                        plugins={[ChartDataLabels]}
                                        data={createGraphData(payAmountGraphData)}
                                        options={createGraphOption(payAmountGraphOption)}
                                    />
                                }
                            </div>
                        </CustomChartGroupV2.BodyGroup.ChartBody>
                        <CustomChartGroupV2.BodyGroup.ChartBody height='320px'>
                            <div className='half-type'>
                                {unitGraphData && unitGraphOption &&
                                    <Doughnut
                                        plugins={[ChartDataLabels]}
                                        data={createGraphData(unitGraphData)}
                                        options={createGraphOption(unitGraphOption)}
                                    />
                                }
                            </div>
                        </CustomChartGroupV2.BodyGroup.ChartBody>
                    </CustomChartGroupV2.BodyGroup>
                </div>
            </CustomChartGroupV2>
        </Container>
    )
}