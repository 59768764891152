import { useEffect, useState } from "react";
import useRouterHookV2 from "../../../../../hooks/router/useRouterHookV2";
import { Container } from "./style/ConditionSearch.styled";
import SearchFieldView from "./view/SearchField.view";
import { nullLastAndSort } from "../../../../../utils/sortUtils";

export default function ConditionSearchComponent(props) {
    const [selectedCategoryName, setSelectedCategoryName] = useState(null);
    const router = useRouterHookV2();

    useEffect(() => {
        if(!(router.state?.productCategoryIds?.length > 0) && !router.state?.includeUndefinedCategoryFlag) {
            return;
        }

        if(!props.productCategories) {
            return;
        }

        handleActionInitSelectedProductCategory();
    }, [router.state, props.productCategories])

    const handleActionInitSelectedProductCategory = () => {
        let categoryIds = router.state?.productCategoryIds;
        let data = [];

        if(router.state?.includeUndefinedCategoryFlag) {
            data.push(null);
        }
        data = [...data, ...props.productCategories?.filter(r => categoryIds.includes(r.id)).map(r => r.name)];
        
        let sortedData = nullLastAndSort(data);
        setSelectedCategoryName(sortedData);
    }

    return (
        <Container>
            <SearchFieldView selectedCategoryName={selectedCategoryName}/>
        </Container>
    )
}