import { Container } from "./style/ConditionSelector.styled";
import SelectorFieldView from "./view/SelectorField.view";
import TextFieldView from "./view/TextField.view";

export default function ConditionSelectorComponent(props) {
    return (
        <Container>
            <TextFieldView />

            <SelectorFieldView
                categories={props.categories}

                returnIsCheckedOne={props.returnIsCheckedOne}
                onActionCheckOne={props.onActionCheckOne}
                onActionCheckAll={props.onActionCheckAll}
                onActionCheckCancelAll={props.onActionCheckCancelAll}
            />
        </Container>
    )
}