import { useDispatch } from "react-redux";
import { userDataConnect } from "../../../data_connect/userDataConnect";
import { Container, Wrapper } from "./index.styled";
import InfoFieldComponent from "./info-field/InfoField.component";
import LogoFieldComponent from "./logo-field/LogoField.component";
import defaultErrorHandler from "../../../handler/dataConnectErrorHandler";

const MAIN_CLIENT_ORIGIN = process.env.REACT_APP_MAIN_CLIENT_ADDRESS;

const PrimaryNavbarMainComponent = (props) => {
    const reduxDispatch = useDispatch();

    const __handle = {
        req: {
            logout: async () => {
                await userDataConnect().logoutLocal()
                    .then(res => {
                        if (res.status === 200 && res.data.message === 'success') {
                            reduxDispatch({
                                type: 'USER_REDUX_CLEAR_USER_INFO'
                            })
                            reduxDispatch({
                                type: 'WORKSPACE_REDUX_CLEAR_WORKSPACE_INFO'
                            })
                            window.location.replace(`${MAIN_CLIENT_ORIGIN}`);
                        }
                    })
                    .catch(err => defaultErrorHandler(err))
            }
        },
        action: {
            logout: async () => {
                await __handle.req.logout();
            }
        }
    }
    return (
        <>
            <Container>
                <Wrapper>
                    <LogoFieldComponent></LogoFieldComponent>
                    <InfoFieldComponent
                        onLogout={__handle.action.logout}
                    />
                </Wrapper>
            </Container>
        </>
    );
}
export default PrimaryNavbarMainComponent;