class GraphDataset {
    constructor() {
        this.type = 'bar';
        this.label = '';
        this.data = [];
        this.fill = false;
        this.borderColor = '#80A9E1';
        this.backgroundColor = '#80A9E1';
    }

    toJSON() {
        return {
            type: this.type,
            label: this.label,
            data: this.data,
            fill: this.fill,
            borderColor: this.borderColor,
            backgroundColor: this.backgroundColor
        }
    }
}

function createGraphData(graph) {
    let datasets = graph.datasets?.map(r => {
        return {
            type: r.type || 'bar',
            label: r.label || '',
            data: r.data || [],
            fill: r.fill || false,
            borderColor: r.borderColor || '#eee',
            backgroundColor: r.backgroundColor || '#eee',
            order: r.order || 0,
            borderWidth: r.borderWidth ?? 1,
            borderDash: r.borderDash || [0, 0],
            pointRadius: r.pointRadius ?? 3,
            stack: r.stack,
            borderRadius: r.borderRadius ?? 2
        }
    });

    return {
        labels: graph.labels || [],
        datasets
    }
}

function createGraphOption(option) {
    return {
        responsive: option.responsive,
        indexAxis: option.indexAxis,
        maintainAspectRatio: option.maintainAspectRatio,
        interaction: option.interaction,
        maxBarThickness: option.maxBarThickness || 23,
        lineTension: option.lineTension ?? 0.3,
        cutout: option.cutout ?? 30,
        plugins: {
            legend: {
                ...option.plugins?.legend,
                position: option.plugins?.legend?.position || 'top',
                labels: {
                    ...option.plugins?.legend?.labels,
                    boxWidth: option.plugins?.lengend?.labels || 12,
                }
            },
            tooltip: {
                callbacks: {
                    label: option.plugins?.tooltip?.callbacks?.label
                }
            },
            datalabels: option.plugins?.datalabels,
            title: option.plugins?.title
        },
        onClick: option.onClick,
        onHover: option.onHover,
        scales: option.scales,
    }
}

// 결과 text를 설정. label: 항목명, value: 항목 해당 값, color: 그래프에서 나타내는 색상
function setAnalysisResultText(datasets) {
    // 데이터가 존재하지 않는 경우
    return datasets?.map(r => {
        let sum = 0;
        r.data.forEach(r2 => sum += r2);

        return {
            label: r.label || '',
            value: sum || 0,
            color: r.backgroundColor || ''
        }
    })
}

export {
    GraphDataset,
    createGraphData,
    createGraphOption,
    setAnalysisResultText
}