import styled from "styled-components";

const Container = styled.div`
    padding: 0 15px;
    margin-top: 30px;
`;

const SearchFieldWrapper = styled.div`
`;

const SearchButtonEl = styled.button`
    width: 200px;
    padding: 5px 0;
    height: 48px;
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
    color: white;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    -webkit-transition: all .1s;
    transition: all .1s;
    font-weight: 700;
    font-size: 14px;
    
    &:hover {
        background-color: var(--mainColorHover);
    }
`;

export {
    Container,
    SearchFieldWrapper,
    SearchButtonEl
}