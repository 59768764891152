import CustomBoxImage from "../../../../../../components/image/box-image/CustomBoxImage";
import FieldCircleLoading from "../../../../../../components/loading/field-loading/v1/FieldCircleLoading";
import useRouterHookV2 from "../../../../../../hooks/router/useRouterHookV2";
import { ImageButtonEl, ControlButtonBox, ItemBoxWrapper, SelectorFieldWrapper, ButtonEl, ButtonBox } from "../style/ConditionSelector.styled";

const MAXIMUM_SEARCH_SIZE = 10;

export default function SelectorFieldView ({ isProductSubCategoryLoading, subCategories, onActionCheckAll, onActionCheckCancelAll, returnIsCheckedOne, onActionCheckOne }) {
    const router = useRouterHookV2();

    return (
        <SelectorFieldWrapper>
            <ControlButtonBox>
                <div>
                    <ImageButtonEl
                        className='button-el'
                        color='#5fcf80'
                        onClick={(e) => onActionCheckAll(e)}
                    >
                        <CustomBoxImage
                            src='/images/icon/check_all_default_5fcf80.svg'
                            size={25}
                        />
                    </ImageButtonEl>
                </div>
                <div>
                    <ImageButtonEl
                        className='button-el'
                        color='#e56767'
                        onClick={(e) => onActionCheckCancelAll(e)}
                    >
                        <CustomBoxImage
                            src='/images/icon/check_remove_all_default_e56767.svg'
                            size={25}
                        />
                    </ImageButtonEl>
                </div>
            </ControlButtonBox>
            <div style={{ position: 'relative' }}>
                {isProductSubCategoryLoading &&
                    <FieldCircleLoading boxStyle={{ borderRadius: '15px', backgroundColor: '#ffffff' }} />
                }
                <ItemBoxWrapper>
                    {subCategories?.map((r, idx) => {
                        return (
                            <ButtonBox
                                key={'category-idx' + idx}
                                onClick={(e) => onActionCheckOne(e, r)}
                            >
                                <ButtonEl
                                    type='button'
                                    checked={returnIsCheckedOne(r)}
                                    onChange={(e) => onActionCheckOne(e, r)}
                                >{r.name}</ButtonEl>
                            </ButtonBox>
                        )
                    })}
                    {!isProductSubCategoryLoading && (subCategories?.length === 0) &&
                        <div style={{ color: '#666' }}>
                            조회 결과 없음
                        </div>
                    }
                </ItemBoxWrapper>
            </div>
            <div style={{ padding: '3px', color: '#888', float: 'right' }}>
                {(router.state?.productSubCategoryIds?.length ?? 0) + ' / ' + MAXIMUM_SEARCH_SIZE}
            </div>
        </SelectorFieldWrapper>
    )
}