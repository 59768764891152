import styled from "styled-components";
import Title from "./Title";
import Info from "./Info";
import SearchModalButton from "./SearchModalButton";

const Container = styled.div`
    border-bottom: 1px solid var(--mainColorOpacity30);
    padding: 20px;
    overflow: hidden;

    &.flex-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 992px) {
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
    }
`;

function BoardGroupMain({
    children, className, ...props
}) {
    return (
        <Container className={className} {...props}>
            {children}
        </Container>
    )
}

export const BoardGroup = Object.assign(BoardGroupMain, {
    Title: Title,
    Info: Info,
    SearchModalButton: SearchModalButton
})
