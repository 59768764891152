import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useDisabledBtn from "../../../../../../../hooks/button/useDisableBtn";
import SingleBlockButton from "../../../../../../modules/button/SingleBlockButton";
import PagenationComponentStateV2 from "../../../../../../modules/pagenation/PagenationStateComponentV2";
import { ButtonContainer, Container, ContentContainer, ContentWrapper } from "./ProductListModal.styled";
import FieldCircleLoading from "../../../../../../../components/loading/field-loading/v1/FieldCircleLoading";

export default function ProductListModalComponent({
    isProductSearchLoading,
    products,
    onConfirm,
    onClose,
    onActionSearchProductPage
}) {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();
    const [searchQuery, setSearchQuery] = useState(null);

    useEffect(() => {
        let params = {
            productCategoryId: null,
            productSubCategoryId: null,
            searchCondition: null,
            searchQuery: null,
            sort: 'createdAt_asc',
            page: 0,
            size: 10
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }
        
        onActionSearchProductPage(params, headers);
    }, [])

    const onChangeSearchQuery = (e) => {
        let value = e.target.value;
        setSearchQuery(value);
    }
    
    const handlePaging = (pageIndex) => {
        let params = {
            productCategoryId: null,
            productSubCategoryId: null,
            searchCondition: searchQuery ? 'product.name' : null,
            searchQuery: searchQuery,
            sort: 'createdAt_asc',
            page: pageIndex,
            size: products?.size
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }
        
        onActionSearchProductPage(params, headers);
    }

    const handleSubmitFetchProductPage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDisabledBtn(true);

        let params = {
            productCategoryId: null,
            productSubCategoryId: null,
            searchCondition: searchQuery ? 'product.name' : null,
            searchQuery: searchQuery,
            sort: 'createdAt_asc',
            page: 1,
            size: products?.size
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        onActionSearchProductPage(params, headers);
    };

    const handleSizing = (size) => {
        let params = {
            productCategoryId: null,
            productSubCategoryId: null,
            searchCondition: searchQuery ? 'product.name' : null,
            searchQuery: searchQuery,
            sort: 'createdAt_asc',
            page: products?.number,
            size: size
        }

        let headers = {
            wsId: workspaceRedux?.workspaceInfo.id
        }

        onActionSearchProductPage(params, headers);
    }

    return (
        <Container>
            <ContentContainer>
                <ContentWrapper>
                    <form onSubmit={(e) => handleSubmitFetchProductPage(e)}>
                        <div className='search-input-box'>
                            <input
                                type='text'
                                className='search-input-item'
                                placeholder="상품명을 입력해 주세요."
                                value={searchQuery || ''}
                                onChange={(e) => onChangeSearchQuery(e)}
                                autoFocus
                            />
                            <SingleBlockButton
                                type='submit'
                                className='search-button-item'
                                disabled={disabledBtn}
                            >
                                조회
                            </SingleBlockButton>
                        </div>
                    </form>
                    <div className='item-list-wrapper'>
                        <div className='item-list-box'>
                            {isProductSearchLoading &&
                                <FieldCircleLoading boxStyle={{ borderRadius: '15px' }} />
                            }
                            {products?.content?.map(product => {
                                return (
                                    <div
                                        key={product.id}
                                        className='item-box'
                                        onClick={() => onConfirm(product.id)}
                                    >
                                        <div className='product-info'>
                                            {product.name}
                                        </div>
                                        <div className='category-info'>
                                            {product?.productCategory?.name} / {product?.productSubCategory?.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className='pagenation-wrapper'>
                        <PagenationComponentStateV2
                            align={'center'}
                            pageIndex={products?.number}
                            totalPages={products?.totalPages}
                            isFirst={products?.first}
                            isLast={products?.last}
                            totalElements={products?.totalElements}
                            sizeElements={[10, 20, 50]}
                            size={products?.size}
                            onChangePage={handlePaging}
                            onChangeSize={handleSizing}
                        />
                    </div>
                </ContentWrapper>
            </ContentContainer>
            <ButtonContainer>
                <SingleBlockButton
                    type='button'
                    className='button-el'
                    style={{
                        background: '#959eae',
                        flex: 1
                    }}
                    onClick={() => onClose()}
                >
                    닫기
                </SingleBlockButton>
            </ButtonContainer>
        </Container>
    )
}