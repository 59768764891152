import styled from "styled-components";

const Container = styled.div`
`;

const GraphOperatorFieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const SelectEl = styled.select`
    width: 200px;
    height: 40px;
    padding: 5px;
    text-align: center;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none;
    background: url(/images/icon/arrowDropDown_default_808080.svg) 97% 50% / 25px no-repeat rgb(255, 255, 255);
    background-color: white;
        
    &:focus{
        outline: none;
    }
`;

export {
    Container,
    GraphOperatorFieldWrapper,
    SelectEl
}