import moment from "moment";

const weekName = ['일', '월', '화', '수', '목', '금', '토'];
const DEFAULT_YEAR_2DIGIT = moment(new Date()).format('YYYY').substring(0, 2);

function diffTimeToHHmmss(startDate, endDate) {
    let diffTime = new Date(endDate) - new Date(startDate);
    let tempTime = moment.duration(diffTime);
    return moment.utc(tempTime.asMilliseconds()).format('HH : mm : ss');
}

function getStartDate(date) {
    var d = new Date(date);
    d.setHours(0);
    d.setMinutes(0);
    // d.setSeconds(1);
    d.setSeconds(0);
    return d;
}

function getEndDate(date) {
    var d = new Date(date);
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(59);
    return d;
}

function getStartDateOfMonth(date) {
    var d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth(), 1);
}

function getEndDateOfMonth(date) {
    var d = new Date(date);
    return new Date(d.getFullYear(), d.getMonth() + 1, 0);
}

function setPlusDate(idate, prevYear, prevMonth, prevDay) {
    var date = new Date(idate);
    date.setFullYear(date.getFullYear() + prevYear)
    date.setMonth(date.getMonth() + prevMonth);
    date.setDate(date.getDate() + prevDay);
    return new Date(moment(date));
}

function dateToYYYYMMDD(date) {
    var d = new Date(date)
    return moment(d).format("YYYY-MM-DD");
}

function dateToYYYYMM(date) {
    var d = new Date(date);
    return moment(d).format("YYYY-MM");
}

function getDayName(idate) {
    var d = new Date(idate);
    return weekName[d.getDay()];
}

function getWeekNumber(date) {
    let d = new Date(date);
    let currentDate = new Date(d.setDate(d.getDate()-1));

    var weekNum = moment(currentDate).week();
    return weekNum;
}

// date의 해당 week 첫번째 일을 구한다. 만약 첫번째 일이 minDate보다 작다면 minDate를 리턴
function getSearchStartDateByWeekNumber(date, minDate) {
    let d = new Date(date);
    let currentDate = new Date(d.setDate(d.getDate()-1));
    let minD = new Date(minDate);
    let minimumDate = new Date(minD.setDate(minD.getDate()-1));

    let startDate = moment(currentDate).startOf('week').toDate();
    if(startDate < minimumDate) {
        startDate = minimumDate;
    }

    startDate = new Date(startDate.setDate(startDate.getDate() + 1));
    startDate = moment(startDate).format("YY.MM.DD");

    return startDate;
}

// date의 해당 week 마지막 일을 구한다. 만약 마지막 일이 maxDate보다 작다면 maxDate를 리턴
function getSearchEndDateByWeekNumber(date, maxDate) {
    let d = new Date(date);
    let currentDate = new Date(d.setDate(d.getDate()-1));
    let maxD = new Date(maxDate);
    let maximumDate = new Date(maxD.setDate(maxD.getDate()-1));

    let endDate = moment(currentDate).endOf('week').toDate();
    if(endDate > maximumDate) {
        endDate = maximumDate;
    }

    endDate = new Date(endDate.setDate(endDate.getDate() + 1));
    endDate = moment(endDate).format("YY.MM.DD");

    return endDate;
}

// date의 해당 month 첫번째 일을 구한다. 만약 첫번째 일이 minDate보다 작다면 minDate를 리턴
function getSearchStartDateByMonth(date, minDate) {
    let currentDate = new Date(date);
    let minimumDate = new Date(minDate);

    let startDate = moment(currentDate).startOf('month').toDate();
    if(startDate < minimumDate) {
        startDate = minimumDate;
    }

    startDate = moment(startDate).format("YY.MM.DD");
    return startDate;
}

// date의 해당 month 마지막 일을 구한다. 만약 마지막 일이 maxDate보다 크다면 maxDate를 리턴
function getSearchEndDateByMonth(date, maxDate) {
    let currentDate = new Date(date);
    let maximumDate = new Date(maxDate);

    let endDate = moment(currentDate).endOf('month').toDate();
    if(endDate > maximumDate) {
        endDate = maximumDate;
    }

    endDate = moment(endDate).format("YY.MM.DD");
    return endDate;
}

function getWeekName() {
    return weekName;
}

function isSearchablePeriod(date1, date2, searchablePeriod) {
    var startDate = moment(date1);
    var endDate = moment(date2);

    // diff <= searchablePeriod
    if(endDate.diff(startDate, 'days') <= searchablePeriod) {
        return true;
    }
    return false;
}

function getTimeDiffWithUTC() {
    var d = new Date();
    var hourDiff = d.getTimezoneOffset() / 60;

    // hourDiff가 -9라면, utc시간보다 9시간 빠른것
    return hourDiff * -1;
}

// graph label -> date format
function getDateFormatByGraphDateLabel(label, dimension) {
    let date1 = new Date();
    let date2 = new Date();
    
    if(dimension === 'date') {
        var date = label.split(" ");

        date1 = date;
        date2 = date;
    } else {
        var startDateStartIdx = label.indexOf("(");
        var startDateEndIdx = label.indexOf("~");
        var endDateEndIdx = label.indexOf(")");
        date1 = label.slice(startDateStartIdx+1, startDateEndIdx);
        date2 = label.slice(startDateEndIdx+1, endDateEndIdx);
    } 
    
    let startDate = getStartDate(new Date(DEFAULT_YEAR_2DIGIT + date1));
    let endDate = getEndDate(new Date(DEFAULT_YEAR_2DIGIT + date2));
    
    return {
        startDate,
        endDate
    };
}

function dateToYYYYMMDDAndDayName(date) {
    return moment(date).format("YY.MM.DD") + ' (' + getDayName(date) + ')';
}

function getWeekNumberAndSearchDateRange(date, minDate, maxDate) {
    return getWeekNumber(date) +"주차 (" +
        getSearchStartDateByWeekNumber(date, minDate) + "~" +
        getSearchEndDateByWeekNumber(date, maxDate) + ")";
}

function getMonthAndSearchDateRange(date, minDate, maxDate) {
    return dateToYYYYMM(date) + " (" +
        getSearchStartDateByMonth(date, minDate) + "~" +
        getSearchEndDateByMonth(date, maxDate) + ")";
}

export {
    diffTimeToHHmmss,
    getStartDate,
    getEndDate,
    dateToYYYYMMDD,
    getDayName,
    dateToYYYYMM,
    getWeekNumber,
    getWeekName,
    getStartDateOfMonth,
    getEndDateOfMonth,
    isSearchablePeriod,
    getTimeDiffWithUTC,
    setPlusDate,
    getDateFormatByGraphDateLabel,
    dateToYYYYMMDDAndDayName,
    getSearchStartDateByWeekNumber,
    getSearchEndDateByWeekNumber,
    getSearchStartDateByMonth,
    getSearchEndDateByMonth,
    getWeekNumberAndSearchDateRange,
    getMonthAndSearchDateRange
}