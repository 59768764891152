import { useState } from "react";
import NavBarComponent from "../../navbar/NavBar.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import useTotalAnalysisHook from "./hooks/useTotalAnalysisHook";
import { Container } from "./index.styled";
import DetailGraphSelectorModalComponent from "./modal/detail-graph-selector/DetailGraphSelector.component";
import PayAmountDayOfWeekGraphComponent from "./pay-amount-day-of-week-graph/PayAmountDayOfWeekGraph.component";
import PayAmountGraphComponent from "./pay-amount-graph/PayAmountGraph.component";
import RegistrationAndUnitGraphComponent from "./registration-and-unit-graph/RegistrationAndUnitGraph.component";
import SummaryTableComponent from "./summary-table/SummaryTable.component";
import TextInfoComponent from "./text-info/TextInfo.component";

export default function SalesAnalysis_TotalIndexComponent() {
    const [searchDimension, setSearchDimension] = useState('date');
    const [checkedSwitch, setCheckedSwitch] = useState(false);

    const [detailGraphSelectorModalOpen, setDetailGraphSelectorModalOpen] = useState(false);

    const {
        isLoading: isTotalAnalysisLoading,
        analysis,
        reqSearchTotalAnalysis
    } = useTotalAnalysisHook();

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }

    const handleChangeDimension = (value) => {
        setSearchDimension(value);
    }

    const handleActionOpenDetailGraphSelectorModal = () => {
        setDetailGraphSelectorModalOpen(true);
    }

    const handleActionCloseDetailGraphSelectorModal = () => {
        setDetailGraphSelectorModalOpen(false);
    }

    const handleSubmitSearchPerformance = async (body, headers) => {
        await reqSearchTotalAnalysis(body, headers);
    }

    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'전체 판매 성과'}
                    headerName={'총 매출액 & 판매 건'}
                    sidebarColor={'#ffffff'}
                >
                    <>
                        <TextInfoComponent />
                        
                        <GraphOperatorComponent
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}

                            onChangeSwitch={handleChangeSwitch}
                            onChangeSearchDimension={handleChangeDimension}
                        />

                        <PayAmountGraphComponent
                            isTotalAnalysisLoading={isTotalAnalysisLoading}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}

                            onActionOpenDetailGraphSelectorModal={handleActionOpenDetailGraphSelectorModal}
                        />

                        <RegistrationAndUnitGraphComponent
                            isTotalAnalysisLoading={isTotalAnalysisLoading}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}

                            onActionOpenDetailGraphSelectorModal={handleActionOpenDetailGraphSelectorModal}
                        />

                        <PayAmountDayOfWeekGraphComponent
                            isTotalAnalysisLoading={isTotalAnalysisLoading}
                            analysis={analysis}
                        />

                        <SummaryTableComponent
                            isTotalAnalysisLoading={isTotalAnalysisLoading}
                            analysis={analysis}
                        />
                    </>
                </NavBarComponent>

                <DateOperatorComponent
                    onSubmitSearchPerformance={handleSubmitSearchPerformance}
                />
            </Container>

            <DetailGraphSelectorModalComponent
                modalOpen={detailGraphSelectorModalOpen}

                onActionCloseModal={handleActionCloseDetailGraphSelectorModal}
            />
        </>
    )
}