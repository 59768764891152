import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productDataConnect } from "../../../../../../data_connect/productDataConnect";
import defaultErrorHandler from "../../../../../../handler/dataConnectErrorHandler";

export default function useProductHook () {
    const workspaceRedux = useSelector(state => state.workspaceRedux);

    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState(null);

    useEffect(() => {
        async function fetchInit() {
            let params = {
                productCategoryId: null,
                productSubCategoryId: null,
                searchCondition: null,
                searchQuery: null,
                sort: 'createdAt_asc',
                page: 1,
                size: 10
            }

            let headers = {
                wsId: workspaceRedux?.workspaceInfo.id
            }
            
            await reqSearchProductAll(params, headers);
        }

        fetchInit();
    }, [])

    const onActionClearProducts = () => {
        setProducts(null);
    }

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchProductAll = async (params, headers) => {
        toggleIsLoading(true);
        await productDataConnect().searchAll(params, headers)
            .then(res => {
                if (res.status === 200) {
                    setProducts(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))
        toggleIsLoading(false);
    }

    return {
        isLoading,
        products,
        reqSearchProductAll,
        onActionClearProducts
    }
}