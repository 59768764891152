import CustomBoxImage from "../../../../../../components/image/box-image/CustomBoxImage";
import { ImageButtonEl, ControlButtonBox, ItemBoxWrapper, SelectorFieldWrapper, ButtonEl, ButtonBox } from "../style/ConditionSelector.styled";

export default function SelectorFieldView ({ subCategories, onActionCheckAll, onActionCheckCancelAll, returnIsCheckedOne, onActionCheckOne }) {
    return (
        <SelectorFieldWrapper>
            <ControlButtonBox>
                <div>
                    <ImageButtonEl
                        className='button-el'
                        color='#5fcf80'
                        onClick={(e) => onActionCheckAll(e)}
                    >
                        <CustomBoxImage
                            src='/images/icon/check_all_default_5fcf80.svg'
                            size={25}
                        />
                    </ImageButtonEl>
                </div>
                <div>
                    <ImageButtonEl
                        className='button-el'
                        color='#e56767'
                        onClick={(e) => onActionCheckCancelAll(e)}
                    >
                        <CustomBoxImage
                            src='/images/icon/check_remove_all_default_e56767.svg'
                            size={25}
                        />
                    </ImageButtonEl>
                </div>
            </ControlButtonBox>
            <ItemBoxWrapper>
                {subCategories?.map((r, idx) => {
                    return (
                        <ButtonBox
                            key={'category-idx' + idx}
                            onClick={(e) => onActionCheckOne(e, r.id)}
                        >
                            <ButtonEl
                                type='button'
                                checked={returnIsCheckedOne(r.id)}
                                onChange={(e) => onActionCheckOne(e, r.id)}
                            >{r.name}</ButtonEl>
                        </ButtonBox>
                    )
                })}
                {(!subCategories || subCategories?.length === 0) &&
                    <div style={{ color: '#666' }}>
                        조회 결과 없음
                    </div>
                }
            </ItemBoxWrapper>
        </SelectorFieldWrapper>
    )
}