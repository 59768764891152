import { useState } from "react";
import { salesAnalysisDataConnect } from "../../../../../data_connect/salesAnalysisDataConnect";
import defaultErrorHandler from "../../../../../handler/dataConnectErrorHandler";

export default function useProductAnalysisHook() {
    const [analysis, setAnalysis] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const toggleIsLoading = (loading) => {
        setIsLoading(loading);
    }

    const reqSearchProductAnalysis = async (body, headers) => {
        toggleIsLoading(true);
        
        await salesAnalysisDataConnect().searchProductAnalysis(body, headers)
            .then(res => {
                if (res.status === 200) {
                    setAnalysis(res.data.data);
                }
            })
            .catch(err => defaultErrorHandler(err))

        toggleIsLoading(false);
    }

    return {
        isLoading,
        analysis,
        reqSearchProductAnalysis
    }
}