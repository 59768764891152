import NavBarComponent from "../../navbar/NavBar.component";
import { Container } from "./index.styled";
import SalesAnalysis_ProductSingleProductAnalysisContainerComponent from "./analysis-container";

export default function SalesAnalysis_ProductSingleProductComponent() {    

    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'상품 성과'}
                    headerName={'단일 상품 성과'}
                    sidebarColor={'#ffffff'}
                >
                    <>
                        <SalesAnalysis_ProductSingleProductAnalysisContainerComponent />
                    </>
                </NavBarComponent>
            </Container>
        </>
    )
}