import _ from "lodash";
import { useEffect, useState } from "react";
import { BackdropHookComponent } from "../../../../hooks/backdrop/useBackdropHook";
import NavBarComponent from "../../navbar/NavBar.component";
import BestCategoryGraphComponent from "./best-category-graph/BestCategoryGraph.component";
import BestProductGraphComponent from "./best-product-graph/BestProductGraph.component";
import ConditionSelectorComponent from "./condition-selector/ConditionSelector.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import useCategoryAndRelatedProductAnalysisHook from "./hooks/useCategoryAndRelatedProductAnalysis";
import { Container } from "./index.styled";
import TextInfoComponent from "./text-info/TextInfo.component";

export default function SalesAnalysis_CategoryBestComponent() {
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [bestPayAmountItem, setBestPayAmountItem] = useState(null);
    const [bestUnitItem, setBestUnitItem] = useState(null);

    const [checkedSwitch, setCheckedSwitch] = useState(false);

    const [optionListSize, setOptionListSize] = useState(10);

    const {
        isLoading: isCategoryAnalysisLoading,
        analysis,
        reqSearchCategoryAndRelatedProductAnalysis
    } = useCategoryAndRelatedProductAnalysisHook();

    useEffect(() => {
        if(!analysis) {
            return;
        }
    
        handleActionInitData();
    }, [analysis])

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }

    const handleActionInitData = () => {
        let category = [];
        let bestPayAmountItem = [];
        let bestUnitItem = [];

        analysis.forEach(r => {
            let categoryId = r.productCategoryId;
            let categoryName = r.productCategoryName;

            let payAmount = _.orderBy(r.performances, 'salesPayAmount', 'desc');
            let unit = _.orderBy(r.performances, 'salesUnit', 'desc');

            bestPayAmountItem.push({
                categoryId,
                categoryName,
                performances: payAmount
            });
            bestUnitItem.push({
                categoryId,
                categoryName,
                performances: unit
            });

            category.push({
                id: categoryId,
                name: categoryName
            });

        });

        bestPayAmountItem = bestPayAmountItem.map(r =>  {
            return {
                ...r,
                performances: r.performances
            }
        });
        bestUnitItem = bestUnitItem.map(r => {
            return {
                ...r,
                performances: r.performances
            }
        });

        let sortedCategoryName = _.sortBy(category, 'name');

        setCategories(sortedCategoryName);
        setBestPayAmountItem(bestPayAmountItem);
        setBestUnitItem(bestUnitItem)

        setSelectedCategories([]);
    }

    const returnIsCheckedOne = (id) => selectedCategories.some(category => category.id === id);

    const handleActionCheckOne = (e, id) => {
        e.stopPropagation();

        let data = [...selectedCategories];

        if (selectedCategories.some(r => r.id === id)) {
            data = data.filter(category => category.id !== id);
        } else {
            let addData = categories.filter(r => r.id === id)[0];
            data.push(addData);
        }
        setSelectedCategories(data);
    }

    const handleActionCheckAll = (e) => {
        e.stopPropagation();
        setSelectedCategories([...categories]);
    }

    const handleActionCheckCancelAll = (e) => {
        e.stopPropagation();
        setSelectedCategories([]);
    }

    const handleSubmitSearchPerformance = async (body, headers) => {
        await reqSearchCategoryAndRelatedProductAnalysis(body, headers);
    }

    const handleChangeOptionListSize = (e) => {
        let value = e.target.value;
        setOptionListSize(value);
    }

    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'카테고리 성과'}
                    headerName={'BEST 카테고리'}
                    sidebarColor={'#ffffff'}
                >
                    <TextInfoComponent />

                    <BestCategoryGraphComponent
                        isCategoryAnalysisLoading={isCategoryAnalysisLoading}
                        analysis={analysis}
                    />

                    <ConditionSelectorComponent
                        categories={categories}

                        returnIsCheckedOne={returnIsCheckedOne}
                        onActionCheckOne={handleActionCheckOne}
                        onActionCheckAll={handleActionCheckAll}
                        onActionCheckCancelAll={handleActionCheckCancelAll}
                    />

                    <GraphOperatorComponent
                        checkedSwitch={checkedSwitch}
                        optionListSize={optionListSize}

                        onActionChangeSwitch={handleChangeSwitch}
                        onActionChangeOptionListSize={handleChangeOptionListSize}
                    />

                    <BestProductGraphComponent
                        selectedCategories={selectedCategories}
                        categories={categories}
                        checkedSwitch={checkedSwitch}
                        bestPayAmountItem={bestPayAmountItem}
                        bestUnitItem={bestUnitItem}
                        optionListSize={optionListSize}
                    />
                </NavBarComponent>

                <DateOperatorComponent
                    onSubmitSearchPerformance={handleSubmitSearchPerformance}
                />
            </Container>
        </>
    )
}