import _ from "lodash";
import { useEffect, useState } from "react";
import NavBarComponent from "../../navbar/NavBar.component";
import ConditionSelectorComponent from "./condition-selector/ConditionSelector.component";
import DateOperatorComponent from "./date-operator/DateOperator.component";
import GraphOperatorComponent from "./graph-operator/GraphOperator.component";
import useSubCategoryAnalysisHook from "./hooks/useSubCategoryAnalysisHook";
import useProductCategoriesHook from "./hooks/useProductCategoriesHook";
import { Container } from "./index.styled";
import DetailGraphSelectorModalComponent from "./modal/detail-graph-selector/DetailGraphSelector.component";
import OperatorComponent from "./operator/Operator.component";
import PayAmountDayOfWeekGraphComponent from "./pay-amount-day-of-week-graph/PayAmountDayOfWeekGraph.component";
import PayAmountGraphComponent from "./pay-amount-graph/PayAmountGraph.component";
import RegistrationAndUnitGraphComponent from "./registration-and-unit-graph/RegistrationAndUnitGraph.component";
import useProductSubCategoriesHook from "./hooks/useProductSubCategoriesHook";
import TextInfoComponent from "./text-info/TextInfo.component";
import useRouterHookV2 from "../../../../hooks/router/useRouterHookV2";
import { customToast, defaultOptions } from "../../../../components/toast/custom-react-toastify/v1";

export default function SalesAnalysis_SubCategoryIndexComponent() {
    const [searchDimension, setSearchDimension] = useState('date');
    const [checkedSwitch, setCheckedSwitch] = useState(false);

    const [subCategories, setSubCategories] = useState([]);
    const [selectedProductCategory, setSelectedProductCategory] = useState(null);

    const [detailGraphSelectorModalOpen, setDetailGraphSelectorModalOpen] = useState(false);

    const router = useRouterHookV2();

    const {
        productCategories: totalProductCategories
    } = useProductCategoriesHook();

    const {
        isLoading: isProductSubCategoryLoading,
        productSubCategories: totalProductSubCategories
    } = useProductSubCategoriesHook({ selectedProductCategory });

    const {
        isLoading: isSubCategoryAnalysisLoading,
        analysis,
        reqSearchSubCategoryAnalysis
    } = useSubCategoryAnalysisHook({ selectedProductCategory });

    useEffect(() => {
        if(!totalProductCategories) {
            return;
        }

        if(!router.state?.productCategoryIds?.length > 0) {
            return;
        }

        let selectedCategoryIds = router.state.productCategoryIds;
        let data = totalProductCategories.filter(r => selectedCategoryIds.includes(r.id));
        setSelectedProductCategory(data[0]);
    }, [totalProductCategories])

    useEffect(() => {
        if(!totalProductSubCategories) {
            return;
        }

        if(!analysis) {
            return;
        }

        handleActionUpdateProductCategory();
    }, [analysis, totalProductSubCategories])

    useEffect(() => {
        if(!router.state) {
            setSubCategories([]);
            setSelectedProductCategory(null);
        }
    }, [router.state])

    const handleActionUpdateProductCategory = () => {
        let subCategoryIds = new Set([]);
        let subCategory = [];

        analysis.forEach(r => r.performances?.forEach(r2 => {
            if(!subCategoryIds.has(r2.productSubCategoryId)) {
                subCategoryIds.add(r2.productSubCategoryId);
                subCategory.push({
                    id: r2.productSubCategoryId,
                    name: r2.productSubCategoryName
                })
            }
        }))

        let sortedData = _.sortBy(subCategory, 'name');
        setSubCategories(sortedData);
    }

    const handleChangeSwitch = () => {
        let checkedValue = checkedSwitch;
        setCheckedSwitch(!checkedValue);
    }

    const handleChangeDimension = (value) => {
        setSearchDimension(value);
    }

    const handleActionOpenDetailGraphSelectorModal = () => {
        if(!(router.state?.productSubCategoryIds?.length > 0)) {
            let memo = '카테고리를 먼저 선택해주세요.';
            customToast.info(memo, {
                ...defaultOptions,
                toastId: memo
            })
            return;
        }

        setDetailGraphSelectorModalOpen(true);
    }

    const handleActionCloseDetailGraphSelectorModal = () => {
        setDetailGraphSelectorModalOpen(false);
    }

    const handleSubmitSearchPerformance = async (body, headers) => {
        await reqSearchSubCategoryAnalysis(body, headers);
    }

    const handleActionSelectProductCategory = (id) => {
        let selectedCategory = totalProductCategories.filter(r => r.id === id)[0];
        
        router.replace({
            state: {
                ...router.state,
                productCategoryIds: [selectedCategory.id],
                productSubCategoryIds: []
            }
        })
        setSelectedProductCategory(selectedCategory);
    }
    
    return (
        <>
            <Container>
                <NavBarComponent
                    sidebarName={'서브 카테고리 성과'}
                    headerName={'총 매출액 & 판매 건'}
                    sidebarColor={'#ffffff'}
                >
                    <>
                        <TextInfoComponent />
                        
                        <OperatorComponent
                            selectedProductCategory={selectedProductCategory}
                            productCategories={totalProductCategories}
                            onActionSelectProductCategory={handleActionSelectProductCategory}
                        />

                        <ConditionSelectorComponent
                            isProductSubCategoryLoading={isProductSubCategoryLoading}
                            subCategories={subCategories}
                        />

                        <GraphOperatorComponent
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}

                            onChangeSwitch={handleChangeSwitch}
                            onChangeSearchDimension={handleChangeDimension}
                        />

                        <PayAmountGraphComponent
                            isSubCategoryAnalysisLoading={isSubCategoryAnalysisLoading}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}
                            subCategories={subCategories}

                            onActionOpenDetailGraphSelectorModal={handleActionOpenDetailGraphSelectorModal}
                        />

                        <RegistrationAndUnitGraphComponent
                            isSubCategoryAnalysisLoading={isSubCategoryAnalysisLoading}
                            searchDimension={searchDimension}
                            checkedSwitch={checkedSwitch}
                            analysis={analysis}
                            subCategories={subCategories}

                            onActionOpenDetailGraphSelectorModal={handleActionOpenDetailGraphSelectorModal}
                        />

                        <PayAmountDayOfWeekGraphComponent
                            isSubCategoryAnalysisLoading={isSubCategoryAnalysisLoading}
                            analysis={analysis}
                            subCategories={subCategories}
                        />
                    </>   
                </NavBarComponent>

                <DateOperatorComponent
                    onSubmitSearchPerformance={handleSubmitSearchPerformance}
                />
            </Container>

            <DetailGraphSelectorModalComponent
                modalOpen={detailGraphSelectorModalOpen}

                onActionCloseModal={handleActionCloseDetailGraphSelectorModal}
            />
        </>
    )
}