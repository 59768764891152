import styled from "styled-components"
import PropTypes from "prop-types";

const Container = styled.div`
    height: 350px;
    overflow: auto;

    @media screen and (max-width: 992px) {
        font-size: 14px;
    }

    ul {
        list-style: none;
        padding: 10px;
    } 

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;

        font-size: 14px;
        height: 38px;
        margin-bottom: 10px;
        border-bottom: 2px solid #fff;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export default function SummaryContent({
    children
}) {
    return (
        <Container>
            {children}
        </Container>
    )
}


SummaryContent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}