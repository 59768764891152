import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import SalesAnalysis_CategoryBestPage from './pages/sales-analysis/category/best';
import SalesAnalysis_CategoryBestProductPage from './pages/sales-analysis/category/best-product';
import SalesAnalysis_CategoryIndexPage from './pages/sales-analysis/category/index';
import SalesAnalysis_DashboardIndexPage from './pages/sales-analysis/dashboard/index';
import SalesAnalysis_SalesChannelBestProductPage from './pages/sales-analysis/sales-channel/best-product';
import SalesAnalysis_SalesChannelIndexPage from './pages/sales-analysis/sales-channel/index';
import SalesAnalysis_SalesChannelMultiProductPage from './pages/sales-analysis/sales-channel/multi-product';
import SalesAnalysis_SubCategoryBestPage from './pages/sales-analysis/sub-category/best';
import SalesAnalysis_SubCategoryBestProductPage from './pages/sales-analysis/sub-category/best-product';
import SalesAnalysis_SubCategoryIndexPage from './pages/sales-analysis/sub-category/index';
import SalesAnalysis_TotalIndexPage from './pages/sales-analysis/total/index';
import SalesAnalysis_ProductIndexPage from './pages/sales-analysis/product/index';
import SalesAnalysis_ProductBestProductPage from './pages/sales-analysis/product/best-product';
import SalesAnalysis_ProductSingleProductPage from './pages/sales-analysis/product/single-product';

const theme = createTheme();

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Routes>
				{/* 판매분석 */}
				<Route path='/' element={<SalesAnalysis_DashboardIndexPage />} />
				<Route path='/sales-analysis/dashboard' element={<SalesAnalysis_DashboardIndexPage />} />
				
				<Route path='/sales-analysis/total' element={<SalesAnalysis_TotalIndexPage />} />
				
				<Route path='/sales-analysis/sales-channel' element={<SalesAnalysis_SalesChannelIndexPage />} />
				<Route path='/sales-analysis/sales-channel/best-product' element={<SalesAnalysis_SalesChannelBestProductPage />} />
				<Route path='/sales-analysis/sales-channel/multi-product' element={<SalesAnalysis_SalesChannelMultiProductPage />} />
				
				<Route path='/sales-analysis/category' element={<SalesAnalysis_CategoryIndexPage />} />
				<Route path='/sales-analysis/category/best-product' element={<SalesAnalysis_CategoryBestProductPage />} />
				<Route path='/sales-analysis/category/best' element={<SalesAnalysis_CategoryBestPage />} />
				<Route path='/sales-analysis/sub-category' element={<SalesAnalysis_SubCategoryIndexPage />} />
				<Route path='/sales-analysis/sub-category/best-product' element={<SalesAnalysis_SubCategoryBestProductPage />} />
				<Route path='/sales-analysis/sub-category/best' element={<SalesAnalysis_SubCategoryBestPage />} />
				
				<Route path='/sales-analysis/product' element={<SalesAnalysis_ProductIndexPage />} />
				<Route path='/sales-analysis/product/best-product' element={<SalesAnalysis_ProductBestProductPage />} />
				<Route path='/sales-analysis/product/single-product' element={<SalesAnalysis_ProductSingleProductPage />} />
			</Routes>
		</ThemeProvider>
	);
}

export default App;
