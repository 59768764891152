import { ClickableWrapper } from "../../../../../../../components/clickable-wrapper/v1";
import CustomDatePicker from "../../../../../../../components/date-picker/v1/CustomDatePicker";
import { ButtonBox, ButtonEl, SearchFieldWrapper, SelectEl } from "../style/DateOperator.styled";

export default function SearchFieldView ({
    periodType,
    monthDatePickerOpen,
    startDate,
    onChangePeriodType,
    onActionToggleMonthDatePicker,
    onChangeStartAndEndDate
}) {
    return (
        <SearchFieldWrapper>
            <SelectEl
                onChange={(e) => onChangePeriodType(e)}
                value={periodType}
            >
                <option value='registration'>주문 등록일</option>
                <option value='channelOrderDate'>주문 일시</option>
            </SelectEl>

            <ButtonBox>
                <ClickableWrapper
                    isActive={monthDatePickerOpen}
                    onClickOutside={() => onActionToggleMonthDatePicker()}
                >
                    <ButtonEl
                        type='button'
                        checked={monthDatePickerOpen}
                        onClick={() => onActionToggleMonthDatePicker()}
                    >
                        월 검색
                    </ButtonEl>

                    {monthDatePickerOpen &&
                        <div className='date-selector-box'>
                            <CustomDatePicker
                                valueSize={14}
                                labelSize={12}
                                label={'시작일'}
                                selected={startDate}
                                isMonthYearPicker={true}
                                onChange={value => onChangeStartAndEndDate(value)}
                            ></CustomDatePicker>
                        </div>
                    }
                </ClickableWrapper>
            </ButtonBox>
        </SearchFieldWrapper>
    )
}
