import 'chart.js/auto';
import { Chart, Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from "styled-components";
import { createGraphData, createGraphOption } from "../../../utils/chartUtils";
import PropTypes from 'prop-types';

const Container = styled.div`
    &.full-type {
        min-height: ${props => props.height};
        flex: 1;
    }

    &.half-type {
        min-height: ${props => props.height};
        display: flex;
        justify-content: space-around;
        flex: 1;
    }
`;

const GraphWrapper = styled.div`
    /* width: 100%; */
    width: ${props => props.width};
    height: ${props => props.height};
`;

export default function ChartBody({
    chartData,
    chartOption,
    chartType,
    className='full-type',
    height='450px',
    width='100%'
}) {
    return (
        <Container height={height} className={className}>
            {chartData && chartOption &&
                <GraphWrapper height={height} width={width}>
                    {chartType === 'doughnut' ?
                        <Doughnut
                            plugins={[ChartDataLabels]}
                            data={createGraphData(chartData)}
                            options={createGraphOption(chartOption)}
                        />
                        :
                        <Chart
                            data={createGraphData(chartData)}
                            options={createGraphOption(chartOption)}
                        />
                    }
                </GraphWrapper>
            }
        </Container>
    )
}

ChartBody.propTypes = {
    chartData: PropTypes.object,
    chartOption: PropTypes.object,
    chartType: PropTypes.oneOf(["bar", "doughnut"]),
    className: PropTypes.oneOf(["full-type", "half-type"]),
    width: PropTypes.string,
    height: PropTypes.string
}
